import React from "react";
import HowToDrawFloorPlan1 from "../Images/HowToDrawFloorPlan1.png";
import HowToDrawFloorPlan2 from "../Images/HowToDrawFloorPlan2.png";
import InfoIcon from "../Icons/InfoIcon";

function HowToDrawFloorPlanEs() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">Cómo dibujar planos</h1>

          <div className="title-secondary gray">
            Tutorial para crear planos sin ser arquitecto
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              ¿Qué puedes hacer con nuestra herramienta de planos?
            </h2>
            <p className="article-text">
              Nuestra herramienta de planos te permite crear planos
              profesionales en minutos, sin conocimientos de arquitectura. Ya
              seas agente inmobiliario, diseñador de interiores o propietario,
              puedes crear fácilmente planos precisos y visualmente atractivos.
              <p style={{ marginTop: "10px" }}>
                Crear planos es especialmente útil para los anuncios
                inmobiliarios, ya que ayuda a los compradores potenciales a
                entender la distribución y el flujo del espacio antes de
                visitarlo.
              </p>
            </p>

            <h2 className="article-subtitleh2">Cómo crear un plano</h2>
            <p className="article-text">
              Comienza creando un nuevo proyecto en nuestra herramienta de
              planos.
              <p style={{ marginTop: "10px" }}>
                Haz clic en el botón{" "}
                <span style={{ fontWeight: "500" }}>"Nuevo Proyecto"</span> para
                comenzar tu plano.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToDrawFloorPlan2}
                alt="Cómo usar la herramienta de planos – Captura de pantalla del software Pedra"
              />
            </p>

            <h2 className="article-subtitleh2">
              <span style={{ fontWeight: "600" }}>Elementos básicos: </span>
              Crea tu plano en segundos
            </h2>
            <p style={{ marginTop: "10px" }}>
              La barra de herramientas superior contiene todos los elementos
              básicos necesarios: habitaciones, puertas, ventanas y muebles.
              Simplemente haz clic en cualquier elemento para añadirlo a tu
              canvas.
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "20px 0px",
                }}
                src={HowToDrawFloorPlan1}
                alt="Cómo usar la herramienta de planos – Captura de pantalla del software Pedra"
              />
            </p>

            <h2 className="article-subtitleh2">
              <span style={{ fontWeight: "600" }}>
                Opciones de personalización:{" "}
              </span>
              Modifica los elementos de tu plano
            </h2>
            <p className="article-text">
              Después de colocar los elementos, puedes personalizarlos
              fácilmente según tus necesidades.
              <p style={{ marginTop: "10px" }}>
                Haz clic en cualquier elemento para acceder a sus propiedades:
                <p style={{ marginTop: "10px" }}>
                  Para las habitaciones, puedes ajustar el tamaño, ocultar
                  paredes, cambiar el color y mostrar/ocultar el nombre de la
                  habitación. Para los muebles, puertas y ventanas, puedes
                  rotarlos, redimensionarlos y reposicionarlos según necesites.
                </p>
                <p style={{ marginTop: "10px" }}>
                  Para renombrar una habitación, simplemente haz doble clic en
                  su nombre y escribe el nuevo.
                </p>
              </p>
            </p>

            <aside className="callout">
              <div>
                <InfoIcon />
              </div>
              <div className="callout-text">
                <h4 className="callout-h4">Consejos para mejores planos</h4>
                <p className="callout-p">
                  Para obtener los mejores resultados con tus planos:
                  <ul>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Comienza con las habitaciones
                      </span>
                      . Crea la estructura principal antes de añadir paredes
                      interiores y muebles.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Usa medidas estándar
                      </span>
                      . Esto asegura que tu plano sea realista y esté a escala.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Añade muebles para una mejor visualización
                      </span>
                      . Esto ayuda a los visitantes a entender mejor el espacio.
                    </li>
                  </ul>
                </p>
              </div>
            </aside>

            <h2 className="article-subtitleh2">
              Video tutorial: Crear un plano
            </h2>

            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "10px",
              }}
            >
              <iframe
                width="100%"
                height="400px"
                src="https://www.youtube.com/embed/Urt4OaV--Wg?si=QJicp9L_1wh6HrjK"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>

            <h2 className="article-subtitleh2">Atajos de teclado</h2>
            <p className="article-text">
              Para trabajar más eficientemente, usa estos atajos de teclado:
              <ul style={{ marginTop: "10px", marginLeft: "20px" }}>
                <li>Rotar elementos: Ctrl/Cmd + R</li>
                <li>Eliminar elemento: Delete o Retroceso</li>
                <li>Copiar elemento: Ctrl/Cmd + C</li>
                <li>Pegar elemento: Ctrl/Cmd + V</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default HowToDrawFloorPlanEs;
