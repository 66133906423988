import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import { Link } from "react-router-dom";
import HowToHomeStageFrame1Es from "../Images/HowToHomeStageSalonFrame1Es.jpg";
import HowToHomeStageFrame2Es from "../Images/HowToHomeStageTerraceFrame2Es.jpg";
import HowToHomeStageFrame4 from "../Images/HowToHomeStageFrame4.png";
import BeforeLivingRoomStaging1 from "../Images/curtain-effect/BeforeSalonStaging1.png";
import AfterLivingRoomStaging1 from "../Images/curtain-effect/AfterSalonStaging1.png";
import BeforeLivingRoomStaging2 from "../Images/curtain-effect/BeforeSalonStaging2.png";
import AfterLivingRoomStaging2 from "../Images/curtain-effect/AfterSalonStaging2.png";
import BeforeLivingRoomStaging3 from "../Images/curtain-effect/BeforeSalonStaging3.png";
import AfterLivingRoomStaging3 from "../Images/curtain-effect/AfterSalonStaging3.png";

import CurtainEffect from "../CurtainEffect";

function HowToVirtualStagingLivingRoomEs() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            Cómo crear un home staging virtual para salones
          </h1>
          <h2 className="title-secondary gray">
            Tutorial sobre cómo crear un home staging virtual para espacios de
            estar
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Fundador de Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fundador de Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              ¿Cómo puedo crear un home staging virtual para un salón?
            </h2>
            <p className="article-text">
              Con Pedra puedes crear un{" "}
              <Link className="article-link" to="/es/home-staging-virtual">
                home staging virtual
              </Link>{" "}
              para salones en pocos clics.
              <p>
                Para comenzar, accede al menú de{" "}
                <span style={{ fontWeight: "500" }}>"Editar con IA"</span> y
                selecciona <span style={{ fontWeight: "500" }}>"Amueblar"</span>
                . Selecciona "Salón" como tipo de espacio y en unos 2 o 3
                minutos tendrás la imagen de tu home staging virtual de salón
                lista para descargar.
              </p>
            </p>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame1Es}
              alt="Cómo crear un home staging virtual de salón – Ejemplos"
            />
            <br />
            <h2 className="article-subtitleh2">
              ¿Cómo crear un home staging virtual para salones?
            </h2>
            <p className="article-text">
              Para conseguir tu{" "}
              <Link className="article-link" to="/es/home-staging-salon">
                home staging virtual de salón
              </Link>
              , sigue estos sencillos pasos:
            </p>
            <ol>
              <li>
                Sube la imagen del salón que quieres amueblar virtualmente
              </li>
              <li>Selecciona la imagen para empezar a editarla</li>
              <li>
                Haz click en{" "}
                <span style={{ fontWeight: "500" }}>"Editar con IA"</span> y
                luego haz click en{" "}
                <span style={{ fontWeight: "500" }}>"Amueblar"</span>
              </li>
            </ol>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageFrame2Es}
              alt="Menú de cómo crear un home staging virtual de salón"
            />
            <p className="article-text">
              Dentro de <span style={{ fontWeight: "500" }}>"Amueblar"</span>{" "}
              tendrás que seguir estos pasos:
            </p>
            <ol>
              <li>
                Selecciona la{" "}
                <span style={{ fontWeight: "500" }}>
                  posición de los muebles
                </span>
                . Para salones, recomendamos usar "Dibujar" para indicar áreas
                específicas donde colocar el sofá, mesa de centro, mueble TV,
                etc.
              </li>
              <li>
                Selecciona <span style={{ fontWeight: "500" }}>"Salón"</span>{" "}
                como tipo de espacio. Esto asegurará que Pedra coloque muebles
                apropiados como sofás, sillones, mesas de centro, etc.
              </li>
              <li>
                Selecciona el <span style={{ fontWeight: "500" }}>estilo</span>.
                Para salones, estilos como "Moderno", "Contemporáneo" o
                "Escandinavo" suelen funcionar bien.
              </li>
              <li>
                Escoge el{" "}
                <span style={{ fontWeight: "500" }}>
                  número de imágenes a generar
                </span>
                . Recomendamos que escojas 2 o 3 imágenes para tener opciones
                diferentes de diseño de salón.
              </li>
            </ol>

            <aside className="callout" style={{ marginTop: "10px" }}>
              <div>
                <InfoIcon />
              </div>
              <h4 className="callout-h4" style={{ fontWeight: "400" }}>
                Consejos para un mejor home staging virtual de salón:
                <ul>
                  <li>
                    Asegúrate de que la fotografía muestre claramente las
                    dimensiones del salón para un amueblado más preciso
                  </li>
                  <li>
                    Si el salón tiene características especiales como chimenea o
                    ventanales, intenta incluirlos en la foto para un resultado
                    más atractivo
                  </li>
                  <li>
                    Usa la opción "Dibujar" para indicar áreas específicas para
                    la zona de estar, comedor, y elementos decorativos
                  </li>
                  <img
                    style={{
                      border: "1px solid #F7F7F7",
                      borderRadius: "12px",
                      width: "100%",
                      maxWidth: "300px",
                      height: "auto",
                      margin: "10px 0px",
                    }}
                    src={HowToHomeStageFrame4}
                    alt="Ejemplo de como crear un home staging virtual de salón"
                  />
                </ul>
              </h4>
            </aside>
            <br />
            <h2 className="article-subtitleh2">
              ¿Por qué hacer un home staging virtual de salón?
            </h2>
            <p className="article-text">
              Un salón bien presentado puede ser decisivo para potenciales
              compradores. El home staging virtual de salones te permite mostrar
              el potencial de estos espacios de convivencia, creando ambientes
              acogedores y funcionales que ayudan a los compradores a visualizar
              cómo podrían disfrutar de momentos en familia y con amigos.
            </p>
            <p className="article-text">
              Con un buen home staging virtual de salón podrás:
            </p>
            <ul>
              <li>Mostrar el potencial de espacios vacíos o poco atractivos</li>
              <li>Crear una atmósfera acogedora y social</li>
              <li>
                Destacar características como la luminosidad, la distribución o
                la versatilidad del salón
              </li>
              <li>
                Ayudar a los compradores a imaginar momentos de convivencia y
                entretenimiento en el salón
              </li>
            </ul>
            <br />
            <h2 className="article-subtitleh2">
              Ejemplos de home staging virtual de salones
            </h2>
            <p className="article-text">
              Aquí te dejamos varios ejemplos de home stagings virtuales que
              pueden aplicarse a salones:
            </p>

            <CurtainEffect
              beforeImage={BeforeLivingRoomStaging1}
              afterImage={AfterLivingRoomStaging1}
              altBefore={"Antes de crear home staging virtual"}
              altAfter={"Después de crear home staging virtual"}
            />
            <CurtainEffect
              beforeImage={BeforeLivingRoomStaging2}
              afterImage={AfterLivingRoomStaging2}
              altBefore={"Antes de crear home staging virtual"}
              altAfter={"Después de crear home staging virtual"}
            />
            <CurtainEffect
              beforeImage={BeforeLivingRoomStaging3}
              afterImage={AfterLivingRoomStaging3}
              altBefore={"Antes de crear home staging virtual"}
              altAfter={"Después de crear home staging virtual"}
            />
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToVirtualStagingLivingRoomEs;
