import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import VirtualStagingExample1 from "../Images/HowToFloorPlanRender1En.png";
import VirtualStagingExample2 from "../Images/HowToFloorPlanRender2En.png";
import VirtualStagingExample3 from "../Images/HowToFloorPlanRender3En.png";

function HowToRenderFloorPlanWithAIFr() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            Comment faire le rendu d'un plan avec l'IA
          </h1>
          <h2 className="title-secondary gray">
            Tutoriel sur la génération d'images réalistes de pièces à partir de
            plans en utilisant l'intelligence artificielle
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Fondateur de Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fondateur de Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Qu'est-ce que le rendu de plans avec l'IA ?
            </h2>
            <p className="article-text">
              Avec Pedra, vous pouvez transformer vos plans en images
              photoréalistes de chaque pièce en quelques minutes. Notre
              technologie d'IA analyse votre plan et génère des visualisations
              de haute qualité montrant à quoi ressembleraient les espaces dans
              la réalité, vous permettant de choisir entre différents styles de
              décoration.
            </p>

            <h3 className="article-subtitle">
              Étape 1 – Téléchargez votre plan
            </h3>
            <div className="value-prop-text">
              Téléchargez votre plan sur la plateforme Pedra.
            </div>
            <div className="picture">
              <img
                src={VirtualStagingExample1}
                alt="Comment télécharger des plans pour le rendu automatique sur Pedra – Infographie"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto", maxWidth: "700px" }}
              />
            </div>

            <h3 className="article-subtitle">
              Étape 2 – Choisissez vos options de rendu
            </h3>
            <div className="value-prop-text">
              Sélectionnez votre style et vos options de personnalisation.
              <p>
                Choisissez le nombre d'images que vous souhaitez et le style.
              </p>
            </div>
            <div className="picture">
              <img
                src={VirtualStagingExample2}
                alt="Comment choisir les options pour le rendu de plans – Infographie"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto", maxWidth: "700px" }}
              />
            </div>

            <h3 className="article-subtitle">
              Étape 3 – Obtenez vos rendus de pièces
            </h3>
            <div className="value-prop-text">
              Recevez des rendus photoréalistes de chaque pièce de votre plan.
              <p>
                Téléchargez et utilisez vos rendus immédiatement ou continuez à
                les modifier.
              </p>
            </div>
            <div className="picture">
              <img
                src={VirtualStagingExample3}
                alt="Comment télécharger les rendus de plans – Infographie"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto", maxWidth: "700px" }}
              />
            </div>

            <aside className="callout" style={{ marginTop: "10px" }}>
              <div>
                <InfoIcon />
              </div>
              <h4 className="callout-h4" style={{ fontWeight: "400" }}>
                Conseils pour de meilleurs résultats :
                <ul>
                  <li>
                    Assurez-vous que le plan soit bien défini et que les mesures
                    soient lisibles
                  </li>
                  <li>
                    Considérez le style qui convient le mieux à votre projet
                  </li>
                  <li>
                    Générez plusieurs rendus pour avoir différentes options de
                    visualisation
                  </li>
                </ul>
              </h4>
            </aside>

            <h2 className="article-subtitleh2">
              Pourquoi utiliser le rendu de plans avec l'IA ?
            </h2>
            <p className="article-text">
              Le rendu de plans avec l'IA offre de multiples avantages :
            </p>
            <ul>
              <li>
                Visualisation immédiate de l'apparence des espaces dans la
                réalité
              </li>
              <li>
                Gain de temps significatif par rapport au rendu traditionnel
              </li>
              <li>Flexibilité pour essayer différents styles de décoration</li>
              <li>
                Qualité photoréaliste qui aide à la présentation des projets
              </li>
            </ul>
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToRenderFloorPlanWithAIFr;
