// HomeEs.js
import React, { useState } from "react";
import EnlightRooms from "./Images/EnlightRooms.png";
import FrameRenovateRealEstateAgents from "./Images/Frame Renovate RealEstateAgents.jpeg";
import HomeAmueblaEstanciasEn1Click from "./Images/HomeAmueblaEstanciasEn1Click.png";
import HomeVaciaEstanciasEn1Click from "./Images/HomeVaciaEstanciasEn1Click.png";
import HomeStaging1 from "./Images/HomeStaging1.jpeg";
import HomeStaging2 from "./Images/HomeStaging2.png";
import HomeStaging3 from "./Images/HomeStaging3.png";
import HomeStaging4 from "./Images/HomeStaging4.png";
import HDImage from "./Images/HDImage.png";
import FrameFloorPlanFeature from "./Images/FrameFloorPlanFeature.png";
import MLSLogo from "./Images/MLSLogo.png";
import MLSFrame1 from "./Images/MLSFrame1.png";
import MLSFrame2 from "./Images/MLSFrame2.png";
import MLSFrame3 from "./Images/MLSFrame3.png";
import MLSFrame4 from "./Images/MLSFrame4.png";
import CustomerLogos from "./CustomerLogos.js";
import posterImageVideo from "./Images/poster-images/poster-image-video.png";
import { Link } from "react-router-dom";

// Icons

import { ReactComponent as RenovateIcon } from "./Images/Renovate icon.svg";
import OrangeSunIcon from "./Icons/OrangeSunIcon.jsx";
import OrangeChairIcon from "./Icons/OrangeChairIcon.jsx";
import OrangeCleanerIcon from "./Icons/OrangeCleanerIcon.jsx";
import OrangePaletteIcon from "./Icons/OrangePaletteIcon.jsx";
import OrangeRollerIcon from "./Icons/OrangeRollerIcon.jsx";
import OrangeFeatherIcon from "./Icons/OrangeFeatherIcon.jsx";
import OrangeVideoIcon from "./Icons/OrangeVideoIcon.jsx";
import OrangeFlowerIcon from "./Icons/OrangeFlowerIcon.jsx";
import OrangePenIcon from "./Icons/OrangePenIcon.jsx";
import OrangeHDIcon from "./Icons/OrangeHDIcon.jsx";
import ToggleIcon from "./Icons/ToggleIcon.jsx";
import ToggleOpenIcon from "./Icons/ToggleOpenIcon.jsx";
import ArrowIcon from "./Icons/ArrowIcon.jsx";
import OrangeRemoveObject from "./Icons/OrangeRemoveObject.jsx";
import OrangeFootnoteIcon from "./Icons/OrangeFootnoteIcon.jsx";
import OrangePerspectiveIcon from "./Icons/OrangePerspectiveIcon.jsx";
import OrangeVirtualVideoIcon from "./Icons/OrangeVirtualVideoIcon.jsx";
import OrangeSettingsIcon from "./Icons/OrangeSettingsIcon.jsx";
import OrangeWatermarkIcon from "./Icons/OrangeWatermarkIcon.jsx";

function redirectToPage() {
  if (window.location.href.includes("/es")) {
    window.open("https://app.pedra.so/es", "_blank");
  } else {
    window.open("https://app.pedra.so", "_blank");
  }
}

function MlsEs(locale) {
  const [FAQ1, setFAQ1] = useState(false);
  const [FAQ2, setFAQ2] = useState(false);
  const [FAQ3, setFAQ3] = useState(false);
  const [FAQ4, setFAQ4] = useState(false);

  return (
    <div>
      <section className="section-first-wrapper">
        <section className="section-first">
          <div className="section-first-text-container">
            <h1 className="section-first-title">
              Muestra el potencial de tus propiedades con Pedra,
              <span style={{ color: "#878787" }}> en colaboración con </span>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: "25px",
                }}
              >
                <img alt="MLS logo" src={MLSLogo} className="mls"></img>
              </div>
            </h1>

            <div className="section-first-text">
              <div className="section-first-text-bold">
                Crea propuestas de renovación, home staging virtuales, vacia
                habitaciones y más en 1 click con IA
              </div>
              <div className="section-first-text-gray">
                Acelera tus ventas inmobiliarias
              </div>
            </div>
            <div className="section-first-button-container">
              <button className="button-top-of-page" onClick={redirectToPage}>
                Prueba Pedra <ArrowIcon />
              </button>
            </div>
          </div>
          <div className="header-picture">
            <div className="header-video-frame">
              <div className="header-video-frame-circles-container">
                <div className="header-video-frame-circle"></div>
                <div className="header-video-frame-circle"></div>
                <div className="header-video-frame-circle"></div>
              </div>
              <div className="video-container">
                <video
                  className="header-video"
                  width="100%"
                  height="auto"
                  playsInline
                  autoPlay
                  muted
                  loop
                  preload="auto"
                  poster={posterImageVideo}
                >
                  <source
                    src="https://pedraimages.s3.eu-west-3.amazonaws.com/Pedra+website+demo+Jul+09+24.mp4"
                    type="video/mp4"
                  />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </section>
      </section>

      <div className="section-one-column-left">
        <div className="customer-logo-container">
          MÁS DE 5,000 PROFESIONALES INMOBILIARIOS YA CONFÍAN EN PEDRA
          <CustomerLogos />
        </div>
      </div>

      <br />

      <div className="section-one-column-left">
        <div className="title-container">
          <h2 className="title-container">Propuestas de renovación con IA</h2>
          <div className="title-secondary">
            Genera renders con IA en Pedra. <br /> Renueva espacios, vacía
            habitaciones, o haz un home staging virtual en 1 click.
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container">
          <div className="feature-text-container">
            <RenovateIcon />
            <h2 className="feature-title">Renders con IA en 1 click</h2>
            <div className="feature-text">
              Usa Pedra para generar renders con IA en 15 segundos. Acelera tus
              ventas mostrando el verdadero potencial de tus propiedades. ¿Cómo
              quedaría esta vivienda de segundo mano renovada?
            </div>
          </div>
          <img
            src={FrameRenovateRealEstateAgents}
            alt="Herramientas de renders con IA en 1 click – Captura de pantalla del software"
          />
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeCleanerIcon />
                <h2 className="feature-title">Vacía una habitación con IA</h2>
                <div className="feature-text">
                  Usa la IA de Pedra para vaciar habitaciones de manera mágica
                  en 1 solo click.
                </div>
              </div>
              <img
                src={HomeVaciaEstanciasEn1Click}
                alt="Herramienta de vaciar habitación con IA – Captura de pantalla del software"
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeChairIcon />
                <h2 className="feature-title">
                  Amuebla estancias vacías en 1 click
                </h2>
                <div className="feature-text">
                  Usa la IA para hacer un{" "}
                  <Link className="article-link" to="/es/home-staging-virtual">
                    home staging virtual
                  </Link>{" "}
                  . Selecciona el estilo y Pedra se encarga del resto.
                </div>
              </div>
              <img
                src={HomeAmueblaEstanciasEn1Click}
                alt="Herramienta de home staging con IA – Captura de pantalla del software"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="quote-container">
          <div className="quote-block">
            <h2 className="quote">
              "¡Usar Pedra para editar nuestras imágenes está ayudándonos a
              obtener más visitas!"
            </h2>
            <div className="quote-author-block">
              <img
                className="quote-author-image"
                src="/fincas-lluria.png"
                alt="Logotipo de Vives Homes para Pedra"
              />

              <div className="quote-author-block-name-title">
                <div className="quote-author-name">Max Vives-Fierro</div>
                <div className="quote-author-company">Fincas Llúria</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />

      <div className="section-one-column-left">
        <div className="title-container">
          <h2 className="title-container">Home staging virtual</h2>
          <div className="title-secondary">
            Pedra ofrece un home staging virtual sencillo pero potente. <br />
            Házlo automático, o generalo por partes.
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container">
          <div className="feature-text-container">
            <OrangeFeatherIcon />
            <h2 className="feature-title">Home staging virtual automático</h2>
            <div className="feature-text">
              Haz más atractivas tus propiedades con el home staging virtual de
              Pedra. Usa el home staging automático o manualmente crea el home
              staging a tu gusto. Tu eliges.
            </div>
          </div>
          <img
            src={HomeStaging1}
            alt="Herramientas de home staging virtual con IA – Captura de pantalla del software"
          />
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeRollerIcon />
                <h2 className="feature-title">
                  Cambia suelos y paredes con la IA
                </h2>
                <div className="feature-text">
                  Renderiza un nuevo suelo con la IA de Pedra. Cambiale el
                  material de las paredes.
                </div>
              </div>
              <img
                src={HomeStaging2}
                alt="Herramienta de cambio de paredes y suelos via renders con IA – Captura de pantalla del software"
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeFlowerIcon />
                <h2 className="feature-title">Home staging manual</h2>
                <div className="feature-text">
                  Usa la librería de muebles de Pedra para hacer una home
                  staging virtual a tu gusto. Tan solo añade los muebles y haz
                  una render con IA para que quede realista.
                </div>
              </div>
              <img
                src={HomeStaging3}
                alt="Herramienta de home staging manual con IA – Captura de pantalla del software"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangePaletteIcon />
                <h2 className="feature-title">
                  Estilos de home staging virtual
                </h2>
                <div className="feature-text">
                  Pedra ofrece muchos estilos para que puedas generar el staging
                  digital que más se adapte a tus gustos o a los de tu cliente.
                </div>
              </div>
              <img
                src={HomeStaging4}
                alt="Herramienta de home staging virtual con varios estilos de IA – Captura de pantalla del software"
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeVideoIcon />
                <h2 className="feature-title">
                  Genera un video enseñando los cambios del render
                </h2>
                <div className="feature-text">
                  Haz un video para posicionar mejor en portales o para redes.
                  Comunica el potencial de la vivienda con la herramienta de
                  video de Pedra.
                </div>
              </div>
              <div
                style={{
                  paddingLeft: "30px",
                  paddingTop: "30px",
                  marginBottom: "-8px",
                }}
              >
                <video
                  width="100%"
                  height="auto"
                  playsInline
                  autoPlay
                  muted
                  loop
                  alt="Software para renders inmobiliarios, Home Staging virtual y planos de planta – Video"
                  style={{
                    borderTopLeftRadius: "10px",
                    borderBottomRightRadius: "10px",
                    borderLeft: "1px solid #EEEEEE",
                    borderRight: "1px solid #EEEEEE",
                    borderTop: "1px solid #EEEEEE",
                  }}
                >
                  <source src="/HomeStaging5.mp4" type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="quote-container">
          <div className="quote-block">
            <h2 className="quote">
              "Habéis pensado en todo! Pedra tiene todo lo que necesitamos!"
            </h2>
            <div className="quote-author-block">
              <img
                style={{
                  maxWidth: "150px",
                  height: "auto",
                }}
                className="quote-author-image"
                src="/vives-homes.png"
                alt="Logotipo de Vives Homes para Pedra"
              />

              <div className="quote-author-block-name-title">
                <div className="quote-author-name">Jordi Vives</div>
                <div className="quote-author-company">Vives Homes</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />

      <div className="section-one-column-left">
        <div className="title-container">
          <h2 className="title-container">Edita planos e imágenes</h2>
          <div className="title-secondary">
            Genera planos de manera sencila y haz destacar tus imágenes. No
            tendrás que salir de Pedra para darle la mejor cara a tus
            propiedades.
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container">
          <div className="feature-text-container">
            <OrangePenIcon />
            <h2 className="feature-title">
              La herramienta para dibujar planos más sencilla
            </h2>
            <div className="feature-text">
              Crea planos de planta para posicionar mejor tus listados contra
              otras agencias inmobiliarias. La herramienta de planos de planta
              de Pedra es fácil y tiene todo lo que necesitas.
            </div>
          </div>
          <img
            src={FrameFloorPlanFeature}
            alt="Herramienta de planos de planta – Captura de pantalla del software"
          />
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeSunIcon />
                <h2 className="feature-title">
                  Arregla la luz de tus fotografías inombiliarias
                </h2>
                <div className="feature-text">
                  Arregla automáticamente la iluminación de tus fotos.
                </div>
              </div>
              <img
                src={EnlightRooms}
                alt="Herramienta de corrección de luz de habitaciones – Captura de pantalla del software"
              />
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-small">
              <div className="feature-text-container">
                <OrangeHDIcon />
                <h2 className="feature-title">
                  Aumenta la definición de renders IA e imágenes
                </h2>
                <div className="feature-text">
                  Pon tus imágenes en HD con Pedra. Apreta un botón y aumenta la
                  definición.
                </div>
              </div>
              <img
                src={HDImage}
                alt="Herramienta de aumento de definición de imagen (HD) para renders IA y fotografía – Captura de pantalla del software"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeRemoveObject />
                <h2 className="feature-title">
                  Elimina fotos familiares, cruces, banderas y otros objetos
                  personales de tus fotos
                </h2>
                <div className="feature-text">
                  Llega a un público más amplio eliminando objetos personales de
                  las fotos – en 5 segundos, sin borrones ni manchas!
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeFootnoteIcon />
                <h2 className="feature-title">
                  Añade pies de foto personalizados a tus fotografías
                  inmobiliarias
                </h2>
                <div className="feature-text">
                  Añade directamente pies de página que indiquen que los
                  anuncios de propiedades inmobiliarias son, por ejemplo, no
                  contractuales, generados por IA, etc., y evita cualquier
                  confusión.
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangePerspectiveIcon />
                <h2 className="feature-title">
                  Corrige la perspectiva de tus fotos de inmuebles en 1 clic
                </h2>
                <div className="feature-text">
                  Olvídate de editar fotos de inmuebles una por una, y corrige
                  directamente la perspectiva de todo un reportaje en segundos
                  con Pedra.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="feature-container-two-features">
          <div className="feature-container-2-column">
            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeVirtualVideoIcon />
                <h2 className="feature-title">
                  Crea vídeos de inmuebles automáticamente
                </h2>
                <div className="feature-text">
                  Crea automáticamente vídeos de tus propiedades en 1 minuto,
                  para que los potenciales compradores vean los vídeos en
                  formato de vídeo.
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeSettingsIcon />
                <h2 className="feature-title">
                  Descarga imágenes de inmuebles en cualquier formato
                </h2>
                <div className="feature-text">
                  Con Pedra puedes descargar imágenes en cualquier formato y
                  convertir imágenes a cualquier formato como PNG, JPG o JPEG.
                </div>
              </div>
            </div>

            <div className="feature-container-expander"></div>

            <div className="feature-container-extra-small">
              <div className="feature-text-container">
                <OrangeWatermarkIcon />
                <h2 className="feature-title">
                  Elimina cualquier marca de agua perfectamente
                </h2>
                <div className="feature-text">
                  Elimina marcas de agua de fotografías de bienes inmuebles
                  fácilmente y con resultados limpios!
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <br />

      <div style={{ height: "50px" }}></div>

      <div className="section-one-column-left">
        <div className="title-container">¿Cómo darse de alta?</div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>PASO 1</h5>
            <h2>Dale a "Prueba Pedra" y regístrate</h2>
            <div className="value-prop-text">
              Tendrás que rellenar 3 datos: idioma, nombre y rol. Así te
              podremos ofrecer la mejor experiencia.
            </div>
          </div>

          <div className="picture">
            <img src={MLSFrame1} alt="Dale a 'Prueba Pedra' y regístrate" />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>PASO 2</h5>
            <h2>Dale a "Actualizar a Pro"</h2>
          </div>

          <div className="picture">
            <img src={MLSFrame2} alt="Dale a 'Actualizar a Pro'" />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>PASO 3</h5>
            <h2>Dale a "Suscribirse"</h2>
          </div>

          <div className="picture">
            <img src={MLSFrame3} alt="Dale a 'Suscribirse'" />
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container">
          <div className="step-text-container">
            <h5>PASO 4</h5>
            <h2>Aplica el cupón de la MLS</h2>
            <div className="value-prop-text">
              El cupón de la MLS te dará un 10% de por vida. Podrás cancelar tu
              suscripción cuando quieras.
            </div>
          </div>

          <div className="picture">
            <img src={MLSFrame4} alt="Aplica el cupón de la MLS para Pedra" />
          </div>
        </div>
      </div>

      <div className="long-break"> </div>
      <div className="section-first-button-container">
        <button className="button-top-of-page" onClick={redirectToPage}>
          Prueba Pedra <ArrowIcon />
        </button>
      </div>

      <br />
      <br />
      <br />
      <br />
      <br />

      <div className="section-one-column-left">
        <div className="title-container">Preguntas y respuestas</div>
      </div>

      <div className="section-one-column-left">
        <div className="faq-container">
          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ1(!FAQ1)}>
              <h3 className="question-title">¿Qué es un render IA?</h3>
              {FAQ1 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ1 && (
              <div className="answer-container">
                Un render IA, en el contexto del diseño y la visualización
                arquitectónica, se refiere a la aplicación de la inteligencia
                artificial (IA) para generar imágenes renderizadas de alta
                calidad de espacios arquitectónicos. La inteligencia artificial
                se utiliza para mejorar y optimizar el proceso de renderizado,
                permitiendo resultados más realistas y eficientes.
                <br />
                <br />
                La IA en el renderizado permite conseguir resultados de manera
                más rápida que con el método tradicional. Lo métodos antiguos
                involucran horas o semanas de trabajo ya que hay que re-crear
                modelos 3D de las habitaciones y procesar los sistemas de
                renderizado. Con la IA esto ha cambiado. Los renders pueden
                generarse a partir de imágenes y crear resultados espectaculares
                a partir de la imagen original con nuevos estilos aplicados.
                <br />
                <br />
                La inteligencia artificial en el renderizado de imágenes de
                propiedades inmobiliarias no solo ahorra tiempo si no que da
                flexibilidad a la hora de imaginar espacios para los
                inmobiliarios, los propietarios y los compradores. La tecnología
                ayuda a visualizar el potencial de los espaciso de una manera
                nunca vista.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ2(!FAQ2)}>
              <h3 className="question-title">
                ¿Qué es un home staging virtual?
              </h3>
              {FAQ2 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ2 && (
              <div className="answer-container">
                El home staging virtual es una técnica utilizada en el mercado
                inmobiliario para presentar una propiedad de manera más
                atractiva y agradable a los posibles compradores o
                arrendatarios, pero a diferencia del home staging tradicional,
                que implica la preparación física y decoración del espacio, el
                home staging virtual se realiza de forma digital.
                <br />
                <br />
                En el home staging virtual, se utilizan herramientas como
                software de diseño 3D, realidad virtual (VR) y fotografía
                digital para crear representaciones digitales realistas de una
                propiedad. Estas representaciones pueden incluir imágenes
                renderizadas de alta calidad, recorridos virtuales por la
                propiedad y visualizaciones interactivas que muestran cómo se
                vería el espacio con diferentes configuraciones de muebles y
                decoración.
                <br />
                <br />
                El objetivo del home staging virtual es permitir que los
                posibles compradores o arrendatarios tengan una mejor idea de
                cómo se vería la propiedad una vez amueblada y decorada, lo que
                puede ayudar a aumentar el interés y las posibilidades de venta
                o alquiler. Además, el home staging virtual puede ser
                especialmente útil en situaciones donde la propiedad no está
                completamente amueblada o está vacía, ya que puede ayudar a los
                clientes a visualizar el potencial del espacio.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ3(!FAQ3)}>
              <h3 className="question-title">¿Es Pedra sencillo de usar?</h3>
              {FAQ3 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ3 && (
              <div className="answer-container">
                Sí, Pedra esta diseñada para tener un uso sencillo y ágil.
                <br />
                <br />
                Al crear un proyecto podrás subir una o más imágenes. Podrás
                hacer click para seleccionar imágenes, lo cual deplegará una
                barra de opciones. Las opciones incluyen{" "}
                <span style={{ fontWeight: "600px" }}> Editar con IA</span>,
                añadir marca de agua, descargar en varios formatos y más.
                Creando un proyecto también tendrás la opción de dibujar un
                plano. El plano contiene varias figuras, la cuales podrás editar
                seleccionandolas, arranstrandolas o a través del menú.
              </div>
            )}
          </div>

          <div className="question-answer-container">
            <div className="question-container" onClick={() => setFAQ4(!FAQ4)}>
              <h3 className="question-title">¿Cuanto cuesta Pedra?</h3>
              {FAQ4 ? <ToggleOpenIcon /> : <ToggleIcon />}
            </div>
            {FAQ4 && (
              <div className="answer-container">
                Pedra cuesta 29€ más IVA al mes.
                <br />
                <br />
                No tiene permanencia, así que puedes darte de baja cuando ya no
                lo necesites.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default MlsEs;
