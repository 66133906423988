import React from "react";
import ReleaseNov2024Frame1 from "../Images/ReleaseNov2024Frame1.png";
import ReleaseNov2024Frame2 from "../Images/ReleaseNov2024Frame2.png";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function ReleaseNov24() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "60px" }}>
          <Link className="go-back" to="/releases">
            &#8592; Releases
          </Link>
          <br />

          <div className="title-secondary gray small">NOVEMBRE 2024</div>
          <h1 className="article-titleh1">
            Pedra : Collaboration d'équipe et reconnaissance sectorielle
          </h1>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Felix Ingla"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fondateur de Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <div className="article-text">
              Ce novembre, nous vous apportons des nouveautés pour faciliter la
              collaboration avec votre équipe, ainsi qu'une réussite de Pedra
              que nous sommes fiers de partager :
            </div>
            <br />
            <div className="article-text">
              <h2 className="article-subtitleh2">
                Inviter des membres à votre espace de travail
              </h2>
              Vous pouvez maintenant inviter d'autres membres à votre espace de
              travail pour que différentes personnes de votre agence puissent
              travailler sur Pedra. Il suffit d'écrire leur email et de les
              inviter.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseNov2024Frame1}
                  alt="Fonction d'invitation à l'espace de travail"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Prix de l'innovation IA dans l'immobilier 2024
              </h2>
              L'Ordre des Agents Immobiliers nous a décerné le prix de
              l'innovation IA dans le secteur immobilier. Nous avons reçu 56%
              des votes de 600 agents.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseNov2024Frame2}
                  alt="Prix de l'innovation"
                />
              </div>
            </div>
            <br />
            <br />
            <h2 className="article-subtitleh2">Autres améliorations</h2>
            <div>
              <li>
                Option de rénovation et d'ameublement pour "Cuisine et salle à
                manger" et "Cuisine et salon"
              </li>
              <li>Vidage de pièce désormais plus rapide</li>
              <li>
                Option de service professionnel de vidage virtuel de pièces à 4€
                par image
              </li>
              <li>Optimisation du poids des images téléchargées</li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReleaseNov24;
