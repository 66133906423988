import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import HowToRenovateFrame1 from "../Images/HowToRenovateFrame1.jpg";
import HowToRenovateFrame2 from "../Images/HowToRenovateFrame2.jpg";
import HowToRenovateFrame3 from "../Images/HowToRenovateFrame3.jpg";
import InfoIcon from "../Icons/InfoIcon";

import BeforeHouseStaging1 from "../Images/curtain-effect/BeforeHouseStaging1.png";
import AfterHouseStaging1 from "../Images/curtain-effect/AfterHouseStaging1.png";
import BeforeHouseStaging2 from "../Images/curtain-effect/BeforeHouseStaging2.png";
import AfterHouseStaging2 from "../Images/curtain-effect/AfterHouseStaging2.png";
import BeforeHouseStaging3 from "../Images/curtain-effect/BeforeHouseStaging3.png";
import AfterHouseStaging3 from "../Images/curtain-effect/AfterHouseStaging3.png";

import CurtainEffect from "../CurtainEffect";

function HowToRenderHouseEs() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            Cómo Crear Renders de Exterior de Casa
          </h1>

          <div className="title-secondary gray">
            Tutorial sobre Cómo Crear Renders de Exterior de Casa
          </div>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Fundador de Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fundador de Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              ¿Por qué usar las herramientas de render de Pedra para exteriores
              de casa?
            </h2>
            <p className="article-text">
              Las herramientas de render de Pedra te permiten visualizar una
              transformación completa del exterior de una casa en segundos,
              ayudando a los compradores potenciales a ver el verdadero
              potencial de la propiedad.
              <p style={{ marginTop: "10px" }}>
                Las renovaciones exteriores son a menudo la parte más impactante
                de las mejoras del hogar, ya que definen la primera impresión de
                cualquier propiedad. Poder mostrar el potencial de un exterior
                anticuado puede marcar una gran diferencia en la venta de
                propiedades de segunda mano.
              </p>
              <p style={{ marginTop: "10px" }}>
                Con Pedra, ya no necesitas arquitectos especializados ni
                expertos en renderizado para crear propuestas convincentes de
                renovación exterior.
              </p>
            </p>
            <h2 className="article-subtitleh2">
              ¿Cómo creo un render de exterior de casa?
            </h2>
            <p className="article-text">
              Comienza subiendo fotos claras del exterior de la casa existente
              que quieres transformar.
              <p style={{ marginTop: "10px" }}>
                Selecciona tus imágenes exteriores y haz clic en{" "}
                <span style={{ fontWeight: "500" }}>"Editar con IA"</span>,
                luego elige la opción{" "}
                <span style={{ fontWeight: "500" }}>"Renovación"</span>.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToRenovateFrame1}
                alt="Cómo usar la herramienta de renovación para crear renders de exterior de casa – Captura de pantalla del software Pedra"
              />
            </p>
            <h2 className="article-subtitleh2">
              <span style={{ fontWeight: "600" }}>
                Renovación Exterior Rápida:{" "}
              </span>
              Genera un Render Rápido de Exterior de Casa
            </h2>
            <p style={{ marginTop: "10px" }}>
              La herramienta{" "}
              <span style={{ fontWeight: "500" }}>"Renovación"</span>
              transformará el exterior de tu casa en aproximadamente 25
              segundos.
              <p>
                Para una actualización sutil, elige el estilo "Minimalista" con
                creatividad "Baja". Esto mantendrá la estructura básica mientras
                moderniza los acabados y elementos de la fachada.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "20px 0px",
                }}
                src={HowToRenovateFrame2}
                alt="Cómo usar la herramienta de renovación para crear renders de exterior de casa – Captura de pantalla del software Pedra"
              />
            </p>

            <h2 className="article-subtitleh2">
              Personaliza tu Render de Renovación de Exterior
            </h2>
            <p className="article-text">
              Pedra ofrece extensas opciones de personalización para renders
              exteriores.
              <p style={{ marginTop: "10px" }}>
                <p style={{ marginTop: "10px" }}>
                  Elige entre varios estilos arquitectónicos, controla qué tan
                  dramáticos serán los cambios y decide si deseas preservar
                  elementos existentes como ventanas o características
                  distintivas.
                </p>
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                    margin: "20px 0px",
                  }}
                  src={HowToRenovateFrame3}
                  alt="Cómo usar la herramienta de renovación para crear renders de exterior de casa – Captura de pantalla del software Pedra"
                />
              </p>
            </p>
            <br />
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Paso 1 – </span>
              Elige tu estilo arquitectónico
            </h3>
            <p className="article-text">
              Selecciona entre 9 diferentes estilos arquitectónicos incluyendo
              moderno, tradicional, mediterráneo y más.
              <p className="article-text" style={{ marginTop: "10px" }}>
                <span style={{ fontWeight: "500" }}>
                  También puedes subir imágenes de referencia de estilos de casa
                  que te gusten.
                </span>{" "}
                Esto te permite crear renders que coincidan con una inspiración
                de diseño específica o el estilo preferido de tu cliente.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Paso 2 – </span>
              Establece la intensidad de la renovación
            </h3>
            <p className="article-text">
              Elige entre niveles de creatividad Baja, Media, Alta y Muy Alta
              para tu transformación exterior.
              <p style={{ marginTop: "10px" }}>
                Los ajustes Bajo y Medio actualizarán los acabados y elementos
                de fachada mientras mantienen intacta la estructura básica.
              </p>
              <p style={{ marginTop: "10px" }}>
                Los ajustes Alto y Muy Alto pueden reimaginar completamente el
                exterior con nuevos elementos arquitectónicos, líneas de techo y
                cambios estructurales.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Paso 3 – </span>
              Preserva elementos clave del exterior
            </h3>
            <p className="article-text">
              Selecciona "preservar partes" y dibuja directamente en la imagen
              para indicar las áreas que deseas mantener sin cambios. Esto es
              particularmente útil para:
              <p style={{ marginTop: "10px" }}>
                <ul>
                  <li>Edificios vecinos que quieres mantener intactos</li>
                  <li>La calle o el paisaje circundante</li>
                  <li>Características arquitectónicas distintivas</li>
                  <li>Ventanas u otros elementos que deseas conservar</li>
                </ul>
              </p>
              <p style={{ marginTop: "10px" }}>
                Simplemente dibuja sobre las áreas que deseas preservar antes de
                hacer clic en generar. Esto asegura que tu render se enfoque
                solo en renovar las partes deseadas del exterior mientras
                mantiene el contexto y los alrededores.
              </p>
              <br />
            </p>
            <h2 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Paso 4 – </span>
              Genera tu render exterior
            </h2>
            <p>
              Después de seleccionar tus preferencias, genera el render
              exterior. Los renders estándar tardan unos 15 segundos, mientras
              que preservar elementos específicos puede tomar un poco más de
              tiempo.
            </p>
            <aside className="callout">
              <div>
                <InfoIcon />
              </div>
              <div className="callout-text">
                <h4 className="callout-h4">
                  Consejos para mejores renders de exterior de casa
                </h4>
                <p className="callout-p">
                  Para obtener renders exteriores más realistas y útiles:
                  <ul>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Genera múltiples versiones
                      </span>
                      . Cada render es único, y podrías encontrar algunos que
                      preserven mejor las características importantes como
                      detalles arquitectónicos o elementos distintivos.
                    </li>
                    <li style={{ fontWeight: "500" }}>
                      Usa creatividad baja para actualizaciones sutiles en
                      exteriores antiguos.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Toma fotos en un día nublado para minimizar sombras
                        fuertes, y captura la fachada completa
                      </span>
                      . Esto ayuda a la IA a comprender mejor la estructura y
                      crear transformaciones más precisas.
                    </li>
                  </ul>
                </p>
              </div>
            </aside>
            <h2 className="article-subtitleh2">
              Ejemplos de renders de exterior de casa
            </h2>
            <p className="article-text">
              Aquí hay varios ejemplos de renovaciones exteriores creadas con
              nuestros renders:
            </p>
            <CurtainEffect
              beforeImage={BeforeHouseStaging1}
              afterImage={AfterHouseStaging1}
              altBefore={"Exterior de casa antes del render"}
              altAfter={"Exterior de casa después del render"}
            />
            <CurtainEffect
              beforeImage={BeforeHouseStaging2}
              afterImage={AfterHouseStaging2}
              altBefore={"Exterior de casa antes del render"}
              altAfter={"Exterior de casa después del render"}
            />
            <CurtainEffect
              beforeImage={BeforeHouseStaging3}
              afterImage={AfterHouseStaging3}
              altBefore={"Exterior de casa antes del render"}
              altAfter={"Exterior de casa después del render"}
            />
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default HowToRenderHouseEs;
