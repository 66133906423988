import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import HowToRenovateFrame1 from "../Images/HowToRenovateFrame1.jpg";
import HowToRenovateFrame2 from "../Images/HowToRenovateFrame2.jpg";
import HowToRenovateFrame3 from "../Images/HowToRenovateFrame3.jpg";
import InfoIcon from "../Icons/InfoIcon";

import BeforeHouseStaging1 from "../Images/curtain-effect/BeforeHouseStaging1.png";
import AfterHouseStaging1 from "../Images/curtain-effect/AfterHouseStaging1.png";
import BeforeHouseStaging2 from "../Images/curtain-effect/BeforeHouseStaging2.png";
import AfterHouseStaging2 from "../Images/curtain-effect/AfterHouseStaging2.png";
import BeforeHouseStaging3 from "../Images/curtain-effect/BeforeHouseStaging3.png";
import AfterHouseStaging3 from "../Images/curtain-effect/AfterHouseStaging3.png";

import CurtainEffect from "../CurtainEffect";

function HowToRenderHouse() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            Comment Créer des Rendus d'Extérieur de Maison
          </h1>

          <div className="title-secondary gray">
            Tutoriel sur la Création de Rendus d'Extérieur de Maison
          </div>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Fondateur de Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fondateur de Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Pourquoi utiliser les outils de rendu Pedra pour l'extérieur de
              maison ?
            </h2>
            <p className="article-text">
              Les outils de rendu Pedra vous permettent de visualiser une
              transformation complète de l'extérieur d'une maison en quelques
              secondes, aidant les acheteurs potentiels à voir le véritable
              potentiel de la propriété.
              <p style={{ marginTop: "10px" }}>
                Les rénovations extérieures sont souvent la partie la plus
                impactante des améliorations de la maison, car elles définissent
                la première impression de toute propriété. Pouvoir montrer le
                potentiel d'un extérieur démodé peut faire une grande différence
                dans la vente de propriétés d'occasion.
              </p>
              <p style={{ marginTop: "10px" }}>
                Avec Pedra, vous n'avez plus besoin d'architectes spécialisés ni
                d'experts en rendu pour créer des propositions convaincantes de
                rénovation extérieure.
              </p>
            </p>
            <h2 className="article-subtitleh2">
              Comment créer un rendu d'extérieur de maison ?
            </h2>
            <p className="article-text">
              Commencez par télécharger des photos claires de l'extérieur
              existant de la maison que vous souhaitez transformer.
              <p style={{ marginTop: "10px" }}>
                Sélectionnez vos images extérieures et cliquez sur{" "}
                <span style={{ fontWeight: "500" }}>"Éditer avec l'IA"</span>,
                puis choisissez l'option{" "}
                <span style={{ fontWeight: "500" }}>"Rénovation"</span>.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToRenovateFrame1}
                alt="Comment utiliser l'outil de rénovation pour créer des rendus d'extérieur de maison – Capture d'écran du logiciel Pedra"
              />
            </p>
            <h2 className="article-subtitleh2">
              <span style={{ fontWeight: "600" }}>
                Rénovation Extérieure Rapide :{" "}
              </span>
              Générez un Rendu Rapide d'Extérieur de Maison
            </h2>
            <p style={{ marginTop: "10px" }}>
              L'outil <span style={{ fontWeight: "500" }}>"Rénovation"</span>
              transformera l'extérieur de votre maison en environ 25 secondes.
              <p>
                Pour une mise à jour subtile, choisissez le style "Minimaliste"
                avec une créativité "Basse". Cela maintiendra la structure de
                base tout en modernisant les finitions et les éléments de la
                façade.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "20px 0px",
                }}
                src={HowToRenovateFrame2}
                alt="Comment utiliser l'outil de rénovation pour créer des rendus d'extérieur de maison – Capture d'écran du logiciel Pedra"
              />
            </p>

            <h2 className="article-subtitleh2">
              Personnalisez votre Rendu de Rénovation d'Extérieur
            </h2>
            <p className="article-text">
              Pedra offre de nombreuses options de personnalisation pour les
              rendus extérieurs.
              <p style={{ marginTop: "10px" }}>
                <p style={{ marginTop: "10px" }}>
                  Choisissez parmi plusieurs styles architecturaux, contrôlez
                  l'importance des changements et décidez si vous souhaitez
                  préserver des éléments existants comme les fenêtres ou les
                  caractéristiques distinctives.
                </p>
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                    margin: "20px 0px",
                  }}
                  src={HowToRenovateFrame3}
                  alt="Comment utiliser l'outil de rénovation pour créer des rendus d'extérieur de maison – Capture d'écran du logiciel Pedra"
                />
              </p>
            </p>
            <br />
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Étape 1 – </span>
              Choisissez votre style architectural
            </h3>
            <p className="article-text">
              Sélectionnez parmi 9 styles architecturaux différents incluant
              moderne, traditionnel, méditerranéen et plus encore.
              <p className="article-text" style={{ marginTop: "10px" }}>
                <span style={{ fontWeight: "500" }}>
                  Vous pouvez également télécharger des images de référence de
                  styles de maison que vous aimez.
                </span>{" "}
                Cela vous permet de créer des rendus qui correspondent à une
                inspiration de design spécifique ou au style préféré de votre
                client.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Étape 2 – </span>
              Définissez l'intensité de la rénovation
            </h3>
            <p className="article-text">
              Choisissez entre les niveaux de créativité Basse, Moyenne, Haute
              et Très Haute pour votre transformation extérieure.
              <p style={{ marginTop: "10px" }}>
                Les réglages Bas et Moyen mettront à jour les finitions et les
                éléments de façade tout en maintenant la structure de base
                intacte.
              </p>
              <p style={{ marginTop: "10px" }}>
                Les réglages Haut et Très Haut peuvent réimaginer complètement
                l'extérieur avec de nouveaux éléments architecturaux, lignes de
                toit et changements structurels.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Étape 3 – </span>
              Préservez les éléments clés de l'extérieur
            </h3>
            <p className="article-text">
              Sélectionnez "préserver les parties" et dessinez directement sur
              l'image pour indiquer les zones que vous souhaitez garder
              inchangées. C'est particulièrement utile pour :
              <p style={{ marginTop: "10px" }}>
                <ul>
                  <li>Les bâtiments voisins que vous voulez garder intacts</li>
                  <li>La rue ou le paysage environnant</li>
                  <li>Les caractéristiques architecturales distinctives</li>
                  <li>
                    Les fenêtres ou autres éléments que vous souhaitez conserver
                  </li>
                </ul>
              </p>
              <p style={{ marginTop: "10px" }}>
                Dessinez simplement sur les zones que vous souhaitez préserver
                avant de cliquer sur générer. Cela garantit que votre rendu se
                concentre uniquement sur la rénovation des parties souhaitées de
                l'extérieur tout en maintenant le contexte et les alentours.
              </p>
              <br />
            </p>
            <h2 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Étape 4 – </span>
              Générez votre rendu extérieur
            </h2>
            <p>
              Après avoir sélectionné vos préférences, générez le rendu
              extérieur. Les rendus standard prennent environ 15 secondes,
              tandis que la préservation d'éléments spécifiques peut prendre un
              peu plus de temps.
            </p>
            <aside className="callout">
              <div>
                <InfoIcon />
              </div>
              <div className="callout-text">
                <h4 className="callout-h4">
                  Conseils pour de meilleurs rendus d'extérieur de maison
                </h4>
                <p className="callout-p">
                  Pour obtenir des rendus extérieurs plus réalistes et utiles :
                  <ul>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Générez plusieurs versions
                      </span>
                      . Chaque rendu est unique, et vous pourriez en trouver
                      certains qui préservent mieux les caractéristiques
                      importantes comme les détails architecturaux ou les
                      éléments distinctifs.
                    </li>
                    <li style={{ fontWeight: "500" }}>
                      Utilisez une créativité basse pour des mises à jour
                      subtiles sur les extérieurs anciens.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Prenez des photos par temps nuageux pour minimiser les
                        ombres fortes, et capturez la façade complète
                      </span>
                      . Cela aide l'IA à mieux comprendre la structure et créer
                      des transformations plus précises.
                    </li>
                  </ul>
                </p>
              </div>
            </aside>
            <h2 className="article-subtitleh2">
              Exemples de rendus d'extérieur de maison
            </h2>
            <p className="article-text">
              Voici plusieurs exemples de rénovations extérieures créées avec
              nos rendus :
            </p>
            <CurtainEffect
              beforeImage={BeforeHouseStaging1}
              afterImage={AfterHouseStaging1}
              altBefore={"Extérieur de maison avant le rendu"}
              altAfter={"Extérieur de maison après le rendu"}
            />
            <CurtainEffect
              beforeImage={BeforeHouseStaging2}
              afterImage={AfterHouseStaging2}
              altBefore={"Extérieur de maison avant le rendu"}
              altAfter={"Extérieur de maison après le rendu"}
            />
            <CurtainEffect
              beforeImage={BeforeHouseStaging3}
              afterImage={AfterHouseStaging3}
              altBefore={"Extérieur de maison avant le rendu"}
              altAfter={"Extérieur de maison après le rendu"}
            />
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default HowToRenderHouse;
