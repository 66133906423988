// LangManager.js
// This code:
//   -> Changes the lang attribute from index.html depending on the url of the page
//   -> Changes the canonical URL setting from index.html depending on the url of the page
//   -> Changes the meta description from index.html depending on the url of the page
//   -> Changes the meta title from index.html depending on the url of the page
// This helps with SEO, since pages can then know if the language is properly set for the page

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const LangManager = () => {
  const location = useLocation();

  useEffect(() => {
    // Check if the URL includes 'es'
    const newLang = location.pathname.includes("/es")
      ? "es"
      : location.pathname.includes("/fr")
      ? "fr"
      : "en";

    // Update the lang attribute of the HTML tag
    document.documentElement.lang = newLang;

    // Update the canonical setting based on the URL
    const canonicalLink = document.querySelector('link[rel="canonical"]');
    if (canonicalLink) {
      const currentUrl = window.location.href;
      canonicalLink.href = currentUrl;
    }

    // Update the meta description based on the URL
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      const currentUrl = window.location.href;
      let newDescription = "";

      if (currentUrl.includes("floorplan")) {
        newDescription =
          newLang === "es"
            ? "Dibuja planos de planta con la herramienta online para crear planos de Pedra, además te explicamos cómo crearlos de forma sencilla paso a paso."
            : newLang === "fr"
            ? "Dessinez des plans d'étage avec l'outil en ligne pour créer des plans avec Pedra, en plus nous vous expliquons comment les créer facilement étape par étape."
            : "Create a floor plan in seconds. You can design floor plans with different layer types: doors, windows, furniture and edit their parameters.";
      } else if (
        [
          "/es/blog/rentabilidad-home-staging-virtual",
          "/fr/blog/rentabilite-home-staging-virtuel",
          "/blog/roi-virtual-staging",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Analysez la rentabilité du home staging virtuel dans l'immobilier avec des données réelles des principales agences du marché."
          : newLang === "es"
          ? "Descubre la rentabilidad del home staging virtual en el sector inmobiliario con datos reales de las principales agencias."
          : "Learn about the ROI of virtual staging in real estate with concrete data and insights from top agencies across market segments.";
      } else if (
        [
          "/help/render-floor-plan-with-ai",
          "/fr/help/rendu-plan-etage-avec-ia",
          "/es/help/renderizar-plano-de-planta-con-ia",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Transformez vos plans en visualisations 3D photoréalistes avec l'IA. Guide pas à pas pour créer des rendus de qualité instantanément."
          : newLang === "es"
          ? "Transforma tus planos en imágenes fotorrealistas con IA. Tutorial paso a paso para crear renders de alta calidad al instante."
          : "Transform your floor plans into photorealistic 3D visuals with AI. Step-by-step guide to creating instant, high-quality room renders.";
      } else if (
        [
          "render-desde-plano",
          "render-depuis-plan",
          "render-from-floor-plan",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Transformez vos plans d'étage en images réalistes. Notre outil génère automatiquement des visuels pour chaque pièce de votre plan."
          : newLang === "es"
          ? "Convierte tus planos en imágenes realistas. Nuestra herramienta genera automáticamente visualizaciones para cada habitación de tu plano."
          : "Transform your floor plans into realistic images. Our tool automatically generates visuals for every room in your floor plan.";
      } else if (
        [
          "/es/blog/home-staging-tradicional-vs-home-staging-virtual",
          "/fr/blog/home-staging-traditionnel-vs-home-staging-virtuel",
          "/blog/traditional-home-staging-vs-virtual-home-staging",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Comparez le home staging virtuel et traditionnel : coûts, avantages et inconvénients. Découvrez quelle méthode est la plus efficace pour valoriser votre bien immobilier."
          : newLang === "es"
          ? "Compara el home staging virtual y tradicional: costos, ventajas y desventajas. Descubre qué método es más efectivo para realzar el valor de tu propiedad."
          : "Compare virtual and traditional home staging: costs, pros, and cons. Discover which method is more effective for enhancing your property's value.";
      } else if (
        [
          "/es/releases/espacios-de-trabajo",
          "/fr/releases/espaces-de-travail",
          "/releases/workspaces",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Ce mois de novembre, découvrez les nouveaux espaces de travail pour collaborer sur vos projets de home staging virtuel."
          : newLang === "es"
          ? "Este noviembre, descubre los nuevos espacios de trabajo para colaborar en tus proyectos de home staging virtual."
          : "This November, discover the new workspaces for collaborating on your virtual staging projects.";
      } else if (
        [
          "/fr/help/comment-creer-rendu-exterieur-maison",
          "/help/how-to-create-house-exterior-render",
          "/es/help/como-crear-render-exterior-casa",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Maîtrisez le rendu extérieur de maison avec notre guide simple. Transformez vos photos en visualisations réalistes et professionnelles."
          : newLang === "es"
          ? "Domina el render exterior de casa con nuestra guía fácil. Transforma tus fotos en visualizaciones realistas y profesionales."
          : "Master house exterior rendering with our easy guide. Transform your photos into realistic and professional visualizations.";
      } else if (
        [
          "/fr/help/comment-dessiner-plan",
          "/help/how-to-draw-floor-plan",
          "/es/help/como-dibujar-plano",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Maîtrisez la création de plans d'étage avec notre guide simple. Créez des plans professionnels sans être architecte."
          : newLang === "es"
          ? "Domina el dibujo de planos con nuestra guía fácil. Crea planos profesionales sin ser arquitecto."
          : "Master floor plan creation with our easy guide. Create professional floor plans without being an architect.";
      } else if (
        [
          "/fr/help/comment-creer-rendu-terrasse",
          "/help/how-to-create-terrace-render",
          "/es/help/como-crear-render-terraza",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Maîtrisez le rendu de terrasse avec notre guide simple. Transformez des espaces vides en terrasses fonctionnelles et élégantes."
          : newLang === "es"
          ? "Domina el render de terraza con nuestra guía fácil. Transforma espacios vacíos en terrazas funcionales y elegantes."
          : "Master terrace rendering with our easy guide. Transform empty spaces into functional and elegant terraces.";
      } else if (
        [
          "/fr/help/comment-creer-rendu-chambre",
          "/help/how-to-create-bedroom-render",
          "/es/help/como-crear-render-dormitorio",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Maîtrisez le rendu de chambre avec notre guide simple. Transformez des espaces vides en chambres fonctionnelles et élégantes."
          : newLang === "es"
          ? "Domina el render de dormitorio con nuestra guía fácil. Transforma espacios vacíos en dormitorios funcionales y elegantes."
          : "Master bedroom rendering with our easy guide. Transform empty spaces into functional and elegant bedrooms.";
      } else if (
        [
          "/fr/help/comment-creer-rendu-salon",
          "/help/how-to-create-living-room-render",
          "/es/help/como-crear-render-sala-estar",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Maîtrisez le rendu de salon avec notre guide simple. Transformez des espaces vides en salons fonctionnels et élégants."
          : newLang === "es"
          ? "Domina el render de sala de estar con nuestra guía fácil. Transforma espacios vacíos en salas funcionales y elegantes."
          : "Master living room rendering with our easy guide. Transform empty spaces into functional and elegant living rooms.";
      } else if (
        [
          "/fr/help/comment-creer-rendu-salle-de-bain",
          "/help/how-to-create-bathroom-render",
          "/es/help/como-crear-render-bano",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Maîtrisez le rendu de salle de bain avec notre guide simple. Transformez des salles de bain vides en espaces fonctionnels et élégants."
          : newLang === "es"
          ? "Domina el render de baños con nuestra guía fácil. Transforma espacios vacíos en baños funcionales y elegantes."
          : "Master bathroom rendering with our easy guide. Transform empty spaces into functional and elegant bathrooms.";
      } else if (
        [
          "/fr/help/comment-creer-rendu-cuisine",
          "/help/how-to-create-kitchen-render",
          "/es/help/como-crear-render-cocina",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Maîtrisez le rendu 3D de cuisine avec notre guide simple. Transformez des cuisines vides en espaces fonctionnels et élégants."
          : newLang === "es"
          ? "Domina el render de cocinas con nuestra guía fácil. Transforma espacios vacíos en cocinas funcionales y elegantes."
          : "Master kitchen rendering with our easy guide. Transform empty spaces into functional and elegant kitchens.";
      } else if (
        ["/fr/rendu-maison", "/es/render-casa", "/render-house"].some((path) =>
          currentUrl.includes(path)
        )
      ) {
        newDescription =
          newLang === "es"
            ? "Crea renders fotorrealistas de casas automáticamente. Nuestro servicio genera visualizaciones profesionales de casas en minutos. Renders de casa rápidos y asequibles."
            : newLang === "fr"
            ? "Créez des rendus photoréalistes de maisons automatiquement. Notre service génère des visualisations professionnelles de maisons en quelques minutes. Rendus de maison rapides et abordables."
            : "Create photorealistic house renders automatically. Our service generates professional house visualizations in minutes. Fast and affordable house renders.";
      } else if (
        ["/fr/rendu-salon", "/es/render-salon", "/living-room-render"].some(
          (path) => currentUrl.includes(path)
        )
      ) {
        newDescription =
          newLang === "es"
            ? "Crea renders fotorrealistas de salones automáticamente. Nuestro servicio genera visualizaciones profesionales de salones en minutos. Renders de salón rápidos y asequibles."
            : newLang === "fr"
            ? "Créez des rendus photoréalistes de salons automatiquement. Notre service génère des visualisations professionnelles de salons en quelques minutes. Rendus de salon rapides et abordables."
            : "Create photorealistic living room renders automatically. Our service generates professional living room visualizations in minutes. Fast and affordable living room renders.";
      } else if (
        ["/fr/rendu-terrasse", "/es/render-terraza", "/render-terrace"].some(
          (path) => currentUrl.includes(path)
        )
      ) {
        newDescription =
          newLang === "es"
            ? "Crea renders fotorrealistas de terrazas automáticamente. Nuestro servicio genera visualizaciones profesionales de terrazas en minutos. Renders de terraza rápidos y asequibles."
            : newLang === "fr"
            ? "Créez des rendus photoréalistes de terrasses automatiquement. Notre service génère des visualisations professionnelles de terrasses en quelques minutes. Rendus de terrasse rapides et abordables."
            : "Create photorealistic terrace renders automatically. Our service generates professional terrace visualizations in minutes. Fast and affordable terrace renders.";
      } else if (
        ["/fr/rendu-salle-de-bain", "/es/render-bano", "/render-bathroom"].some(
          (path) => currentUrl.includes(path)
        )
      ) {
        newDescription =
          newLang === "es"
            ? "Crea renders fotorrealistas de baños automáticamente. Nuestro servicio genera visualizaciones profesionales de baños en minutos. Renders de baño rápidos y asequibles."
            : newLang === "fr"
            ? "Créez des rendus photoréalistes de salles de bain automatiquement. Notre service génère des visualisations professionnelles de salles de bain en quelques minutes. Rendus de salle de bain rapides et abordables."
            : "Create photorealistic bathroom renders automatically. Our service generates professional bathroom visualizations in minutes. Fast and affordable bathroom renders.";
      } else if (
        ["/fr/rendu-cuisine", "/es/render-cocina", "/render-kitchen"].some(
          (path) => currentUrl.includes(path)
        )
      ) {
        newDescription =
          newLang === "es"
            ? "Crea renders fotorrealistas de cocinas automáticamente. Nuestro servicio genera visualizaciones profesionales de cocinas en minutos. Renders de cocina rápidos y asequibles."
            : newLang === "fr"
            ? "Créez des rendus photoréalistes de cuisines automatiquement. Notre service génère des visualisations professionnelles de cuisines en quelques minutes. Rendus de cuisine rapides et abordables."
            : "Create photorealistic kitchen renders automatically. Our service generates professional kitchen visualizations in minutes. Fast and affordable kitchen renders.";
      } else if (
        ["/fr/rendu-chambre", "/es/render-dormitorio", "/render-bedroom"].some(
          (path) => currentUrl.includes(path)
        )
      ) {
        newDescription =
          newLang === "es"
            ? "Crea renders fotorrealistas de dormitorios automáticamente. Nuestro servicio genera visualizaciones profesionales de dormitorios en minutos. Renders de dormitorio rápidos y asequibles."
            : newLang === "fr"
            ? "Créez des rendus photoréalistes de chambres automatiquement. Notre service génère des visualisations professionnelles de chambres en quelques minutes. Rendus de chambre rapides et abordables."
            : "Create photorealistic bedroom renders automatically. Our service generates professional bedroom visualizations in minutes. Fast and affordable bedroom renders.";
      } else if (
        [
          "/es/blog/como-vender-casa-a-reformar",
          "/fr/blog/comment-vendre-une-maison-a-renover",
          "/blog/how-to-sell-a-house-to-renovate",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Découvrez comment vendre une maison à rénover avec succès. Guide pour mettre en valeur le potentiel de rénovation, fixer un prix attractif et attirer les acheteurs."
          : newLang === "es"
          ? "Descubre estrategias clave para vender una casa a reformar. Aprende a destacar su potencial, fijar el precio correcto y atraer compradores interesados en proyectos de renovación."
          : "Learn effective strategies for selling a house that needs renovation. Tips on highlighting potential, setting the right price, and attracting buyers interested in renovation projects.";
      } else if (
        [
          "/es/renders-para-reformas",
          "/renders-for-renovations",
          "/fr/rendus-de-renovation",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Transformez vos projets de rénovation avec nos rendus professionnels. Service automatisé pour architectes et décorateurs. Visualisation rapide et précise de vos rénovations."
          : newLang === "es"
          ? "Transforma tus proyectos de reforma con renders profesionales. Servicio automatizado para arquitectos y decoradores. Visualización rápida y precisa de tus reformas."
          : "Transform your renovation projects with professional renders. Automated service for architects and decorators. Fast and accurate visualization of your renovations.";
      } else if (
        [
          "/es/releases/mejoras-pedra-piscinas-y-preservacion",
          "/fr/releases/ameliorations-pedra-piscines-et-preservation",
          "/releases/pedra-improvements-pools-and-preservation",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Ce mois d'octobre, découvrez les nouvelles fonctionnalités de Pedra pour améliorer vos piscines et préserver vos espaces avec plus de précision que jamais."
          : newLang === "es"
          ? "Este octubre, descubre las nuevas funcionalidades de Pedra para mejorar tus piscinas y preservar tus espacios con más precisión que nunca."
          : "This October, discover Pedra's new features to enhance your pools and preserve your spaces with more precision than ever.";
      } else if (
        [
          "/fr/help/comment-creer-home-staging-virtuel-salle-de-bain",
          "/help/how-to-create-bathroom-virtual-home-staging",
          "/es/help/como-crear-home-staging-virtual-bano",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Maîtrisez le home staging virtuel de salle de bain avec notre guide simple. Transformez des salles de bain vides en espaces fonctionnels et accueillants."
          : newLang === "es"
          ? "Domina el home staging virtual de baños con nuestra guía fácil. Transforma baños vacíos en espacios funcionales y acogedores."
          : "Master bathroom virtual home staging with our easy guide. Transform empty bathrooms into functional, inviting spaces.";
      } else if (
        [
          "/fr/help/comment-creer-home-staging-virtuel-cuisine",
          "/help/how-to-create-kitchen-virtual-home-staging",
          "/es/help/como-crear-home-staging-virtual-cocina",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Maîtrisez le home staging virtuel de cuisine avec notre guide simple. Transformez des cuisines vides en espaces fonctionnels et accueillants."
          : newLang === "es"
          ? "Domina el home staging virtual de cocinas con nuestra guía fácil. Transforma cocinas vacías en espacios funcionales y acogedores."
          : "Master kitchen virtual home staging with our easy guide. Transform empty kitchens into functional, inviting spaces.";
      } else if (
        [
          "/es/blog/terrenos-urbanizables",
          "/fr/blog/terrains-constructibles",
          "/blog/buildable-land",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Nous expliquons ce que sont les terrains constructibles, les différents types de terrains constructibles et comment les préparer pour la vente."
          : newLang === "es"
          ? "Te explicamos qué son los terrenos urbanizables, los tipos de terrenos urbanizables que hay y cómo prepararlos para la venta."
          : "We explain what buildable land is, the different types of buildable land available, and how to prepare them for sale.";
      } else if (
        [
          "/fr/outils-gratuits/convertisseur-format-image",
          "/es/herramientas-gratis/convertidor-formato-imagen-gratuito",
          "/free-tools/free-image-format-converter",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Convertissez facilement vos images entre différents formats avec notre outil gratuit en ligne. Compatible PNG, JPG, WEBP, GIF et plus. Rapide, sécurisé et sans inscription."
          : newLang === "es"
          ? "Convierte imágenes entre formatos fácilmente con nuestra herramienta gratuita en línea. Compatible con PNG, JPG, WEBP, GIF y más. Rápido, seguro y sin registro."
          : "Convert images between formats easily with our free online tool. Support for PNG, JPG, WEBP, GIF, and more. Fast, secure, and no registration required.";
      } else if (
        [
          "/es/blog/fideicomiso-inmobiliario",
          "/fr/blog/fiducie-immobiliere",
          "/blog/real-estate-trust",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "La fiducie immobilière est un contrat juridique entre un constituant qui transfère la propriété de certains biens à un fiduciaire chargé de les gérer."
          : newLang === "es"
          ? "El fideicomiso inmobiliario es un contrato legal entre un fiduciante que transfiere la propiedad de ciertos bienes a un fiduciario que se encarga de administrarlo."
          : "A real estate trust is a legal contract between a trustor who transfers ownership of certain assets to a trustee who is responsible for managing them.";
      } else if (
        [
          "/fr/help/comment-creer-home-staging-virtuel-salon",
          "/help/how-to-create-living-room-virtual-home-staging",
          "/es/help/como-crear-home-staging-virtual-salon",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Maîtrisez le home staging virtuel de salon avec notre guide simple. Transformez des espaces de vie vides en salons accueillants et bien aménagés."
          : newLang === "es"
          ? "Domina el home staging virtual de salones con nuestra guía fácil. Transforma espacios de estar vacíos en salones acogedores y bien amueblados."
          : "Master living room virtual home staging with our easy guide. Transform empty living spaces into inviting, well-furnished rooms.";
      } else if (
        [
          "/fr/help/comment-creer-home-staging-virtuel-chambre",
          "/help/how-to-create-bedroom-virtual-home-staging",
          "/es/help/como-crear-home-staging-virtual-dormitorio",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Maîtrisez le home staging virtuel de chambre avec notre guide simple. Transformez des espaces de sommeil vides en chambres accueillantes et bien aménagées."
          : newLang === "es"
          ? "Domina el home staging virtual de dormitorios con nuestra guía fácil. Transforma espacios de dormir vacíos en dormitorios acogedores y bien amueblados."
          : "Master bedroom virtual home staging with our easy guide. Transform empty sleeping spaces into inviting, well-furnished bedrooms.";
      } else if (
        [
          "/es/blog/frases-inmobiliarias",
          "/fr/blog/phrases-immobilieres",
          "/blog/real-estate-phrases",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Phrases Immobilières pour attirer les clients, mettre en valeur et décrire les propriétés. Nous vous apprenons à rédiger vos phrases et donnons des exemples des meilleures phrases immobilières."
          : newLang === "es"
          ? "Frases Inmobiliarias para atraer clientes, destacar y describir inmuebles. Te enseñamos cómo redactar tus frases y ejemplos de las mejores frases inmobiliarias."
          : "Real Estate Phrases to attract clients, highlight and describe properties. We teach you how to write your phrases and provide examples of the best real estate phrases.";
      } else if (
        [
          "/fr/help/comment-creer-home-staging-virtuel-terrasse",
          "/help/how-to-create-terrace-virtual-home-staging",
          "/es/help/como-crear-home-staging-virtual-terraza",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Maîtrisez le home staging virtuel de terrasse avec notre guide simple. Transformez des espaces extérieurs vides en terrasses accueillantes et bien aménagées."
          : newLang === "es"
          ? "Domina el home staging virtual de terrazas con nuestra guía fácil. Transforma espacios exteriores vacíos en terrazas acogedoras y bien amuebladas."
          : "Master terrace virtual home staging with our easy guide. Transform empty outdoor spaces into inviting, well-furnished terraces.";
      } else if (
        [
          "/es/blog/bienes-raices",
          "/fr/blog/immobilier",
          "/blog/real-estate",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Découvrez tout sur l'immobilier. Des conseils pour les premiers acheteurs aux stratégies d'investissement et tendances du marché."
          : newLang === "es"
          ? "Descubre todo sobre los bienes raíces. Desde consejos para compradores primerizos hasta estrategias de inversión y tendencias del mercado."
          : "Discover everything about real estate. From tips for first-time buyers to investment strategies and market trends.";
      } else if (
        [
          "/fr/home-staging-salle-de-bain",
          "/es/home-staging-bano",
          "/home-staging-bathroom",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription =
          newLang === "es"
            ? "El home staging de baño aumenta el atractivo de tu propiedad. Ofrecemos servicios especializados para transformar tu baño. Home staging de baño efectivo y asequible."
            : newLang === "fr"
            ? "Le home staging de salle de bain augmente l'attrait de votre propriété. Nous proposons des services spécialisés pour transformer votre salle de bain. Home staging de salle de bain efficace et abordable."
            : "Bathroom home staging enhances your property's appeal. We offer specialized services to transform your bathroom. Effective and affordable bathroom home staging.";
      } else if (
        [
          "/fr/home-staging-terrasse",
          "/es/home-staging-terraza",
          "/home-staging-terrace",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription =
          newLang === "es"
            ? "El home staging de terraza aumenta el atractivo de tu propiedad. Ofrecemos servicios especializados para transformar tu terraza. Home staging de terraza efectivo y asequible."
            : newLang === "fr"
            ? "Le home staging de terrasse augmente l'attrait de votre propriété. Nous proposons des services spécialisés pour transformer votre terrasse. Home staging de terrasse efficace et abordable."
            : "Terrace home staging enhances your property's appeal. We offer specialized services to transform your terrace. Effective and affordable terrace home staging.";
      } else if (
        [
          "/fr/home-staging-chambre",
          "/es/home-staging-dormitorio",
          "/home-staging-bedroom",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription =
          newLang === "es"
            ? "El home staging de dormitorio aumenta el atractivo de tu propiedad. Ofrecemos servicios especializados para transformar tu dormitorio. Home staging de dormitorio efectivo y asequible."
            : newLang === "fr"
            ? "Le home staging de chambre augmente l'attrait de votre propriété. Nous proposons des services spécialisés pour transformer votre chambre. Home staging de chambre efficace et abordable."
            : "Bedroom home staging enhances your property's appeal. We offer specialized services to transform your bedroom. Effective and affordable bedroom home staging.";
      } else if (
        [
          "/es/blog/errores-al-vender-casa",
          "/fr/blog/erreurs-vente-maison",
          "/blog/mistakes-when-selling-house",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Erreurs courantes lors de la vente d'une maison et comment les éviter : conseils clés pour réussir une vente sans perdre d'argent ni de temps."
          : newLang === "es"
          ? "Errores comunes al vender una casa y cómo evitarlos: consejos clave para lograr una venta exitosa sin perder dinero ni tiempo."
          : "Common mistakes when selling a house and how to avoid them: key tips for achieving a successful sale without losing money or time.";
      } else if (
        [
          "/fr/home-staging-salon",
          "/es/home-staging-salon",
          "/home-staging-living-room",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription =
          newLang === "es"
            ? "El home staging de salón aumenta el atractivo de tu propiedad. Ofrecemos servicios especializados para transformar tu salón. Home staging de salón efectivo y asequible."
            : newLang === "fr"
            ? "Le home staging de salon augmente l'attrait de votre propriété. Nous proposons des services spécialisés pour transformer votre salon. Home staging de salon efficace et abordable."
            : "Living room home staging enhances your property's appeal. We offer specialized services to transform your living room. Effective and affordable living room home staging.";
      } else if (
        ["/es/blog/short-sale", "/fr/blog/short-sale", "/blog/short-sale"].some(
          (path) => currentUrl.includes(path)
        )
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Découvrez ce qu'est une vente à découvert, ses avantages et les exigences pour les vendeurs et les acheteurs. Exemples de ventes à découvert sur le marché immobilier."
          : newLang === "es"
          ? "Descubre qué es un short sale, sus beneficios y requisitos para vendedores y compradores. Ejemplos de short sale en el mercado inmobiliario."
          : "Discover what a short sale is, its benefits and requirements for sellers and buyers. Examples of short sales in the real estate market.";
      } else if (
        [
          "/fr/home-staging-cuisine",
          "/es/home-staging-cocina",
          "/home-staging-kitchen",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription =
          newLang === "es"
            ? "El home staging de cocina aumenta el atractivo de tu propiedad. Ofrecemos servicios especializados para transformar tu cocina. Home staging de cocina efectivo y asequible."
            : newLang === "fr"
            ? "Le home staging de cuisine augmente l'attrait de votre propriété. Nous proposons des services spécialisés pour transformer votre cuisine. Home staging de cuisine efficace et abordable."
            : "Kitchen home staging enhances your property's appeal. We offer specialized services to transform your kitchen. Effective and affordable kitchen home staging.";
      } else if (
        [
          "/es/blog/diseñador-ambientes",
          "/fr/blog/designer-environnement",
          "/blog/environmental-designer",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Découvrez le rôle du designer d'environnement, ses compétences clés, ses processus créatifs et les tendances actuelles dans la conception d'environnements uniques."
          : newLang === "es"
          ? "Descubre el papel del diseñador de ambientes, sus habilidades clave, procesos creativos y tendencias actuales en el diseño de ambientes únicos."
          : "Discover the role of the environmental designer, their key skills, creative processes, and current trends in unique environmental design.";
      } else if (
        [
          "/es/blog/modernizar-casa",
          "/fr/blog/moderniser-maison",
          "/blog/modernize-home",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Découvrez comment vous pouvez moderniser votre maison avec les meubles que vous avez, donnez une seconde vie à vos meubles anciens et modernisez votre intérieur avec ces idées."
          : newLang === "es"
          ? "Descubre cómo puedes modernizar tu casa con los muebles que tengas, dale una segunda vida a tus muebles antiguos y moderniza tu hogar con estas ideas."
          : "Discover how you can modernize your home with the furniture you have, give a second life to your antique furniture and modernize your home with these ideas.";
      } else if (
        [
          "/es/blog/propiedad-no-se-vende",
          "/fr/blog/propriete-qui-ne-se-vend-pas",
          "/blog/property-that-wont-sell",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Une propriété qui ne se vend pas est une situation complexe. Voici ce que vous pouvez faire pour vous aider à vendre une propriété qui ne trouve pas preneur."
          : newLang === "es"
          ? "Una propiedad que no se vende es una situación complicada. Aquí te explciamos qué puedes hacer para ayudarte a vender una propiedad que no se vende."
          : "A property that won't sell is a complicated situation. Here we explain what you can do to help sell a property that isn't selling.";
      } else if (currentUrl.includes("add-3d-objects-and-make-sky-blue")) {
        newDescription =
          "Add 3D objects and make sky blue to create realistic virtual home stagings easily in seconds.";
      } else if (
        ["/es/blog/flipping", "/fr/blog/flipping", "/blog/flipping"].some(
          (path) => currentUrl.includes(path)
        )
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Découvrez comment réussir dans le flipping immobilier : de l'étude de marché à la vente, optimisez chaque étape pour maximiser vos profits."
          : newLang === "es"
          ? "Descubre cómo hacer flipping inmobiliario exitoso: desde la investigación de mercado hasta la venta, optimiza cada paso para maximizar tus ganancias."
          : "Discover how to successfully flip real estate: from market research to selling, optimize every step to maximize your profits.";
      } else if (currentUrl.includes("help/how-to-remove-object")) {
        newDescription =
          "Learn how to eliminate unwanted objects and furniture from your real estate photos with Pedra. Step-by-step tutorial to enhance property presentation.";
      } else if (
        [
          "/es/api-documentation",
          "/fr/api-documentation",
          "/api-documentation",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Découvrez l'API de mise en scène virtuelle Pedra.so. Transformez les espaces avec l'amélioration des pièces et la décoration par IA. Idéal pour l'immobilier."
          : newLang === "es"
          ? "Explora la API de home staging virtual de Pedra.so. Transforma espacios con mejora de habitaciones, eliminación de muebles y diseño por IA. Ideal para inmobiliarias."
          : "Discover Pedra.so's virtual home staging API. Transform spaces with AI-powered room enhancement, furniture removal, and interior design. Ideal for real estate apps.";
      } else if (
        [
          "/es/blog/domotizar-casa",
          "/fr/blog/domotiser-maison",
          "/blog/home-automation",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Domotiser votre maison est un processus très simple qui offre un grand confort au quotidien. Nous vous montrons comment la domotiser et quels produits acheter."
          : newLang === "es"
          ? "Domotizar tu casa es un proceso muy sencillo que permite una gran comodidad diaria, te enseñamos cómo domotizarla y qué productos comprar."
          : "Automating your home is a very simple process that provides great daily convenience. We show you how to automate it and what products to buy.";
      } else if (
        [
          "/es/lanzamientos/renueva-y-amuebla-en-un-clic",
          "/fr/versions/renovez-et-meublez-en-un-clic",
          "/releases/renovate-and-furnish-in-one-click",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Transformez votre espace sans effort avec Pedra. Notre fonction 'Auto' améliorée offre de meilleurs résultats tout en préservant l'intégrité spatiale. Découvrez une rénovation et un ameublement parfaits en un seul clic."
          : newLang === "es"
          ? "Transforma tu espacio sin esfuerzo con Pedra. Nuestra función 'Auto' mejorada ofrece mejores resultados preservando la integridad espacial. Experimenta una renovación y amueblado perfectos con un solo clic."
          : "Transform your space effortlessly with Pedra. Our enhanced 'Auto' feature offers better results while preserving spatial integrity. Experience seamless renovation and furnishing with just a single click.";
      } else if (currentUrl.includes("es/help/como-borrar-objeto")) {
        newDescription =
          "Aprende a eliminar objetos y muebles no deseados de tus fotos inmobiliarias con Pedra. Tutorial paso a paso para mejorar la presentación de propiedades.";
      } else if (
        [
          "/es/blog/salas-hundidas",
          "/fr/blog/salons-en-contrebas",
          "/blog/sunken-living-rooms",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Découvrez le charme des salons en contrebas, des espaces architecturaux uniques qui allient design moderne et confort pour créer des ambiances chaleureuses et élégantes."
          : newLang === "es"
          ? "Descubre el encanto de las salas hundidas, espacios arquitectónicos únicos que combinan diseño moderno con comodidad, creando ambientes acogedores y elegantes."
          : "Discover the charm of sunken living rooms, unique architectural spaces that combine modern design with comfort, creating cozy and elegant environments.";
      } else if (
        [
          "/es/blog/mentor-inmobiliario",
          "/fr/blog/mentor-immobilier",
          "/blog/real-estate-mentor",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Les mentors immobiliers sont des experts du secteur et conseillent les personnes intéressées par le marché immobilier. Nous vous montrons comment choisir votre mentor."
          : newLang === "es"
          ? "Los mentores inmobiliarios son expertos en el sector y aconsejan a personas interesadas en el mercado inmobiliario, te enseñamos cómo elegir a tu mentor."
          : "Real estate mentors are experts in the field and provide advice to people interested in the real estate market. We guide you on how to choose your mentor.";
      } else if (
        [
          "/fr/help/comment-creer-home-staging-virtuel",
          "/help/how-to-create-virtual-home-staging",
          "/es/help/como-crear-home-staging-virtual",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Maîtrisez le home staging virtuel avec notre guide simple. Transformez des espaces vides en pièces meublées attrayantes."
          : newLang === "es"
          ? "Domina el home staging virtual con nuestra guía fácil. Transforma espacios vacíos en habitaciones amuebladas y atractivas."
          : "Master virtual home staging with our easy guide. Transform empty spaces into attractive, furnished rooms.";
      } else if (
        [
          "/es/herramientas-gratis/generar-descripcion-anuncio-inmobiliario-gratis",
          "/fr/outils-gratuits/generer-description-annonce-immobiliere-gratuit",
          "/free-tools/generate-real-estate-listing-description-free",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Créez des annonces immobilières professionnelles instantanément avec notre générateur gratuit. Démarquez-vous et attirez plus d'acheteurs !"
          : newLang === "es"
          ? "Crea anuncios inmobiliarios profesionales al instante con nuestro generador gratuito. ¡Destácate en el mercado y atrae más compradores!"
          : "Create professional real estate listings instantly with our free AI-powered description generator. Stand out in the market and attract more buyers!";
      } else if (
        [
          "/es/blog/como-personalizar-habitacion",
          "/fr/blog/comment-personnaliser-chambre",
          "/blog/how-to-personalize-room",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Nous vous montrons comment personnaliser votre chambre, les étapes à suivre et des conseils pour personnaliser votre espace."
          : newLang === "es"
          ? "Te enseñamos cómo personalizar tu habitación, los pasos a seguir y consejos para personalizar tu espacio. "
          : "We show you how to personalize your room, the steps to follow, and tips for customizing your space.";
      } else if (
        [
          "/es/blog/pricing-inmobiliario",
          "/fr/blog/pricing-immobilier",
          "/blog/real-estate-pricing",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Le pricing immobilier consiste à fixer le prix optimal de votre bien immobilier et à maximiser sa valeur. Nous vous enseignons différentes méthodes de pricing immobilier."
          : newLang === "es"
          ? "El pricing inmobiliario consiste en fijar el precio óptimo de tu inmueble y maximizar el valor, te enseñamos diferentes métodos de pricing inmobiliario."
          : "Real estate pricing involves setting the optimal price for your property and maximizing its value. We teach you different methods of real estate pricing.";
      } else if (
        [
          "/fr/blog/marketing-immobilier",
          "/blog/real-estate-marketing",
          "/es/blog/marketing-inmobiliario",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Le marketing immobilier est un ensemble de stratégies et de techniques conçues pour vendre des propriétés immobilières. Découvrez les types et comment les appliquer."
          : newLang === "es"
          ? "El marketing inmobiliario es un conjunto de estrategias y técnicas diseñadas para vender propiedades inmobiliarias, descubre los tipos y cómo aplicarlo."
          : "Real estate marketing is a set of strategies and techniques designed to sell real estate properties. Discover the types and how to apply them.";
      } else if (
        [
          "/fr/releases/ameliorations-ameublement-styles-personnalises-preservation-fenetres",
          "/releases/realistic-furnishing",
          "/es/releases/amueblado-realista",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Découvrez les dernières améliorations IA de Pedra : ameublement ultra-réaliste, styles de rénovation personnalisés et préservation améliorée des fenêtres. Optimisez vos visuels !"
          : newLang === "es"
          ? "Explora las últimas mejoras de IA de Pedra: amueblado super realista, estilos de renovación personalizados y preservación mejorada de ventanas. ¡Optimiza tus visualizaciones!"
          : "Discover Pedra's latest AI improvements: super-realistic furnishing, custom renovation styles, and enhanced window preservation. Upgrade your property visualizations now!";
      } else if (
        [
          "/fr/blog/tendances-marche-immobilier",
          "/blog/real-estate-market-trends",
          "/es/blog/tendencias-mercado-inmobiliario",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Nous explorons l'évolution du marché immobilier et les tendances actuelles du marché immobilier digital."
          : newLang === "es"
          ? "Exploramos cómo ha evolucionado el mercado inmobiliario y cuales son las tendencias actuales del mercado digital inmobiliario."
          : "We explore how the real estate market has evolved and what the current trends in the digital real estate market are.";
      } else if (
        [
          "/fr/help/comment-vider-pieces-virtuellement",
          "/help/how-to-empty-rooms-virtually",
          "/es/help/como-vaciar-habitacion-virtualmente",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Découvrez comment vider virtuellement des pièces avec notre guide. Retirez meubles et objets d'une pièce facilement et de façon réaliste"
          : newLang === "es"
          ? "Aprende a vaciar habitaciones virtualmente de forma automática y fácil con nuestra guía. Vacía muebles y objetos de una habitación de manera sencilla."
          : "Learn how to empty rooms virtually in an automatic and easy way with our step-by-step guide. Empty furniture and objects from a room easily.";
      } else if (
        [
          "/fr/blog/augmentez-valeur-maison",
          "/blog/increase-home-value",
          "/es/blog/aumenta-valor-vivienda",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Nous vous montrons comment augmenter la valeur de votre maison rapidement et facilement avec plusieurs conseils et astuces pour améliorer votre maison avant de la vendre &#127969;"
          : newLang === "es"
          ? "Te enseñamos cómo aumentar el valor de la vivienda de forma rápida y sencilla con varios consejos y tips para mejorar tu hogar antes de venderlo &#127969;"
          : "We show you how to increase the value of your home quickly and easily with several tips and advice to improve your home before selling it &#127969;";
      } else if (
        [
          "/fr/blog/espaces-multifonctionnels",
          "/blog/multifunctional-spaces",
          "/es/blog/espacios-multifuncionales",
        ].some((path) => currentUrl.includes(path))
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Les espaces multifonctionnels ont pour objectif de maximiser l'utilisation de l'espace disponible, en utilisant une même zone pour différentes fonctions."
          : newLang === "es"
          ? "Los espacios multifuncionales tienen el objetivo de aprovechar al máximo el espacio posible, usando una misma zona para diferentes funciones."
          : "Multifunctional spaces aim to maximize the use of available space by using the same area for different functions.";
      } else if (currentUrl.includes("anade-objetos-3d-y-cielo-azul")) {
        newDescription =
          "Añade objetos 3D y pon el cielo azul para crear home staging virtuales realista en segundos de manera fácil.";
      } else if (currentUrl.includes("help/how-to-renovate-houses-virtually")) {
        newDescription =
          "How to virtually renovate a room. Create a virtual renovation of a house in seconds.";
      } else if (currentUrl.includes("help/como-renovar-casas-virtualmente")) {
        newDescription =
          "Cómo renovar virtualmente una habitación. Crea renovaciones virtuales de una casa en segundos.";
      } else if (currentUrl.includes("blog/sell-more-properties-with-ai")) {
        newDescription =
          "An explanation about what is AI and how it can help on the sale of real estate properties";
      } else if (
        currentUrl.includes("/fr/help/comment-ajouter-des-objets-3d")
      ) {
        newDescription =
          "Apprenez à utiliser l'outil 'Add Object' pour créer des mises en scène virtuelles avec Pedra. Ajoutez, positionnez et redimensionnez des objets 3D facilement.";
      } else if (
        currentUrl.includes("/fr/help/comment-renover-des-maisons-en-virtuel")
      ) {
        newDescription =
          "Comment rénover virtuellement une pièce. Créez des rénovations virtuelles d'une maison en quelques secondes";
      } else if (currentUrl.includes("/fr/help/comment-meubler-en-virtuel")) {
        newDescription =
          "Apprenez à meubler virtuellement des pièces et des propriétés. Ce tutoriel vous aidera à meubler comme un pro.";
      } else if (currentUrl.includes("/fr/help/comment-supprimer-un-objet")) {
        newDescription =
          "Apprenez à éliminer les objets et meubles indésirables de vos photos immobilières avec Pedra. Tutoriel étape par étape pour améliorer la présentation de vos propriétés.";
      } else if (
        ["/fr/interiordesign", "/es/interiordesign", "/interiordesign"].some(
          (path) => currentUrl.includes(path)
        )
      ) {
        newDescription =
          newLang === "es"
            ? "Con nuestra herramienta de diseño de interiores con IA tendrás la capacidad de crear espacios personalizados, optimizados y estéticamente únicos en minutos."
            : newLang === "fr"
            ? "Notre outil de design d'intérieur alimenté par l'IA vous permet de créer des espaces personnalisés, optimisés et esthétiquement uniques en quelques minutes."
            : "With our AI-powered interior design tool, you'll have the ability to create customized, optimized and aesthetically unique spaces in minutes.";
      } else if (currentUrl.includes("real-estate-photography")) {
        newDescription =
          newLang === "es"
            ? "Mejora tus fotografías inmobiliarias con IA: arregla perspectivas, mejora color, luz y definición en segundos de manera automática. Quita objetos innecesarios."
            : newLang === "fr"
            ? "Utilisez l'IA pour perfectionner facilement vos photos immobilières. Que vous souhaitiez ajuster la lumière, corriger les perspectives ou enlever les objets inutiles, Pedra offre une solution rapide et efficace pour mettre en valeur vos propriétés"
            : "Improve your real estate photography with AI: fix perspectives, improve color, light and resolution in seconds automatically. Remove unwanted objects.";
      } else if (currentUrl.includes("realestate")) {
        newDescription =
          newLang === "es"
            ? "Usa Pedra para inmobiliarias para crear renders, home staging virtuales, planos de planta, mejora de imagen y más en segundos. Pedra te ayuda a vender inmuembles."
            : newLang === "fr"
            ? "Découvrez comment Pedra peut transformer vos photos immobilières. Avec des outils puissants pour la rénovation, le home staging, et la suppression d'objets, améliorez vos annonces facilement. Essayez Pedra dès maintenant pour attirer plus d'acheteurs et vendre plus rapidement."
            : "Use Pedra for real estate to create renders, virtual home stagings, floor plans, image enhancement, and more in seconds. Pedra helps you sell properties.";
      } else if (currentUrl.includes("como-vender-casa")) {
        newDescription =
          newLang === "es"
            ? "Consejos y recomendaciones de cómo vender tu casa rápido, desde qué debes tener en cuenta antes de vender tu casa hasta cómo prepararla para venderla rápido."
            : "Use Pedra for real estate to create renders, virtual home stagings, floor plans, image enhancement, and more in seconds. Pedra helps you sell properties.";
      } else if (currentUrl.includes("renovar-casa")) {
        newDescription =
          newLang === "es"
            ? "Renueva tu casa rápido, sin obras y con 6 ideas de cómo remodelar tu casa y qué necesitas para ello. Además de las herramientas que puedes usar para renovarla."
            : "Use Pedra for real estate to create renders, virtual home stagings, floor plans, image enhancement, and more in seconds. Pedra helps you sell properties.";
      } else if (currentUrl.includes("pricing")) {
        newDescription =
          newLang === "es"
            ? "Usa Pedra para generar renders inmobiliarios gratis. Usa el Plan Pro por 29€ al mes. Cancela en cualquier momento."
            : newLang === "fr"
            ? "Découvrez notre forfait Pro à 29€/mois avec 100 générations d'images, rénovation, home staging et plus. Annulez à tout moment. Essayez Pedra maintenant!"
            : "Try Pedra to generate renders. Use the Pro Plan for €29 per month. Cancel anytime.";
      } else if (currentUrl.includes("render")) {
        newDescription =
          newLang === "es"
            ? "Crea renders con IA y prueba el software gratis para generar de forma rápida renders 3d con ayuda de la inteligencia artificial."
            : newLang === "fr"
            ? "Découvrez comment utiliser l'IA pour créer des rendus immobiliers époustouflants. Téléchargez vos images et choisissez parmi divers styles de rénovation."
            : "Create AI renders and try the software for free to quickly generate 3D renders with the help of artificial intelligence.";
      } else if (
        currentUrl.includes("como-utilizar-ia-para-vender-propiedades") ||
        currentUrl.includes("how-to-sell-properties-with-ai")
      ) {
        newDescription =
          newLang === "es"
            ? "La IA en inmobiliarias usa la tecnologías para mejorar y optimizar diversos aspectos automatizando procesos del sector inmobiliario. ¡Te mostramos cómo usarlo!"
            : "AI in real estate uses technology to enhance and optimize various aspects by automating processes within the real estate sector.";
      } else if (
        currentUrl.includes("vende-mas-propiedades-inmobiliarias-con-ia")
      ) {
        newDescription =
          newLang === "es"
            ? "Una explicación sobre qué es la IA y cómo puede ayudar a acelerar a la venta de inmuebles por menos coste."
            : "An explanation of what AI is and how it can accelerate real estate sales.";
      } else if (currentUrl.includes("home-staging-virtual")) {
        newDescription =
          newLang === "es"
            ? "El home staging acelera tus ventas inmobiliarias. Te proporcionamos una herramienta para realizar un home staging virtual. Home staging fácil y rápido."
            : newLang === "fr"
            ? "Le home staging accélère vos ventes immobilières. Nous vous fournissons un outil pour réaliser un home staging virtuel. Home staging facile et rapide."
            : "Home staging speeds up your real estate sales. We provide you with a tool to create a virtual home staging. Easy and fast home staging.";
      } else if (currentUrl.includes("es/mls")) {
        newDescription =
          "Proveedor oficial de IA de la MLS: propuestas de renovación con IA, home staging virtual, vaciado de habitaciones y más.";
      } else if (currentUrl.includes("privacy")) {
        newDescription =
          newLang === "es"
            ? "Politica de Privacidad de Pedra, aplicación con IA para crear propuestas de renovación, home staging virtual, y otras infografías."
            : newLang === "fr"
            ? "Découvrez la politique de confidentialité de Pedra.so. Informez-vous sur la collecte, l'utilisation, et la protection de vos données personnelles lors de l'utilisation de notre application."
            : "Pedra's privacy policy, an AI application to create renovation proposals, virtual home stagings, and other render types";
      } else if (currentUrl.includes("legal")) {
        newDescription =
          newLang === "es"
            ? "Aviso legal de Pedra, aplicación con IA para crear propuestas de renovación, home staging virtual, y otras infografías."
            : newLang === "fr"
            ? "Consultez les mentions légales de Pedra.so. Découvrez les informations sur le propriétaire, les conditions d'utilisation, et la protection des données personnelles."
            : "Pedra's legal notice, an AI application to create renovation proposals, virtual home stagings, and other render types";
      } else if (currentUrl.includes("about")) {
        newDescription =
          newLang === "es"
            ? "Soy Felix Ingla, I empecé Pedra, una herramienta online para transformar tus propiedades de inmobiliarias de manera fácil con inteligencia artificial."
            : newLang === "fr"
            ? "Découvrez l'histoire de Pedra et de son fondateur, Felix Ingla. Apprenez comment Pedra est né pour répondre aux besoins du marché immobilier en offrant une solution simple et efficace pour améliorer la présentation des biens. Explorez notre engagement envers la satisfaction client et comment notre outil peut vous aider à optimiser vos photos immobilières et plus encore."
            : "I'm Felix Ingla, and I started Pedra, an online tool to transform your real estate properties easily with artificial intelligence.";
      } else if (
        currentUrl.includes("/blog/how-to-make-sky-blue-real-estate-photo")
      ) {
        newDescription =
          "How to make the sky in your real estate photos blue easily";
      } else if (
        currentUrl.includes(
          "/es/blog/como-poner-cielo-azul-fotografias-de-inmuebles"
        )
      ) {
        newDescription =
          "Como poner el cielo azul y despejado en las fotografías de tus inmuebles";
      } else if (
        currentUrl.includes(
          "/fr/blog/vendre-plus-de-proprietes-immobilieres-avec-ia"
        )
      ) {
        newDescription =
          "Découvrez comment l'IA révolutionne l'immobilier avec des rendus réalistes, home staging virtuel et plus encore. Apprenez à tirer parti de ces innovations pour optimiser vos ventes.";
      } else if (
        currentUrl.includes(
          "/fr/blog/comment-utiliser-lia-pour-vendre-des-proprietes"
        )
      ) {
        newDescription =
          "Découvrez comment l'IA aide les agents immobiliers à vendre plus efficacement, de la sélection d'images à la création de rendus de rénovation. Lisez notre article pour en savoir plus!";
      } else if (currentUrl.includes("/fr/blog/exemples-de-home-staging")) {
        newDescription =
          "Découvrez des exemples de home staging avant et après pour des cuisines, salles de bain, chambres, salons et terrasses. Apprenez comment transformer vos espaces avec l'IA et le home staging virtuel.";
      } else if (
        currentUrl.includes(
          "/fr/blog/comment-acceleration-des-ventes-avec-la-photo-de-couverture-de-lannonce-immobiliere"
        )
      ) {
        newDescription =
          "Découvrez comment choisir et optimiser la photo de couverture de votre annonce immobilière pour attirer l'attention des acheteurs et accélérer la vente. Utilisez des outils comme Pedra pour améliorer vos photos.";
      } else if (
        currentUrl.includes(
          "/fr/blog/comment-ajouter-un-ciel-bleu-aux-photographies-immobilieres"
        )
      ) {
        newDescription =
          "Découvrez comment améliorer vos photos immobilières en rendant le ciel bleu grâce à Photoshop, à des services professionnels, ou à des applications spécialisées comme Pedra";
      } else if (currentUrl.includes("/es/blog/home-staging-ejemplos")) {
        newDescription =
          "Ejemplos de home staging. Fotografías de home staging antes y después.";
      } else if (currentUrl.includes("/blog/home-staging-examples")) {
        newDescription =
          "Home staging examples. Photographies of home staging: before and after.";
      } else if (
        currentUrl.includes(
          "/blog/how-to-speed-up-sales-with-real-estate-listing-cover-photo"
        )
      ) {
        newDescription =
          "Choose and generate cover photos in your real estate listings that catch attention and help you sell";
      } else if (currentUrl.includes("/help/how-to-furnish-virtually")) {
        newDescription =
          "Learn to virtually furnish rooms and properties. This tutorial will will help you virtually furnish like a pro.";
      } else if (currentUrl.includes("/es/help/como-amueblar-virtualmente")) {
        newDescription =
          "Descubre cómo usar la herramienta de amueblar para amueblar virtualmente tus propiedades. Tutorial paso a paso para un amueblado virtual profesional.";
      } else if (
        currentUrl.includes("empty-rooms-and-remove-objects-with-ai")
      ) {
        newDescription =
          "Empty rooms and remove objects with AI. Empty rooms in seconds with 1 click";
      } else if (
        currentUrl.includes("vacia-habitacion-y-quita-objetos-con-ia")
      ) {
        newDescription =
          "Vacia habitación y quita objetos con IA. Vacia espacios en segundos en 1 click.";
      } else if (currentUrl.includes("renovate-interiors-with-ai-from-phone")) {
        newDescription =
          "Renovate interiors and rooms with AI directly from your phone in seconds and easily";
      } else if (
        currentUrl.includes("renueva-espacios-desde-el-movil-con-ia")
      ) {
        newDescription =
          "Renueva habitaciones con IA desde el móvil en segundos y facilmente";
      } else if (
        currentUrl.includes(
          "/blog/como-asegurar-que-la-foto-de-portada-de-tu-anuncio-inmobiliario-acelere-la-venta"
        )
      ) {
        newDescription =
          "Escoge y genera fotos de portada en tus anuncios inmobiliarios que capten la atención y te ayuden a vender";
      } else if (currentUrl.includes("reviews-and-testimonials")) {
        newDescription =
          newLang === "es"
            ? "Opiniones, testimonios y valoraciones sobre Pedra, herramienta de home staging virtual y mejora de fotografías"
            : newLang === "fr"
            ? "Lisez les témoignages de nos clients satisfaits sur Pedra. Découvrez comment notre outil de home staging virtuel et d'amélioration de photos a transformé leur travail."
            : "Opinions, testimonials and reviews about Pedra, virtual home staging and photography enhancement tool";
      } else if (currentUrl.includes("reviews-and-testimonials")) {
        newDescription =
          newLang === "es"
            ? "Lanzamientos y novedades de Pedra – aplicación de IA para agentes e inmobilarias para generar home staging virtuales"
            : "Releases from Pedra – an AI application for real estate agents and companies to generate virtual home staging proposals";
      } else if (currentUrl.includes("blog")) {
        newDescription =
          newLang === "es"
            ? "Blog sobre Home Staging Virtual y Fotografía Real Estate, creado y mantenido por Pedra"
            : newLang === "fr"
            ? "Découvrez les articles récents de Pedra sur l'utilisation de l'IA dans l'immobilier. Apprenez à améliorer vos photos, accélérer les ventes, et plus encore."
            : "Blog about Virtual Home Staging and Real Estate Photography, created and maintained by Pedra";
      } else if (currentUrl.includes("help/how-to-add-3d-objects")) {
        newDescription =
          "How to add 3D objects and furniture to a room and create a virtual home staging from an image.";
      } else if (currentUrl.includes("/es/help/como-anadir-objetos-3d")) {
        newDescription =
          "Cómo añadir objetos y muebles 3D a una imagen y crear un home staging virtual hiperrealista.";
      } else if (currentUrl.includes("releases")) {
        newDescription =
          newLang === "es"
            ? "Novedades en herramienta de home staging virtual y fotografía inmobiliaria"
            : newLang === "fr"
            ? "Découvrez les mises à jour récentes de Pedra pour mai, juin et juillet 2024 : nouvelles fonctionnalités, améliorations d'interface, et plus encore pour chaque mois."
            : "Releases on virtual home staging tool and real estate photography improvement";
      } else if (
        ["/es/help", "/fr/help", "/help"].some((path) =>
          currentUrl.includes(path)
        )
      ) {
        newDescription = location.pathname.includes("/fr/")
          ? "Apprenez à mettre en valeur vos annonces immobilières virtuellement avec nos guides étape par étape, conseils et meilleures pratiques. Transformez les espaces vides en intérieurs meublés magnifiques."
          : newLang === "es"
          ? "Aprenda a realizar home staging virtual para sus anuncios inmobiliarios con nuestras guías paso a paso, consejos y mejores prácticas. Transforme espacios vacíos en hogares amueblados impresionantes."
          : "Learn how to virtually stage your real estate listings with our step-by-step guides, tips, and best practices. Transform empty spaces into stunning, furnished homes instantly.";
      } else {
        newDescription =
          newLang === "es"
            ? "Crea en un clic renders inmobiliarios, home staging virtuals, planos de planta y más con Pedra."
            : newLang === "fr"
            ? "Créez en un clic des rendus immobiliers, du home staging virtuel, des plans de sol et plus encore avec Pedra."
            : "Create fast real estate renders, virtual home stagings, floor plans and more in one click with Pedra.";
      }

      metaDescription.content = newDescription;
    }

    // Update the title based on the language
    const pageTitle = document.querySelector("title");
    if (pageTitle) {
      const currentUrl = window.location.href;
      let newTitle = "";

      if (currentUrl.includes("floorplan")) {
        newTitle =
          newLang === "es"
            ? "Dibuja planos de planta | Crea planos online"
            : newLang === "fr"
            ? "Outil de Création de Plans d'Étage Facile"
            : "Floor Plan Creator| Easy Floor Plan Tool";
      } else if (
        [
          "/es/blog/rentabilidad-home-staging-virtual",
          "/fr/blog/rentabilite-home-staging-virtuel",
          "/blog/roi-virtual-staging",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "ROI du Home Staging Virtuel : Chiffres Réels des Meilleures Agences Immobilières"
          : newLang === "es"
          ? "ROI del Home Staging Virtual: Números Reales de las Principales Agencias Inmobiliarias"
          : "ROI of Virtual Staging: Real Numbers from Top Real Estate Agencies";
      } else if (
        [
          "/help/render-floor-plan-with-ai",
          "/fr/help/rendu-plan-etage-avec-ia",
          "/es/help/renderizar-plano-de-planta-con-ia",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Comment faire le rendu d'un plan avec l'IA | Guide étape par étape"
          : newLang === "es"
          ? "Cómo renderizar un plano con IA | Tutorial paso a paso"
          : "How to Render a Floor Plan with AI | Step-by-Step Guide";
      } else if (
        [
          "render-desde-plano",
          "/render-depuis-plan",
          "/render-from-floor-plan",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Générer des Images depuis Plans d'Étage | Visualisation Automatique"
          : newLang === "es"
          ? "Generar Imágenes desde Planos | Renderizado Automático"
          : "Generate Images from Floor Plans | Automatic Room Visualization";
      } else if (
        [
          "/es/blog/home-staging-tradicional-vs-home-staging-virtual",
          "/fr/blog/home-staging-traditionnel-vs-home-staging-virtuel",
          "/blog/traditional-home-staging-vs-virtual-home-staging",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Home Staging Virtuel vs Traditionnel : Quelle est la meilleure option ?"
          : newLang === "es"
          ? "Home Staging Virtual vs Tradicional: ¿Cuál es la mejor opción?"
          : "Virtual vs Traditional Home Staging: Which is the Better Option?";
      } else if (
        [
          "/es/releases/espacios-de-trabajo",
          "/fr/releases/espaces-de-travail",
          "/releases/workspaces",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Nouveauté : Espaces de travail pour la collaboration en home staging virtuel"
          : newLang === "es"
          ? "Novedad: Espacios de trabajo para colaborar en home staging virtual"
          : "New: Workspaces for virtual staging collaboration";
      } else if (
        [
          "/fr/help/comment-creer-rendu-exterieur-maison",
          "/help/how-to-create-house-exterior-render",
          "/es/help/como-crear-render-exterior-casa",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Comment créer un rendu extérieur de maison : Guide pratique"
          : newLang === "es"
          ? "Cómo crear un render exterior de casa: Guía paso a paso"
          : "How to Create a House Exterior Render: A Step-by-Step Guide";
      } else if (
        [
          "/fr/help/comment-dessiner-plan",
          "/help/how-to-draw-floor-plan",
          "/es/help/como-dibujar-plano",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Comment dessiner un plan d'étage : Guide pratique"
          : newLang === "es"
          ? "Cómo dibujar un plano: Guía paso a paso"
          : "How to Draw a Floor Plan: A Step-by-Step Guide";
      } else if (
        [
          "/fr/help/comment-creer-rendu-terrasse",
          "/help/how-to-create-terrace-render",
          "/es/help/como-crear-render-terraza",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Comment créer un rendu de terrasse : Guide pratique"
          : newLang === "es"
          ? "Cómo crear un render de terraza: Guía paso a paso"
          : "How to Create a Terrace Render: A Step-by-Step Guide";
      } else if (
        [
          "/fr/help/comment-creer-rendu-chambre",
          "/help/how-to-create-bedroom-render",
          "/es/help/como-crear-render-dormitorio",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Comment créer un rendu de chambre : Guide pratique"
          : newLang === "es"
          ? "Cómo crear un render de dormitorio: Guía paso a paso"
          : "How to Create a Bedroom Render: A Step-by-Step Guide";
      } else if (
        [
          "/fr/help/comment-creer-rendu-salon",
          "/help/how-to-create-living-room-render",
          "/es/help/como-crear-render-sala-estar",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Comment créer un rendu de salon : Guide pratique"
          : newLang === "es"
          ? "Cómo crear un render de sala de estar: Guía paso a paso"
          : "How to Create a Living Room Render: A Step-by-Step Guide";
      } else if (
        [
          "/fr/help/comment-creer-rendu-salle-de-bain",
          "/help/how-to-create-bathroom-render",
          "/es/help/como-crear-render-bano",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Comment créer un rendu de salle de bain : Guide pratique"
          : newLang === "es"
          ? "Cómo crear un render de baño: Guía paso a paso"
          : "How to Create a Bathroom Render: A Step-by-Step Guide";
      } else if (
        [
          "/fr/help/comment-creer-rendu-cuisine",
          "/help/how-to-create-kitchen-render",
          "/es/help/como-crear-render-cocina",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Comment créer un rendu de cuisine : Guide pratique"
          : newLang === "es"
          ? "Cómo crear un render de cocina: Guía paso a paso"
          : "How to Create a Kitchen Render: A Step-by-Step Guide";
      } else if (
        ["/fr/rendu-maison", "/es/render-casa", "/render-house"].some((path) =>
          currentUrl.includes(path)
        )
      ) {
        newTitle =
          newLang === "es"
            ? "Render de Casa | Crear renders de casa automáticamente"
            : newLang === "fr"
            ? "Rendu de Maison | Créer des rendus de maison automatiquement"
            : "House Render | Create house renders automatically";
      } else if (
        ["/fr/rendu-terrasse", "/es/render-terraza", "/render-terrace"].some(
          (path) => currentUrl.includes(path)
        )
      ) {
        newTitle =
          newLang === "es"
            ? "Render de Terraza | Crear renders de terraza automáticamente"
            : newLang === "fr"
            ? "Rendu de Terrasse | Créer des rendus de terrasse automatiquement"
            : "Terrace Render | Create terrace renders automatically";
      } else if (
        ["/fr/rendu-salon", "/es/render-salon", "/render-living-room"].some(
          (path) => currentUrl.includes(path)
        )
      ) {
        newTitle =
          newLang === "es"
            ? "Render de Salón | Crear renders de salón automáticamente"
            : newLang === "fr"
            ? "Rendu de Salon | Créer des rendus de salon automatiquement"
            : "Living Room Render | Create living room renders automatically";
      } else if (
        ["/fr/rendu-salle-de-bain", "/es/render-bano", "/render-bathroom"].some(
          (path) => currentUrl.includes(path)
        )
      ) {
        newTitle =
          newLang === "es"
            ? "Render de Baño | Crear renders de baño automáticamente"
            : newLang === "fr"
            ? "Rendu de Salle de Bain | Créer des rendus de salle de bain automatiquement"
            : "Bathroom Render | Create bathroom renders automatically";
      } else if (
        ["/fr/rendu-cuisine", "/es/render-cocina", "/render-kitchen"].some(
          (path) => currentUrl.includes(path)
        )
      ) {
        newTitle =
          newLang === "es"
            ? "Render de Cocina | Crear renders de cocina automáticamente"
            : newLang === "fr"
            ? "Rendu de Cuisine | Créer des rendus de cuisine automatiquement"
            : "Kitchen Render | Create kitchen renders automatically";
      } else if (
        ["/fr/rendu-chambre", "/es/render-dormitorio", "/render-bedroom"].some(
          (path) => currentUrl.includes(path)
        )
      ) {
        newTitle =
          newLang === "es"
            ? "Render de Dormitorio | Crear renders de dormitorio automáticamente"
            : newLang === "fr"
            ? "Rendu de Chambre | Créer des rendus de chambre automatiquement"
            : "Bedroom Render | Create bedroom renders automatically";
      } else if (
        [
          "/es/blog/como-vender-casa-a-reformar",
          "/fr/blog/comment-vendre-une-maison-a-renover",
          "/blog/how-to-sell-a-house-to-renovate",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Comment Vendre une Maison à Rénover : Guide Pratique"
          : newLang === "es"
          ? "Cómo Vender una Casa a Reformar: Guía Completa"
          : "How to Sell a House to Renovate: Complete Guide";
      } else if (
        [
          "/es/renders-para-reformas",
          "/renders-for-renovations",
          "/fr/rendus-de-renovation",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Rendus pour rénovations - Service rapide et automatique"
          : newLang === "es"
          ? "Renders para reformas - Servicio rápido y automático"
          : "Renders for renovations - Fast and automatic service";
      } else if (
        [
          "/es/releases/mejoras-pedra-piscinas-y-preservacion",
          "/fr/releases/ameliorations-pedra-piscines-et-preservation",
          "/releases/pedra-improvements-pools-and-preservation",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Améliorations Pedra : Piscines et préservation"
          : newLang === "es"
          ? "Mejoras Pedra: Piscinas y preservación de partes al renovar"
          : "Améliorations de Pedra : Piscines et conservation des éléments dans les rénovations";
      } else if (
        [
          "/fr/help/comment-creer-home-staging-virtuel-salle-de-bain",
          "/help/how-to-create-bathroom-virtual-home-staging",
          "/es/help/como-crear-home-staging-virtual-bano",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Comment créer un home staging virtuel de salle de bain : Guide pratique"
          : newLang === "es"
          ? "Cómo crear un home staging virtual de baño: Guía paso a paso"
          : "How to Create a Bathroom Virtual Home Staging: A Step-by-Step Guide";
      } else if (
        [
          "/fr/help/comment-creer-home-staging-virtuel-cuisine",
          "/help/how-to-create-kitchen-virtual-home-staging",
          "/es/help/como-crear-home-staging-virtual-cocina",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Comment créer un home staging virtuel de cuisine : Guide pratique"
          : newLang === "es"
          ? "Cómo crear un home staging virtual de cocina: Guía paso a paso"
          : "How to Create a Kitchen Virtual Home Staging: A Step-by-Step Guide";
      } else if (
        [
          "/es/blog/terrenos-urbanizables",
          "/fr/blog/terrains-constructibles",
          "/blog/buildable-land",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Terrains constructibles : Définition et types de terrains"
          : newLang === "es"
          ? "Terrenos urbanizables: Qué son y tipos de terrenos"
          : "Buildable Land: What It Is and Types of Land";
      } else if (
        [
          "/fr/outils-gratuits/convertisseur-format-image",
          "/es/herramientas-gratis/convertidor-formato-imagen-gratuito",
          "/free-tools/free-image-format-converter",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Convertisseur de Format d'Image Gratuit : Convertissez Entre Tous les Formats"
          : newLang === "es"
          ? "Convertidor Gratuito de Formatos de Imagen: Convierte Entre Cualquier Formato"
          : "Free Image Format Converter: Convert Between Any Image Format";
      } else if (
        [
          "/es/blog/fideicomiso-inmobiliario",
          "/fr/blog/fiducie-immobiliere",
          "/blog/real-estate-trust",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Qu'est-ce qu'une fiducie immobilière et quels sont les différents types?"
          : newLang === "es"
          ? "¿Qué es un fideicomiso inmobiliario y qué tipos de hay?"
          : "What is a real estate trust and what types are there?";
      } else if (
        [
          "/fr/help/comment-creer-home-staging-virtuel-salon",
          "/help/how-to-create-living-room-virtual-home-staging",
          "/es/help/como-crear-home-staging-virtual-salon",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Comment créer un home staging virtuel de salon : Guide pratique"
          : newLang === "es"
          ? "Cómo crear un home staging virtual de salón: Guía paso a paso"
          : "How to Create a Living Room Virtual Home Staging: A Step-by-Step Guide";
      } else if (
        [
          "/fr/help/comment-creer-home-staging-virtuel-chambre",
          "/help/how-to-create-bedroom-virtual-home-staging",
          "/es/help/como-crear-home-staging-virtual-dormitorio",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Comment créer un home staging virtuel de chambre : Guide pratique"
          : newLang === "es"
          ? "Cómo crear un home staging virtual de dormitorio: Guía paso a paso"
          : "How to Create a Bedroom Virtual Home Staging: A Step-by-Step Guide";
      } else if (
        [
          "/es/blog/frases-inmobiliarias",
          "/fr/blog/phrases-immobilieres",
          "/blog/real-estate-phrases",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Phrases Immobilières, Exemples et Conseils pour Attirer les Acheteurs"
          : newLang === "es"
          ? "Frases Inmobiliarias, ejemplos y consejos para captar compradores"
          : "Real Estate Phrases, Examples and Tips to Attract Buyers";
      } else if (
        [
          "/fr/help/comment-creer-home-staging-virtuel-terrasse",
          "/help/how-to-create-terrace-virtual-home-staging",
          "/es/help/como-crear-home-staging-virtual-terraza",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Comment créer un home staging virtuel de terrasse : Guide pratique"
          : newLang === "es"
          ? "Cómo crear un home staging virtual de terraza: Guía paso a paso"
          : "How to Create a Terrace Virtual Home Staging: A Step-by-Step Guide";
      } else if (
        [
          "/es/blog/bienes-raices",
          "/fr/blog/immobilier",
          "/blog/real-estate",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Immobilier : Conseils pour Acheter, Vendre et Investir"
          : newLang === "es"
          ? "Bienes Raíces: Consejos para Comprar, Vender e Invertir"
          : "Real Estate: Tips for Buying, Selling and Investing";
      } else if (
        [
          "/fr/home-staging-salle-de-bain",
          "/es/home-staging-bano",
          "/home-staging-bathroom",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle =
          newLang === "es"
            ? "Home Staging de Baño | Transformar tu baño con home staging"
            : newLang === "fr"
            ? "Home Staging Salle de Bain | Transformer votre salle de bain avec le home staging"
            : "Bathroom Home Staging | Transform your bathroom with home staging";
      } else if (
        [
          "/fr/home-staging-chambre",
          "/es/home-staging-dormitorio",
          "/home-staging-bedroom",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle =
          newLang === "es"
            ? "Home Staging de Dormitorio | Transformar tu dormitorio con home staging"
            : newLang === "fr"
            ? "Home Staging Chambre | Transformer votre chambre avec le home staging"
            : "Bedroom Home Staging | Transform your bedroom with home staging";
      } else if (
        [
          "/fr/home-staging-terrasse",
          "/es/home-staging-terraza",
          "/home-staging-terrace",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle =
          newLang === "es"
            ? "Home Staging de Terraza | Transformar tu terraza con home staging"
            : newLang === "fr"
            ? "Home Staging Terrasse | Transformer votre terrasse avec le home staging"
            : "Terrace Home Staging | Transform your terrace with home staging";
      } else if (
        [
          "/es/blog/errores-al-vender-casa",
          "/fr/blog/erreurs-vente-maison",
          "/blog/mistakes-when-selling-house",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Erreurs Courantes lors de la Vente d'une Maison et Comment les Éviter"
          : newLang === "es"
          ? "Errores Comunes al Vender una Casa y Cómo Evitarlos"
          : "Common Mistakes When Selling a House and How to Avoid Them";
      } else if (
        [
          "/fr/home-staging-salon",
          "/es/home-staging-salon",
          "/home-staging-living-room",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle =
          newLang === "es"
            ? "Home Staging de Salón | Transformar tu salón con home staging"
            : newLang === "fr"
            ? "Home Staging Salon | Transformer votre salon avec le home staging"
            : "Living Room Home Staging | Transform your living room with home staging";
      } else if (
        ["/es/blog/short-sale", "/fr/blog/short-sale", "/blog/short-sale"].some(
          (path) => currentUrl.includes(path)
        )
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Guide sur la vente à découvert : Définition et objectifs"
          : newLang === "es"
          ? "Guía sobre sobre Short Sale: Qué es y objetivos"
          : "Guide to Short Sale: What It Is and Its Objectives";
      } else if (
        [
          "/fr/home-staging-cuisine",
          "/es/home-staging-cocina",
          "/home-staging-kitchen",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle =
          newLang === "es"
            ? "Home Staging de Cocina | Transformar su cocina con home staging"
            : newLang === "fr"
            ? "Home Staging Cuisine | Transformer votre cuisine avec le home staging"
            : "Kitchen Home Staging | Transform your kitchen with home staging";
      } else if (
        [
          "/es/blog/diseñador-ambientes",
          "/fr/blog/designer-environnement",
          "/blog/environmental-designer",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Designer d'environnement : Guide sur le design d'environnement"
          : newLang === "es"
          ? "Diseñador de ambientes: Guía sobre el diseño de ambientes"
          : "Environmental Designer: Guide to Environmental Design";
      } else if (
        [
          "/es/blog/modernizar-casa",
          "/fr/blog/moderniser-maison",
          "/blog/modernize-home",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Comment moderniser votre maison avec des meubles anciens ?"
          : newLang === "es"
          ? "¿Cómo modernizar tu casa con muebles antiguos?"
          : "How to modernize your home with antique furniture?";
      } else if (
        [
          "/es/blog/propiedad-no-se-vende",
          "/fr/blog/propriete-qui-ne-se-vend-pas",
          "/blog/property-that-wont-sell",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Comment vendre une propriété qui ne se vend pas ?"
          : newLang === "es"
          ? "Como vender una propiedad que no se vende"
          : "How to revive a property that won't sell";
      } else if (
        ["/es/blog/flipping", "/fr/blog/flipping", "/blog/flipping"].some(
          (path) => currentUrl.includes(path)
        )
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Flipping Immobilier | Qu'est-ce que c'est et avantages du flipping"
          : newLang === "es"
          ? "Flipping inmobiliario | Qué es y beneficios del flipping"
          : "Real Estate Flipping | What it is and benefits of flipping";
      } else if (
        [
          "/es/api-documentation",
          "/fr/api-documentation",
          "/api-documentation",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "API de Mise en Scène Virtuelle Pedra.so | Designs d'Intérieur Uniques"
          : newLang === "es"
          ? "API de Home Staging Virtual de Pedra.so | Crea Home Stagings Virtuales"
          : "Pedra.so Virtual Home Staging API | Create Stunning Virtual Stagings";
      } else if (
        [
          "/es/lanzamientos/renueva-y-amuebla-en-un-clic",
          "/fr/versions/renovez-et-meublez-en-un-clic",
          "/releases/renovate-and-furnish-in-one-click",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Pedra : Rénovez et meublez en un clic - Meilleurs résultats 'Auto'"
          : newLang === "es"
          ? "Pedra: Renueva y amuebla en un clic - Mejores resultados en 'Auto'"
          : "Pedra: Renovate and furnish in one click - Improved 'Auto' results";
      } else if (currentUrl.includes("blog/sell-more-properties-with-ai")) {
        newTitle = "Sell More Real Estate Properties With AI";
      } else if (
        [
          "/es/blog/domotizar-casa",
          "/fr/blog/domotiser-maison",
          "/blog/home-automation",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Domotiser votre maison : Quels sont les avantages et comment la domotiser"
          : newLang === "es"
          ? "Domotizar tu casa: Qué ventajas tiene y cómo domotizarla"
          : "Home Automation: Its Benefits and How to Automate Your Home";
      } else if (
        [
          "/es/blog/salas-hundidas",
          "/fr/blog/salons-en-contrebas",
          "/blog/sunken-living-rooms",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Salons en contrebas : Qu'est-ce que c'est et comment les concevoir ?"
          : newLang === "es"
          ? "Salas hundidas: ¿Qué son y cómo diseñarlas?"
          : "Sunken living rooms: What are they and how to design them?";
      } else if (currentUrl.includes("add-3d-objects-and-make-sky-blue")) {
        newTitle = "Add 3D objects and make sky blue";
      } else if (currentUrl.includes("anade-objetos-3d-y-cielo-azul")) {
        newTitle = "Añade objetos 3D y pon el cielo azul";
      } else if (currentUrl.includes("help/how-to-renovate-houses-virtually")) {
        newTitle = "How To Create a Virtual Renovation of a House";
      } else if (currentUrl.includes("help/como-renovar-casas-virtualmente")) {
        newTitle = "Cómo Crear Una Renovación Virtual de una Casa";
      } else if (currentUrl.includes("help/how-to-remove-object")) {
        newTitle = "How to Remove Objects from Property Photos";
      } else if (
        [
          "/fr/help/comment-creer-home-staging-virtuel",
          "/help/how-to-create-virtual-home-staging",
          "/es/help/como-crear-home-staging-virtual",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Comment créer des home stagings virtuels : Guide pratique"
          : newLang === "es"
          ? "Cómo crear home stagings virtuales de manera automática"
          : "How to Create a Virtual Home Staging: A Step-by-Step Guide";
      } else if (
        [
          "/es/blog/como-personalizar-habitacion",
          "/fr/blog/comment-personnaliser-chambre",
          "/blog/how-to-personalize-room",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Comment personnaliser ma chambre ? Étapes et conseils"
          : newLang === "es"
          ? "¿Cómo personalizar mi habitación? Pasos y consejos"
          : "How to Personalize My Room? Steps and Tips";
      } else if (
        [
          "/es/blog/mentor-inmobiliario",
          "/fr/blog/mentor-immobilier",
          "/blog/real-estate-mentor",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Qu'est-ce qu'un Mentor Immobilier et comment le choisir"
          : newLang === "es"
          ? "Qué es un Mentor Inmobiliario y cómo seleccionarlo"
          : "What is a Real Estate Mentor and How to Choose One";
      } else if (
        [
          "/es/blog/pricing-inmobiliario",
          "/fr/blog/pricing-immobilier",
          "/blog/real-estate-pricing",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Pricing immobilier | Qu'est-ce que c'est et comment fixer les prix"
          : newLang === "es"
          ? "Pricing inmobiliario | Qué es y cómo fijar los precios"
          : "Real Estate Pricing | What it is and how to set prices";
      } else if (
        [
          "/fr/help/comment-vider-pieces-virtuellement",
          "/help/how-to-empty-rooms-virtually",
          "/es/help/como-vaciar-habitacion-virtualmente",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Comment vider virtuellement des pièces : Guide pratique"
          : newLang === "es"
          ? "Cómo vaciar habitaciones virtualmente de manera automática"
          : "How to Empty Rooms Virtually And Easily: A Step-by-Step Guide";
      } else if (
        [
          "/es/herramientas-gratis/generar-descripcion-anuncio-inmobiliario-gratis",
          "/fr/outils-gratuits/generer-description-annonce-immobiliere-gratuit",
          "/free-tools/generate-real-estate-listing-description-free",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Générateur gratuit de descriptions immobilières | Créez des annonces percutantes"
          : newLang === "es"
          ? "Anuncios inmobiliarios gratis | Crea publicidad inmobiliaria"
          : "Free Real Estate Description Generator | Create Compelling Listings";
      } else if (
        [
          "/fr/releases/ameliorations-ameublement-styles-personnalises-preservation-fenetres",
          "/releases/realistic-furnishing",
          "/es/releases/amueblado-realista",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Ameublement réaliste & Styles personnalisés"
          : newLang === "es"
          ? "Actualización Pedra: Amueblado realista y Estilos personalizados"
          : "Realistic Furnishing & Custom Styles For Real Estate";
      } else if (
        [
          "/fr/blog/marketing-immobilier",
          "/blog/real-estate-marketing",
          "/es/blog/marketing-inmobiliario",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Marketing Immobilier : Qu'est-ce que c'est et comment l'appliquer ?"
          : newLang === "es"
          ? "Marketing inmobiliario ¿Qué es y cómo aplicarlo?"
          : "Real Estate Marketing: What It Is and How to Apply It.";
      } else if (
        [
          "/fr/blog/tendances-marche-immobilier",
          "/blog/real-estate-market-trends",
          "/es/blog/tendencias-mercado-inmobiliario",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Tendances actuelles du marché immobilier"
          : newLang === "es"
          ? "Tendencias actuales del mercado inmobiliario"
          : "Current Real Estate Market Trends";
      } else if (
        [
          "/fr/blog/augmentez-valeur-maison",
          "/blog/increase-home-value",
          "/es/blog/aumenta-valor-vivienda",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Augmentez la valeur de la maison rapidement et facilement"
          : newLang === "es"
          ? "Aumenta el valor de la vivienda rápido y fácil"
          : "Increase Home Value Quickly and Easily";
      } else if (
        [
          "/fr/blog/espaces-multifonctionnels",
          "/blog/multifunctional-spaces",
          "/es/blog/espacios-multifuncionales",
        ].some((path) => currentUrl.includes(path))
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Espaces multifonctionnels: Qu'est-ce que c'est et comment optimiser l'espace?"
          : newLang === "es"
          ? "Espacios Multifuncionales ¿Qué es y cómo optimizar espacio?"
          : "Multifunctional Spaces: What Are They and How to Optimize Space?";
      } else if (
        currentUrl.includes("/fr/help/comment-ajouter-des-objets-3d")
      ) {
        newTitle =
          "Comment Utiliser l'Outil 'Add Object' pour une Mise en Scène Virtuelle";
      } else if (
        currentUrl.includes("/fr/help/comment-renover-des-maisons-en-virtuel")
      ) {
        newTitle = "Comment créer une rénovation virtuelle d'une maison";
      } else if (currentUrl.includes("/fr/help/comment-meubler-en-virtuel")) {
        newTitle =
          "Guide étape par étape pour meubler virtuellement des pièces avec l'outil de mobilier virtuel";
      } else if (currentUrl.includes("/fr/help/comment-supprimer-un-objet")) {
        newTitle = "Comment supprimer des objets des photos de propriété";
      } else if (currentUrl.includes("es/help/como-borrar-objeto")) {
        newTitle = "Cómo borrar objetos de fotos de casas y propiedades";
      } else if (
        ["/fr/interiordesign", "/es/interiordesign", "/interiordesign"].some(
          (path) => currentUrl.includes(path)
        )
      ) {
        newTitle =
          newLang === "es"
            ? "Diseño de interiores con IA"
            : newLang === "fr"
            ? "Design d'intérieur avec IA"
            : "AI Interior Design";
      } else if (currentUrl.includes("real-estate-photography")) {
        newTitle =
          newLang === "es"
            ? "Pedra | Mejora tus fotografías inmobiliarias con IA"
            : newLang === "fr"
            ? "Méliorez vos photos immobilières en un clic"
            : "Pedra | Improve your real estate photos with AI";
      } else if (currentUrl.includes("realestate")) {
        newTitle =
          newLang === "es"
            ? "Pedra | Herramienta Renders Para Inmobiliarias"
            : newLang === "fr"
            ? "Optimisez vos Annonces Immobilières avec Pedra"
            : "Pedra | Easy Render Tool For Real Estate";
      } else if (currentUrl.includes("como-vender-casa")) {
        newTitle =
          newLang === "es"
            ? "¿Cómo vender mi casa? | Consejos para vender rápido tu casa"
            : "Pedra | Easy Render Tool For Real Estate";
      } else if (currentUrl.includes("renovar-casa")) {
        newTitle =
          newLang === "es"
            ? "¿Cómo renovar tu casa? | Pasos e ideas para renovar tu hogar"
            : "Pedra | Easy Render Tool For Real Estate";
      } else if (currentUrl.includes("pricing")) {
        newTitle =
          newLang === "es"
            ? "Pedra | Precios"
            : newLang === "fr"
            ? "Tarifs Pedra: Forfait Pro à 29€/mois"
            : "Pedra | Pricing";
      } else if (currentUrl.includes("render")) {
        newTitle =
          newLang === "es"
            ? "Renders IA | Crea renders con IA "
            : newLang === "fr"
            ? "Rendus Immobiliers IA – Transformez Vos Images en Rendus Professionnels avec IA"
            : "AI Renders | Create AI renders";
      } else if (
        currentUrl.includes("/blog/how-to-make-sky-blue-real-estate-photo")
      ) {
        newTitle = "How To Turn Sky Blue In Real Estate Photo";
      } else if (
        currentUrl.includes(
          "/fr/blog/vendre-plus-de-proprietes-immobilieres-avec-ia"
        )
      ) {
        newTitle = "IA dans l'immobilier : Applications et Avantages";
      } else if (
        currentUrl.includes(
          "/fr/blog/comment-utiliser-lia-pour-vendre-des-proprietes"
        )
      ) {
        newTitle = "Comment l'IA Révolutionne la Vente Immobilière";
      } else if (currentUrl.includes("/fr/blog/exemples-de-home-staging")) {
        newTitle = "Exemples de Home Staging : Avant et Après";
      } else if (
        currentUrl.includes(
          "/fr/blog/comment-acceleration-des-ventes-avec-la-photo-de-couverture-de-lannonce-immobiliere"
        )
      ) {
        newTitle =
          "Comment la photo de couverture peut accélérer la vente de votre propriété";
      } else if (
        currentUrl.includes(
          "/fr/blog/comment-ajouter-un-ciel-bleu-aux-photographies-immobilieres"
        )
      ) {
        newTitle = "Comment rendre le ciel bleu dans vos photos immobilières";
      } else if (
        currentUrl.includes(
          "/es/blog/como-poner-cielo-azul-fotografias-de-inmuebles"
        )
      ) {
        newTitle = "Cómo Poner Cielo Azul En Fotos de Inmuebles";
      } else if (
        currentUrl.includes("como-utilizar-ia-para-vender-propiedades") ||
        currentUrl.includes("how-to-sell-properties-with-ai")
      ) {
        newTitle =
          newLang === "es"
            ? "¿Cómo usar la IA en Inmobiliarias? | IA para inmobiliarias"
            : "What is AI and how is it used in Real Estate? | AI for Real Estate";
      } else if (currentUrl.includes("/help/how-to-furnish-virtually")) {
        newTitle =
          "Step-by-Step Guide to Virtually Furnish Rooms with Virtual Furnishing Tool";
      } else if (currentUrl.includes("/es/help/como-amueblar-virtualmente")) {
        newTitle =
          "Tutorial de Amueblado Virtual: Guía Paso a Paso para Amueblar Virtualmente";
      } else if (currentUrl.includes("home-staging-virtual")) {
        newTitle =
          newLang === "es"
            ? "Home Staging Virtual | Crear un home staging virtual"
            : newLang === "fr"
            ? "Home Staging Virtuel | Créer un home staging virtuel"
            : "Virtual Home Staging | Create a virtual home staging";
      } else if (
        currentUrl.includes("vende-mas-propiedades-inmobiliarias-con-ia")
      ) {
        newTitle =
          newLang === "es"
            ? "¿Cómo puede la IA ayudar a los agentes inmobiliarios a vender?"
            : "How can I help real estate agents sell?";
      } else if (
        currentUrl.includes("vende-mas-propiedades-inmobiliarias-con-ia")
      ) {
        newTitle =
          newLang === "es"
            ? "¿Cómo puede la IA ayudar a los agentes inmobiliarios a vender?"
            : "How can I help real estate agents sell?";
      } else if (currentUrl.includes("privacy")) {
        newTitle =
          newLang === "es"
            ? "Pedra | Política de Privacidad"
            : newLang === "fr"
            ? "Politique de Confidentialité - Pedra.so"
            : "Pedra | Privacy Policy";
      } else if (currentUrl.includes("legal")) {
        newTitle =
          newLang === "es"
            ? "Pedra | Aviso Legal"
            : newLang === "fr"
            ? "Mentions Légales - Pedra.so"
            : "Pedra | Legal Notice";
      } else if (currentUrl.includes("renovate-interiors-with-ai-from-phone")) {
        newTitle = "Renovate interiors with AI with phone";
      } else if (
        currentUrl.includes("renueva-espacios-desde-el-movil-con-ia")
      ) {
        newTitle = "Renueva habitaciones con IA desde el móvil";
      } else if (
        currentUrl.includes("empty-rooms-and-remove-objects-with-ai")
      ) {
        newTitle = "Empty rooms and remove objects with AI";
      } else if (currentUrl.includes("/es/blog/home-staging-ejemplos")) {
        newTitle = "Ejemplos de Home Staging | Antes y Después";
      } else if (currentUrl.includes("/blog/home-staging-examples")) {
        newTitle = "Home Staging Examples | Before and After";
      } else if (
        currentUrl.includes("vacia-habitacion-y-quita-objetos-con-ia")
      ) {
        newTitle = "Vacia habitación y quita objetos con IA";
      } else if (currentUrl.includes("about")) {
        newTitle =
          newLang === "es"
            ? "Pedra | Sobre Nosotros"
            : newLang === "fr"
            ? "À Propos de Pedra - Outil de home staging virtuel"
            : "Pedra | About Us";
      } else if (
        currentUrl.includes(
          "/blog/how-to-speed-up-sales-with-real-estate-listing-cover-photo"
        )
      ) {
        newTitle = "How To Improve Real Estate Listing Cover";
      } else if (
        currentUrl.includes(
          "/blog/como-asegurar-que-la-foto-de-portada-de-tu-anuncio-inmobiliario-acelere-la-venta"
        )
      ) {
        newTitle = "Cómo Mejorar La Foto De Portada Del Anuncio Inmobiliario";
      } else if (currentUrl.includes("es/mls")) {
        newTitle = "Pedra y MLS| Herramienta Inmobiliaria Con IA Para La MLS";
      } else if (currentUrl.includes("releases")) {
        newTitle =
          newLang === "es"
            ? "Pedra | Novedades en herramienta de home staging virtual"
            : newLang === "fr"
            ? "Dernières Versions de Pedra – Juillet, Juin et Mai 2024"
            : "Pedra | Releases on virtual home staging tool";
      } else if (currentUrl.includes("reviews-and-testimonials")) {
        newTitle =
          newLang === "es"
            ? "Testimonios para Pedra | Opiniones y Valoraciones"
            : newLang === "fr"
            ? "Témoignages Pedra | Découvrez les Avis de Nos Clients"
            : "Testimonials for Pedra | Reviews and Opinions";
      } else if (currentUrl.includes("blog")) {
        newTitle =
          newLang === "es"
            ? "Blog de Pedra | Virtual Home Staging y Fotografía Inmobiliaria"
            : newLang === "fr"
            ? "Blog de Pedra – Conseils et Astuces pour l'Immobilier avec l'IA"
            : "Pedra's Blog | Home Staging Virtual and Real Estate Photography";
      } else if (currentUrl.includes("help/how-to-add-3d-objects")) {
        newTitle = "Add Object to Create a Virtual Home Staging";
      } else if (currentUrl.includes("/es/help/como-anadir-objetos-3d")) {
        newTitle = "Añadir Objeto para Crear un Home Staging Virtual";
      } else if (
        ["/es/help", "/fr/help", "/help"].some((path) =>
          currentUrl.includes(path)
        )
      ) {
        newTitle = location.pathname.includes("/fr/")
          ? "Aide au Home Staging Virtuel & Tutoriels | Support Pedra"
          : newLang === "es"
          ? "Ayuda de Home Staging Virtual y Tutoriales | Soporte Pedra"
          : "Virtual Staging Help & Tutorials | Pedra Support";
      } else {
        newTitle =
          newLang === "es"
            ? "Pedra | Renders IA, Home Staging Virtual, Fotografía Inmobiliaria"
            : newLang === "fr"
            ? "Pedra | Rendus IA, Home Staging Virtuel, Photographie Immobilière"
            : "Pedra | AI Render, Virtual Home Staging, Real Estate Photography";
      }

      pageTitle.innerText = newTitle;
    }
  }, [location]);

  return null; // This component doesn't render anything
};

export default LangManager;
