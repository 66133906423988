import React from "react";
import HowToDrawFloorPlan1 from "../Images/HowToDrawFloorPlan1.png";
import HowToDrawFloorPlan2 from "../Images/HowToDrawFloorPlan2.png";
import InfoIcon from "../Icons/InfoIcon";

function HowToDrawFloorPlanFr() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">Comment dessiner un plan d'étage</h1>

          <div className="title-secondary gray">
            Tutoriel pour créer des plans d'étage sans être architecte
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Que pouvez-vous faire avec notre outil de plans ?
            </h2>
            <p className="article-text">
              Notre outil de plans vous permet de créer des plans d'étage
              professionnels en quelques minutes, sans formation en
              architecture. Que vous soyez agent immobilier, décorateur
              d'intérieur ou propriétaire, vous pouvez facilement créer des
              plans précis et visuellement attractifs.
              <p style={{ marginTop: "10px" }}>
                La création de plans est particulièrement utile pour les
                annonces immobilières, car elle aide les acheteurs potentiels à
                comprendre l'agencement et la circulation dans l'espace avant la
                visite.
              </p>
            </p>

            <h2 className="article-subtitleh2">
              Comment créer un plan d'étage
            </h2>
            <p className="article-text">
              Commencez par créer un nouveau projet dans notre outil de plans.
              <p style={{ marginTop: "10px" }}>
                Cliquez sur le bouton{" "}
                <span style={{ fontWeight: "500" }}>"Nouveau Projet"</span> pour
                commencer votre plan.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToDrawFloorPlan2}
                alt="Comment utiliser l'outil de plans – Capture d'écran du logiciel Pedra"
              />
            </p>

            <h2 className="article-subtitleh2">
              <span style={{ fontWeight: "600" }}>Éléments de base : </span>
              Créez votre plan en quelques secondes
            </h2>
            <p style={{ marginTop: "10px" }}>
              La barre d'outils supérieure contient tous les éléments de base
              nécessaires : pièces, portes, fenêtres et meubles. Cliquez
              simplement sur un élément pour l'ajouter à votre canvas.
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "20px 0px",
                }}
                src={HowToDrawFloorPlan1}
                alt="Comment utiliser l'outil de plans – Capture d'écran du logiciel Pedra"
              />
            </p>

            <h2 className="article-subtitleh2">
              <span style={{ fontWeight: "600" }}>
                Options de personnalisation :{" "}
              </span>
              Modifiez les éléments de votre plan
            </h2>
            <p className="article-text">
              Après avoir placé les éléments, vous pouvez facilement les
              personnaliser selon vos besoins.
              <p style={{ marginTop: "10px" }}>
                Cliquez sur n'importe quel élément pour accéder à ses propriétés
                :
                <p style={{ marginTop: "10px" }}>
                  Pour les pièces, vous pouvez ajuster la taille, masquer les
                  murs, changer la couleur et afficher/masquer le nom de la
                  pièce. Pour les meubles, les portes et les fenêtres, vous
                  pouvez les faire pivoter, redimensionner et repositionner
                  selon vos besoins.
                </p>
                <p style={{ marginTop: "10px" }}>
                  Pour renommer une pièce, double-cliquez simplement sur son nom
                  et tapez le nouveau nom.
                </p>
              </p>
            </p>

            <aside className="callout">
              <div>
                <InfoIcon />
              </div>
              <div className="callout-text">
                <h4 className="callout-h4">Conseils pour de meilleurs plans</h4>
                <p className="callout-p">
                  Pour obtenir les meilleurs résultats avec vos plans :
                  <ul>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Commencez par les pièces
                      </span>
                      . Créez la structure principale avant d'ajouter les murs
                      intérieurs et les meubles.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Utilisez des mesures standard
                      </span>
                      . Cela garantit que votre plan est réaliste et à
                      l'échelle.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Ajoutez des meubles pour une meilleure visualisation
                      </span>
                      . Cela aide les visiteurs à mieux comprendre l'espace.
                    </li>
                  </ul>
                </p>
              </div>
            </aside>

            <h2 className="article-subtitleh2">
              Tutoriel vidéo : Créer un plan d'étage
            </h2>

            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "10px",
              }}
            >
              <iframe
                width="100%"
                height="400px"
                src="https://www.youtube.com/embed/Urt4OaV--Wg?si=QJicp9L_1wh6HrjK"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>

            <h2 className="article-subtitleh2">Raccourcis clavier</h2>
            <p className="article-text">
              Pour travailler plus efficacement, utilisez ces raccourcis clavier
              :
              <ul style={{ marginTop: "10px", marginLeft: "20px" }}>
                <li>Faire pivoter les éléments : Ctrl/Cmd + R</li>
                <li>Supprimer un élément : Delete ou Retour arrière</li>
                <li>Copier un élément : Ctrl/Cmd + C</li>
                <li>Coller un élément : Ctrl/Cmd + V</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default HowToDrawFloorPlanFr;
