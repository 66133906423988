import React from "react";
import HowToDrawFloorPlan1 from "../Images/HowToDrawFloorPlan1.png";
import HowToDrawFloorPlan2 from "../Images/HowToDrawFloorPlan2.png";
import InfoIcon from "../Icons/InfoIcon";

function HowToDrawFloorPlan() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">How to Draw Floor Plans</h1>

          <div className="title-secondary gray">
            Tutorial on creating floor plans without being an architect
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              What can you do with our floor plan tool?
            </h2>
            <p className="article-text">
              Our floor plan tool allows you to create professional floor plans
              in minutes, without any architectural background. Whether you're a
              real estate agent, interior designer, or property owner, you can
              easily create accurate and visually appealing floor plans.
              <p style={{ marginTop: "10px" }}>
                Creating floor plans is especially useful for property listings,
                as it helps potential buyers understand the layout and flow of
                the space before visiting.
              </p>
            </p>

            <h2 className="article-subtitleh2">How to create a floor plan</h2>
            <p className="article-text">
              Start by creating a new project in our floor plan tool.
              <p style={{ marginTop: "10px" }}>
                Click on the{" "}
                <span style={{ fontWeight: "500" }}>"New Project"</span> button
                to begin your floor plan.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToDrawFloorPlan2}
                alt="How to use the floor plan tool – Screenshot of Pedra software"
              />
            </p>

            <h2 className="article-subtitleh2">
              <span style={{ fontWeight: "600" }}>Basic Elements: </span>
              Create your floor plan in seconds
            </h2>
            <p style={{ marginTop: "10px" }}>
              The top toolbar contains all the basic elements you need: rooms,
              doors, windows, and furniture. Simply click on any element to add
              it to your canvas.
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "20px 0px",
                }}
                src={HowToDrawFloorPlan1}
                alt="How to use the floor plan tool – Screenshot of Pedra software"
              />
            </p>

            <h2 className="article-subtitleh2">
              <span style={{ fontWeight: "600" }}>Customization options: </span>
              Modify your floor plan elements
            </h2>
            <p className="article-text">
              After placing elements, you can easily customize them to your
              needs.
              <p style={{ marginTop: "10px" }}>
                Click on any element to access its properties:
                <p style={{ marginTop: "10px" }}>
                  For rooms, you can adjust size, hide walls, color, and
                  hide/show the room name. For furniture, doors, and windows,
                  you can rotate, resize, and reposition them as needed.
                </p>
                <p style={{ marginTop: "10px" }}>
                  To rename a room, simply double-click on its name and type the
                  new one.
                </p>
              </p>
            </p>

            <aside className="callout">
              <div>
                <InfoIcon />
              </div>
              <div className="callout-text">
                <h4 className="callout-h4">Tips for better floor plans</h4>
                <p className="callout-p">
                  To get the best results with your floor plans:
                  <ul>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Start with the rooms
                      </span>
                      . Create the main structure before adding internal walls
                      and furniture.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Use standard measurements
                      </span>
                      . This ensures your floor plan is realistic and to scale.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Add furniture for better visualization
                      </span>
                      . This helps viewers understand the space better.
                    </li>
                  </ul>
                </p>
              </div>
            </aside>

            <h2 className="article-subtitleh2">
              Video tutorial: Creating a floor plan
            </h2>

            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "10px",
              }}
            >
              <iframe
                width="100%"
                height="400px"
                src="https://www.youtube.com/embed/Urt4OaV--Wg?si=QJicp9L_1wh6HrjK"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </div>

            <h2 className="article-subtitleh2">Keyboard shortcuts</h2>
            <p className="article-text">
              To work more efficiently, use these keyboard shortcuts:
              <ul style={{ marginTop: "10px", marginLeft: "20px" }}>
                <li>Rotate elements: Ctrl/Cmd + R</li>
                <li>Delete element: Delete or Backspace</li>
                <li>Copy element: Ctrl/Cmd + C</li>
                <li>Paste element: Ctrl/Cmd + V</li>
              </ul>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default HowToDrawFloorPlan;
