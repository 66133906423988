import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import HowToRenovateFrame1 from "../Images/HowToRenovateFrame1.jpg";
import HowToRenovateFrame2 from "../Images/HowToRenovateFrame2.jpg";
import HowToRenovateFrame3 from "../Images/HowToRenovateFrame3.jpg";
import InfoIcon from "../Icons/InfoIcon";

import BeforeBedroomStaging1 from "../Images/curtain-effect/BeforeBedroomStaging1.png";
import AfterBedroomStaging1 from "../Images/curtain-effect/AfterBedroomStaging1.png";
import BeforeBedroomStaging2 from "../Images/curtain-effect/BeforeBedroomStaging2.png";
import AfterBedroomStaging2 from "../Images/curtain-effect/AfterBedroomStaging2.png";
import BeforeBedroomStaging3 from "../Images/curtain-effect/BeforeBedroomStaging3.png";
import AfterBedroomStaging3 from "../Images/curtain-effect/AfterBedroomStaging3.png";

import CurtainEffect from "../CurtainEffect";

function HowToRenderBedroom() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">How to Create Bedroom Renders</h1>

          <div className="title-secondary gray">
            Tutorial on How to Create Bedroom Renders
          </div>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Founder at Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">Founder of Pedra</div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Why use Pedra's render tools for bedrooms?
            </h2>
            <p className="article-text">
              Pedra's render tools allow you to visualize a complete bedroom
              transformation in just seconds, helping potential buyers see the
              true potential of the space.
              <p style={{ marginTop: "10px" }}>
                Bedroom renovations are essential as they're one of the most
                personal and important spaces in any home. Being able to show
                the potential of a dated bedroom can make a huge difference in
                selling second-hand properties.
              </p>
              <p style={{ marginTop: "10px" }}>
                With Pedra, you no longer need specialized interior designers or
                3D rendering experts to create compelling bedroom renovation
                proposals.
              </p>
            </p>
            <h2 className="article-subtitleh2">
              How do I create a bedroom render?
            </h2>
            <p className="article-text">
              Start by uploading clear photos of the existing bedroom you want
              to transform.
              <p style={{ marginTop: "10px" }}>
                Select your bedroom images and click{" "}
                <span style={{ fontWeight: "500" }}>"Edit with AI"</span>, then
                choose the{" "}
                <span style={{ fontWeight: "500" }}>"Renovation"</span> option.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToRenovateFrame1}
                alt="How to Use the Renovation Tool to Create Bedroom Renders – Pedra Software Screenshot"
              />
            </p>
            <h2 className="article-subtitleh2">
              <span style={{ fontWeight: "600" }}>
                Quick Bedroom Renovation:{" "}
              </span>
              Generate a Fast Bedroom Render
            </h2>
            <p style={{ marginTop: "10px" }}>
              The <span style={{ fontWeight: "500" }}>"Renovation"</span> tool
              will transform your bedroom in about 25 seconds.
              <p>
                For a subtle update, choose the "Minimalist" style with "Low"
                creativity. This will maintain the basic layout while
                modernizing finishes and furniture.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "20px 0px",
                }}
                src={HowToRenovateFrame2}
                alt="How to Use the Renovation Tool to Create Bedroom Renders – Pedra Software Screenshot"
              />
            </p>

            <h2 className="article-subtitleh2">
              Customize Your Bedroom Renovation Render
            </h2>
            <p className="article-text">
              Pedra offers extensive customization options for bedroom renders.
              <p style={{ marginTop: "10px" }}>
                <p style={{ marginTop: "10px" }}>
                  Choose from various bedroom styles, control how dramatic the
                  changes should be, and decide whether to preserve existing
                  elements like windows or architectural features.
                </p>
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                    margin: "20px 0px",
                  }}
                  src={HowToRenovateFrame3}
                  alt="How to Use the Renovation Tool to Create Bedroom Renders – Pedra Software Screenshot"
                />
              </p>
            </p>
            <br />
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Step 1 – </span>
              Choose your bedroom style
            </h3>
            <p className="article-text">
              Select from 9 different bedroom styles including minimalist,
              traditional, Mediterranean, and more.
              <p className="article-text" style={{ marginTop: "10px" }}>
                <span style={{ fontWeight: "500" }}>
                  You can also upload reference images of bedroom styles you
                  love.
                </span>{" "}
                This allows you to create renders that match specific design
                inspiration or your client's preferred aesthetic.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Step 2 – </span>
              Set the renovation intensity
            </h3>
            <p className="article-text">
              Choose between Low, Medium, High, and Very High creativity levels
              for your bedroom transformation.
              <p style={{ marginTop: "10px" }}>
                Low and Medium settings will update finishes and furniture while
                keeping the basic room layout intact.
              </p>
              <p style={{ marginTop: "10px" }}>
                High and Very High settings can completely reimagine the space
                with new layouts, lighting configurations, and architectural
                changes.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Step 3 – </span>
              Preserve key bedroom elements
            </h3>
            <p className="article-text">
              Choose to preserve windows, which is particularly important for
              bedrooms with views or specific lighting requirements. This
              ensures your render maintains practical elements while updating
              the aesthetic.
              <br />
            </p>
            <h2 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Step 4 – </span>
              Generate your bedroom render
            </h2>
            <p>
              After selecting your preferences, generate the bedroom render.
              Standard renders take about 15 seconds, while preserving specific
              elements may take slightly longer.
            </p>
            <aside className="callout">
              <div>
                <InfoIcon />
              </div>
              <div className="callout-text">
                <h4 className="callout-h4">Tips for better bedroom renders</h4>
                <p className="callout-p">
                  To get the most realistic and useful bedroom renders:
                  <ul>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Generate multiple versions
                      </span>
                      . Each render is unique, and you might find some better
                      preserve important features like architectural details or
                      natural lighting.
                    </li>
                    <li style={{ fontWeight: "500" }}>
                      Use low creativity for subtle updates to dated bedrooms.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Ensure good lighting when taking photos and minimize
                        reflections
                      </span>
                      . This helps the AI better understand the space and create
                      more accurate transformations.
                    </li>
                  </ul>
                </p>
              </div>
            </aside>
            <h2 className="article-subtitleh2">Bedroom render examples</h2>
            <p className="article-text">
              Here are several examples of bedroom renovations created with our
              renders:
            </p>
            <CurtainEffect
              beforeImage={BeforeBedroomStaging1}
              afterImage={AfterBedroomStaging1}
              altBefore={"Bedroom before render"}
              altAfter={"Bedroom after render"}
            />
            <CurtainEffect
              beforeImage={BeforeBedroomStaging2}
              afterImage={AfterBedroomStaging2}
              altBefore={"Bedroom before render"}
              altAfter={"Bedroom after render"}
            />
            <CurtainEffect
              beforeImage={BeforeBedroomStaging3}
              afterImage={AfterBedroomStaging3}
              altBefore={"Bedroom before render"}
              altAfter={"Bedroom after render"}
            />
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default HowToRenderBedroom;
