import React from "react";
import CustomerLogo1 from "./Images/customer-logos/customer-logo-agenthia.png";
import CustomerLogo2 from "./Images/customer-logos/customer-logo-aproperties.png";
import CustomerLogo3 from "./Images/customer-logos/customer-logo-coldwellbanker.png";
import CustomerLogo4 from "./Images/customer-logos/customer-logo-donpiso.png";
import CustomerLogo5 from "./Images/customer-logos/customer-logo-espigul.png";
import CustomerLogo6 from "./Images/customer-logos/customer-logo-exp.png";
import CustomerLogo7 from "./Images/customer-logos/customer-logo-fincaslluria.png";
import CustomerLogo8 from "./Images/customer-logos/customer-logo-grocasa.png";
import CustomerLogo9 from "./Images/customer-logos/customer-logo-iad.png";
import CustomerLogo10 from "./Images/customer-logos/customer-logo-kivir.png";
import CustomerLogo11 from "./Images/customer-logos/customer-logo-kw.png";
import CustomerLogo12 from "./Images/customer-logos/customer-logo-novahaus.png";
import CustomerLogo13 from "./Images/customer-logos/customer-logo-remax.png";

function CustomerLogos() {
  return (
    <div className="customer-logo-subcontainer">
      <div className="customer-logo-group">
        <div>
          <img
            src={CustomerLogo1}
            alt="Logo of Pedra customer – Engel & Völkers"
            className="customer-logo"
          />
          <img
            src={CustomerLogo2}
            alt="Logo of Pedra customer – AProperties"
            className="customer-logo"
          />
          <img
            src={CustomerLogo3}
            alt="Logo of Pedra customer – Coldwell Banker"
            className="customer-logo"
          />
        </div>
        <div>
          <img
            src={CustomerLogo4}
            alt="Logo of Pedra customer – Don Piso"
            className="customer-logo"
          />
          <img
            src={CustomerLogo5}
            alt="Logo of Pedra customer – Espigul"
            className="customer-logo"
          />
          <img
            src={CustomerLogo6}
            alt="Logo of Pedra customer – EXP"
            className="customer-logo"
          />
          <img
            src={CustomerLogo7}
            alt="Logo of Pedra customer – Fincas Llúria"
            className="customer-logo"
          />
        </div>
      </div>
      <div className="customer-logo-group">
        <div>
          <img
            src={CustomerLogo8}
            alt="Logo of Pedra customer – Grocasa"
            className="customer-logo"
          />
          <img
            src={CustomerLogo9}
            alt="Logo of Pedra customer – IAD"
            className="customer-logo"
          />
          <img
            src={CustomerLogo10}
            alt="Logo of Pedra customer – Kivir"
            className="customer-logo"
          />
        </div>
        <div>
          <img
            src={CustomerLogo11}
            alt="Logo of Pedra customer – KW"
            className="customer-logo"
          />
          <img
            src={CustomerLogo12}
            alt="Logo of Pedra customer – Colegio de API"
            className="customer-logo"
          />
          <img
            src={CustomerLogo13}
            alt="Logo of Pedra customer – RE/MAX"
            className="customer-logo"
          />
        </div>
      </div>
    </div>
  );
}

export default CustomerLogos;
