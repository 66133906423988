import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import HowToRenovateFrame1 from "../Images/HowToRenovateFrame1.jpg";
import HowToRenovateFrame2 from "../Images/HowToRenovateFrame2.jpg";
import HowToRenovateFrame3 from "../Images/HowToRenovateFrame3.jpg";
import InfoIcon from "../Icons/InfoIcon";

import BeforeLivingRoomStaging1 from "../Images/curtain-effect/BeforeSalonStaging1.png";
import AfterLivingRoomStaging1 from "../Images/curtain-effect/AfterSalonStaging1.png";
import BeforeLivingRoomStaging2 from "../Images/curtain-effect/BeforeSalonStaging2.png";
import AfterLivingRoomStaging2 from "../Images/curtain-effect/AfterSalonStaging2.png";
import BeforeLivingRoomStaging3 from "../Images/curtain-effect/BeforeSalonStaging3.png";
import AfterLivingRoomStaging3 from "../Images/curtain-effect/AfterSalonStaging3.png";

import CurtainEffect from "../CurtainEffect";

function HowToRenderLivingRoomFr() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">Comment créer des rendus de salon</h1>

          <div className="title-secondary gray">
            Tutoriel sur la création de rendus pour rénover votre salon
          </div>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Founder at Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fondateur de Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              À quoi servent les outils de rendu de Pedra ?
            </h2>
            <p className="article-text">
              Avec les outils de Pedra, vous pouvez visualiser une rénovation de
              salon en quelques secondes seulement.
              <p style={{ marginTop: "10px" }}>
                Montrer le potentiel d'un salon rénové est particulièrement
                efficace pour la vente de biens immobiliers, le salon étant la
                pièce principale qui influence le plus la décision d'achat.
              </p>
              <p style={{ marginTop: "10px" }}>
                Grâce à Pedra, il n'est plus nécessaire de faire appel à des
                décorateurs d'intérieur ou des architectes pour obtenir une
                proposition de rénovation professionnelle.
              </p>
            </p>
            <h2 className="article-subtitleh2">
              Comment créer un rendu de salon ?
            </h2>
            <p className="article-text">
              Une fois que vous avez téléchargé les photos de votre salon,
              sélectionnez-les.
              <p style={{ marginTop: "10px" }}>
                Cliquez sur{" "}
                <span style={{ fontWeight: "500" }}>"Edit with AI"</span>, et
                sélectionnez l'option{" "}
                <span style={{ fontWeight: "500" }}>"Renovation"</span>.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToRenovateFrame1}
                alt="Comment utiliser l'outil de rendu pour rénover des salons – Capture d'écran du logiciel Pedra"
              />
            </p>
            <h2 className="article-subtitleh2">
              <span style={{ fontWeight: "600" }}>Rénovation : </span>
              Créer un rendu de salon rapidement
            </h2>
            <p style={{ marginTop: "10px" }}>
              En cliquant sur{" "}
              <span style={{ fontWeight: "500" }}>"Renovation"</span>, vous
              obtiendrez une proposition de rénovation pour votre salon en
              environ 15 secondes.
              <p>
                Cette option génère par défaut un style minimaliste avec des
                modifications modérées de l'espace existant.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "20px 0px",
                }}
                src={HowToRenovateFrame2}
                alt="Comment utiliser l'outil de rendu pour rénover des salons – Capture d'écran du logiciel Pedra"
              />
            </p>

            <h2 className="article-subtitleh2">
              <span style={{ fontWeight: "600" }}>Rénovation Avancée : </span>
              Personnaliser le rendu de votre salon
            </h2>
            <p className="article-text">
              Pedra vous permet de personnaliser entièrement la rénovation de
              votre salon.
              <p style={{ marginTop: "10px" }}>
                Dans le menu{" "}
                <span style={{ fontWeight: "500" }}>"Renovation"</span>, vous
                pouvez ajuster plusieurs paramètres :
                <p style={{ marginTop: "10px" }}>
                  Le style du salon, le niveau de transformation souhaité, et la
                  possibilité de conserver certains éléments comme les fenêtres
                  ou les caractéristiques architecturales.
                </p>
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                    margin: "20px 0px",
                  }}
                  src={HowToRenovateFrame3}
                  alt="Comment utiliser l'outil de rendu pour rénover des salons – Capture d'écran du logiciel Pedra"
                />
              </p>
            </p>
            <br />
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Étape 1 – </span>
              Style de salon
            </h3>
            <p className="article-text">
              Pedra propose 9 styles de base : minimaliste, traditionnel,
              méditerranéen, etc.
              <p className="article-text" style={{ marginTop: "10px" }}>
                <span style={{ fontWeight: "500" }}>
                  Vous pouvez également importer votre propre style.
                </span>{" "}
                En important des images de référence, vous pourrez créer des
                rendus basés sur vos inspirations personnelles.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Étape 2 – </span>
              Niveau de transformation
            </h3>
            <p className="article-text">
              Choisissez le niveau de transformation entre Faible, Moyen, Élevé
              et Très Élevé.
              <p style={{ marginTop: "10px" }}>
                Les niveaux Faible et Moyen conserveront l'agencement général de
                votre salon tout en modernisant les finitions et le mobilier.
              </p>
              <p style={{ marginTop: "10px" }}>
                Les niveaux Élevé et Très Élevé permettront de repenser
                complètement l'espace, en modifiant l'agencement du mobilier et
                les éléments architecturaux.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Étape 3 – </span>
              Conservation des éléments existants
            </h3>
            <p className="article-text">
              Vous pouvez choisir de conserver les fenêtres dans votre rendu.
              Cette option est particulièrement utile pour les salons avec une
              belle vue ou un éclairage naturel spécifique à préserver.
              <br />
            </p>
            <h2 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Étape 4 – </span>
              Générer le rendu
            </h2>
            <p>
              Une fois vos préférences sélectionnées, lancez la génération du
              rendu. Le processus prend environ 15 secondes par image, un peu
              plus si vous avez choisi de conserver certains éléments.
            </p>
            <aside className="callout">
              <div>
                <InfoIcon />
              </div>
              <div className="callout-text">
                <h4 className="callout-h4">
                  Conseils pour des rendus de salon réussis
                </h4>
                <p className="callout-p">
                  Pour obtenir les meilleurs résultats possibles :
                  <ul>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Générez plusieurs versions
                      </span>
                      . Chaque rendu est unique, certains préserveront mieux les
                      éléments importants de votre salon.
                    </li>
                    <li style={{ fontWeight: "500" }}>
                      Utilisez un niveau de transformation faible pour une mise
                      à jour subtile.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Assurez-vous d'avoir un bon éclairage lors de la prise
                        de photos et minimisez les reflets
                      </span>
                      . Cela permettra d'obtenir un résultat plus précis et
                      professionnel.
                    </li>
                  </ul>
                </p>
              </div>
            </aside>
            <h2 className="article-subtitleh2">Exemples de rendus de salon</h2>
            <p className="article-text">
              Voici plusieurs exemples de rénovations de salon créées avec nos
              rendus :
            </p>
            <CurtainEffect
              beforeImage={BeforeLivingRoomStaging1}
              afterImage={AfterLivingRoomStaging1}
              altBefore={"Salon avant le rendu"}
              altAfter={"Salon après le rendu"}
            />
            <CurtainEffect
              beforeImage={BeforeLivingRoomStaging2}
              afterImage={AfterLivingRoomStaging2}
              altBefore={"Salon avant le rendu"}
              altAfter={"Salon après le rendu"}
            />
            <CurtainEffect
              beforeImage={BeforeLivingRoomStaging3}
              afterImage={AfterLivingRoomStaging3}
              altBefore={"Salon avant le rendu"}
              altAfter={"Salon après le rendu"}
            />
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default HowToRenderLivingRoomFr;
