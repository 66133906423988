import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import { Link } from "react-router-dom";
import HowToHomeStageKitchenFrame1 from "../Images/HowToHomeStageKitchenFrame1Es.jpg";
import HowToHomeStageKitchenFrame2 from "../Images/HowToHomeStageTerraceFrame2Es.jpg";
import HowToHomeStageKitchenFrame4 from "../Images/HowToHomeStageFrame4.png";

import Example4Before from "../Images/curtain-effect-hero/BeforeKitchen.png";
import Example4After from "../Images/curtain-effect-hero/AfterKitchen.png";
import Example1Before from "../Images/curtain-effect/BeforeKitchenStaging1.png";
import Example1After from "../Images/curtain-effect/AfterKitchenStaging1.png";
import Example2Before from "../Images/curtain-effect/BeforeKitchenStaging2.png";
import Example2After from "../Images/curtain-effect/AfterKitchenStaging2.png";
import Example3Before from "../Images/curtain-effect/BeforeKitchenStaging3.png";
import Example3After from "../Images/curtain-effect/AfterKitchenStaging3.png";

import CurtainEffect from "../CurtainEffect";

function HowToVirtualStagingKitchenEs() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            Cómo crear un home staging virtual para cocinas
          </h1>
          <h2 className="title-secondary gray">
            Tutorial sobre cómo crear un home staging virtual para espacios de
            cocina
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Fundador de Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fundador de Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              ¿Cómo puedo crear un home staging virtual para una cocina?
            </h2>
            <p className="article-text">
              Con Pedra puedes crear un home staging virtual para cocinas en
              pocos clics.
              <p>
                Para comenzar, accede al menú de{" "}
                <span style={{ fontWeight: "500" }}>"Editar con IA"</span> y
                selecciona <span style={{ fontWeight: "500" }}>"Amueblar"</span>
                . Selecciona "Cocina" como tipo de espacio y en unos 2 o 3
                minutos tendrás la imagen de tu{" "}
                <Link className="article-link" to="/es/home-staging-virtual">
                  home staging virtual
                </Link>{" "}
                de cocina lista para descargar.
              </p>
            </p>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageKitchenFrame1}
              alt="Cómo crear un home staging virtual de cocina – Ejemplos"
            />
            <br />
            <h2 className="article-subtitleh2">
              ¿Cómo crear un home staging virtual para cocinas?
            </h2>
            <p className="article-text">
              Para conseguir tu{" "}
              <Link className="article-link" to="/es/home-staging-cocina">
                home staging virtual de cocina
              </Link>
              , sigue estos sencillos pasos:
            </p>
            <ol>
              <li>
                Sube la imagen de la cocina que quieres amueblar virtualmente
              </li>
              <li>Selecciona la imagen para empezar a editarla</li>
              <li>
                Haz click en{" "}
                <span style={{ fontWeight: "500" }}>"Editar con IA"</span> y
                luego haz click en{" "}
                <span style={{ fontWeight: "500" }}>"Amueblar"</span>
              </li>
            </ol>
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "12px",
                width: "100%",
                margin: "10px 0px",
              }}
              src={HowToHomeStageKitchenFrame2}
              alt="Menú de cómo crear un home staging virtual de cocina"
            />
            <p className="article-text">
              Dentro de <span style={{ fontWeight: "500" }}>"Amueblar"</span>{" "}
              tendrás que seguir estos pasos:
            </p>
            <ol>
              <li>
                Selecciona la{" "}
                <span style={{ fontWeight: "500" }}>
                  posición de los elementos
                </span>
                . Para cocinas, recomendamos usar "Dibujar" para indicar áreas
                específicas donde colocar electrodomésticos, decoración y
                accesorios.
              </li>
              <li>
                Selecciona <span style={{ fontWeight: "500" }}>"Cocina"</span>{" "}
                como tipo de espacio. Esto asegurará que Pedra coloque elementos
                apropiados como electrodomésticos, utensilios de cocina y
                elementos decorativos.
              </li>
              <li>
                Selecciona el <span style={{ fontWeight: "500" }}>estilo</span>.
                Para cocinas, estilos como "Moderno", "Contemporáneo" o
                "Tradicional" suelen funcionar bien.
              </li>
              <li>
                Escoge el{" "}
                <span style={{ fontWeight: "500" }}>
                  número de imágenes a generar
                </span>
                . Recomendamos que escojas 2 o 3 imágenes para tener opciones
                diferentes de diseño de cocina.
              </li>
            </ol>

            <aside className="callout" style={{ marginTop: "10px" }}>
              <div>
                <InfoIcon />
              </div>
              <h4 className="callout-h4" style={{ fontWeight: "400" }}>
                Consejos para un mejor home staging virtual de cocina:
                <ul>
                  <li>
                    Asegúrate de que la fotografía muestre claramente toda la
                    distribución de la cocina para un resultado más preciso
                  </li>
                  <li>
                    Captura cualquier característica única como islas, ventanas
                    o detalles arquitectónicos
                  </li>
                  <li>
                    Usa la opción "Dibujar" para indicar áreas específicas para
                    electrodomésticos, accesorios de encimera o elementos
                    decorativos
                  </li>
                  <img
                    style={{
                      border: "1px solid #F7F7F7",
                      borderRadius: "12px",
                      width: "100%",
                      maxWidth: "300px",
                      height: "auto",
                      margin: "10px 0px",
                    }}
                    src={HowToHomeStageKitchenFrame4}
                    alt="Ejemplo de como crear un home staging virtual de cocina"
                  />
                </ul>
              </h4>
            </aside>
            <br />
            <h2 className="article-subtitleh2">
              ¿Por qué hacer un home staging virtual de cocina?
            </h2>
            <p className="article-text">
              La cocina suele considerarse el corazón del hogar y puede ser un
              punto decisivo en la venta. El home staging virtual de cocinas te
              permite mostrar todo el potencial del espacio, creando ambientes
              cálidos y funcionales que ayudan a los compradores a imaginarse
              cocinando y entreteniendo en ella.
            </p>
            <p className="article-text">
              Con un buen home staging virtual de cocina podrás:
            </p>
            <ul>
              <li>
                Transformar cocinas vacías o anticuadas en espacios acogedores
              </li>
              <li>
                Destacar la funcionalidad y el flujo de trabajo de la
                distribución
              </li>
              <li>
                Mostrar soluciones de almacenamiento y el potencial de las
                superficies de trabajo
              </li>
              <li>
                Ayudar a los compradores a imaginarse preparando comidas y
                entreteniendo en el espacio
              </li>
            </ul>
            <br />
            <h2 className="article-subtitleh2">
              Ejemplos de home staging virtual de cocinas
            </h2>
            <p className="article-text">
              Aquí te dejamos varios ejemplos de home stagings virtuales que
              pueden aplicarse a cocinas:
            </p>
            <CurtainEffect
              beforeImage={Example1Before}
              afterImage={Example1After}
              altBefore={"Antes de crear home staging virtual"}
              altAfter={"Después de crear home staging virtual"}
            />
            <CurtainEffect
              beforeImage={Example2Before}
              afterImage={Example2After}
              altBefore={"Antes de crear home staging virtual"}
              altAfter={"Después de crear home staging virtual"}
            />
            <CurtainEffect
              beforeImage={Example3Before}
              afterImage={Example3After}
              altBefore={"Antes de crear home staging virtual"}
              altAfter={"Después de crear home staging virtual"}
            />
            <CurtainEffect
              beforeImage={Example4Before}
              afterImage={Example4After}
              altBefore={"Antes de crear home staging virtual"}
              altAfter={"Después de crear home staging virtual"}
            />
            <br />

            <h2 className="article-subtitleh2">
              Video de cómo crear un home staging virtual para cocinas
            </h2>

            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "10px",
              }}
            >
              {typeof window !== "undefined" && (
                <iframe
                  width="100%"
                  height="400px"
                  src="https://www.youtube.com/embed/ktJul5kZMGI?si=Q6vpVrifNdvgVsGf&amp;controls=0"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              )}
            </div>
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToVirtualStagingKitchenEs;
