import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import VirtualStagingExample1 from "../Images/HowToFloorPlanRender1En.png";
import VirtualStagingExample2 from "../Images/HowToFloorPlanRender2En.png";
import VirtualStagingExample3 from "../Images/HowToFloorPlanRender3En.png";

function HowToRenderFloorPlanWithAI() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            How to Render a Floor Plan with AI
          </h1>
          <h2 className="title-secondary gray">
            Tutorial on how to generate realistic room images from floor plans
            using artificial intelligence
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Founder of Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">Founder of Pedra</div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              What is AI Floor Plan Rendering?
            </h2>
            <p className="article-text">
              With Pedra, you can transform your floor plans into photorealistic
              images of each room in just minutes. Our AI technology analyzes
              your floor plan and generates high-quality visualizations showing
              how the spaces would look in real life, allowing you to choose
              between different decoration styles.
            </p>

            <h3 className="article-subtitle">
              Step 1 – Upload your floor plan
            </h3>
            <div className="value-prop-text">
              Upload your floor plan to the Pedra platform.
            </div>
            <div className="picture">
              <img
                src={VirtualStagingExample1}
                alt="How to upload floor plans for automatic rendering in Pedra – Infographic"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto", maxWidth: "700px" }}
              />
            </div>

            <h3 className="article-subtitle">
              Step 2 – Choose your rendering options
            </h3>
            <div className="value-prop-text">
              Select your style and customization options.
              <p>Choose the number of images you want and the style.</p>
            </div>
            <div className="picture">
              <img
                src={VirtualStagingExample2}
                alt="How to choose options for floor plan rendering – Infographic"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto", maxWidth: "700px" }}
              />
            </div>

            <h3 className="article-subtitle">Step 3 – Get your room renders</h3>
            <div className="value-prop-text">
              Receive photorealistic renders of each room in your floor plan.
              <p>
                Download and use your renders immediately or continue editing
                them.
              </p>
            </div>
            <div className="picture">
              <img
                src={VirtualStagingExample3}
                alt="How to download floor plan renders – Infographic"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto", maxWidth: "700px" }}
              />
            </div>

            <aside className="callout" style={{ marginTop: "10px" }}>
              <div>
                <InfoIcon />
              </div>
              <h4 className="callout-h4" style={{ fontWeight: "400" }}>
                Tips for better results:
                <ul>
                  <li>
                    Make sure the floor plan is well-defined and measurements
                    are readable
                  </li>
                  <li>Consider the style that best suits your project</li>
                  <li>
                    Generate multiple renders to have different visualization
                    options
                  </li>
                </ul>
              </h4>
            </aside>

            <h2 className="article-subtitleh2">
              Why use AI Floor Plan Rendering?
            </h2>
            <p className="article-text">
              AI floor plan rendering offers multiple advantages:
            </p>
            <ul>
              <li>
                Immediate visualization of how spaces will look in reality
              </li>
              <li>
                Significant time savings compared to traditional rendering
              </li>
              <li>Flexibility to try different decoration styles</li>
              <li>Photorealistic quality that helps in project presentation</li>
            </ul>
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToRenderFloorPlanWithAI;
