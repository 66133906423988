import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import HowToRenovateFrame1 from "../Images/HowToRenovateFrame1.jpg";
import HowToRenovateFrame2 from "../Images/HowToRenovateFrame2.jpg";
import HowToRenovateFrame3 from "../Images/HowToRenovateFrame3.jpg";
import InfoIcon from "../Icons/InfoIcon";

import Example1Before from "../Images/curtain-effect-hero/BeforeTerraza.png";
import Example1After from "../Images/curtain-effect-hero/AfterTerraza.png";
import Example2Before from "../Images/curtain-effect/BeforeTerrazaStaging1.png";
import Example2After from "../Images/curtain-effect/AfterTerrazaStaging1.png";
import Example3Before from "../Images/curtain-effect/BeforeTerrazaStaging2.png";
import Example3After from "../Images/curtain-effect/AfterTerrazaStaging2.png";
import Example4Before from "../Images/curtain-effect/BeforeTerrazaStaging3.png";
import Example4After from "../Images/curtain-effect/AfterTerrazaStaging3.png";

import CurtainEffect from "../CurtainEffect";

function HowToRenderTerrace() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">How to Create Terrace Renders</h1>

          <div className="title-secondary gray">
            Tutorial on How to Create Terrace Renders
          </div>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Founder at Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">Founder of Pedra</div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Why use Pedra's render tools for terraces?
            </h2>
            <p className="article-text">
              Pedra's render tools allow you to visualize a complete terrace
              transformation in just seconds, helping potential buyers see the
              true potential of the outdoor space.
              <p style={{ marginTop: "10px" }}>
                Terrace renovations can dramatically increase a property's value
                as outdoor living spaces have become increasingly important to
                buyers. Being able to show the potential of an underutilized
                terrace can make a huge difference in selling properties.
              </p>
              <p style={{ marginTop: "10px" }}>
                With Pedra, you no longer need specialized landscape designers
                or 3D rendering experts to create compelling terrace renovation
                proposals.
              </p>
            </p>
            <h2 className="article-subtitleh2">
              How do I create a terrace render?
            </h2>
            <p className="article-text">
              Start by uploading clear photos of the existing terrace you want
              to transform.
              <p style={{ marginTop: "10px" }}>
                Select your terrace images and click{" "}
                <span style={{ fontWeight: "500" }}>"Edit with AI"</span>, then
                choose the{" "}
                <span style={{ fontWeight: "500" }}>"Renovation"</span> option.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToRenovateFrame1}
                alt="How to Use the Renovation Tool to Create Terrace Renders – Pedra Software Screenshot"
              />
            </p>
            <h2 className="article-subtitleh2">
              <span style={{ fontWeight: "600" }}>
                Quick Terrace Renovation:{" "}
              </span>
              Generate a Fast Terrace Render
            </h2>
            <p style={{ marginTop: "10px" }}>
              The <span style={{ fontWeight: "500" }}>"Renovation"</span> tool
              will transform your terrace in about 25 seconds.
              <p>
                For a subtle update, choose the "Minimalist" style with "Low"
                creativity. This will maintain the basic layout while
                modernizing flooring, furniture, and landscaping elements.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "20px 0px",
                }}
                src={HowToRenovateFrame2}
                alt="How to Use the Renovation Tool to Create Terrace Renders – Pedra Software Screenshot"
              />
            </p>

            <h2 className="article-subtitleh2">
              Customize Your Terrace Renovation Render
            </h2>
            <p className="article-text">
              Pedra offers extensive customization options for terrace renders.
              <p style={{ marginTop: "10px" }}>
                <p style={{ marginTop: "10px" }}>
                  Choose from various terrace styles, control how dramatic the
                  changes should be, and decide whether to preserve existing
                  elements like railings or structural features.
                </p>
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                    margin: "20px 0px",
                  }}
                  src={HowToRenovateFrame3}
                  alt="How to Use the Renovation Tool to Create Terrace Renders – Pedra Software Screenshot"
                />
              </p>
            </p>
            <br />
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Step 1 – </span>
              Choose your terrace style
            </h3>
            <p className="article-text">
              Select from 9 different terrace styles including modern, tropical,
              Mediterranean, and more.
              <p className="article-text" style={{ marginTop: "10px" }}>
                <span style={{ fontWeight: "500" }}>
                  You can also upload reference images of terrace styles you
                  love.
                </span>{" "}
                This allows you to create renders that match specific design
                inspiration or your client's preferred outdoor aesthetic.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Step 2 – </span>
              Set the renovation intensity
            </h3>
            <p className="article-text">
              Choose between Low, Medium, High, and Very High creativity levels
              for your terrace transformation.
              <p style={{ marginTop: "10px" }}>
                Low and Medium settings will update flooring, furniture, and
                plants while keeping the basic terrace layout intact.
              </p>
              <p style={{ marginTop: "10px" }}>
                High and Very High settings can completely reimagine the space
                with new layouts, pergolas, and architectural features.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Step 3 – </span>
              Preserve key terrace elements
            </h3>
            <p className="article-text">
              Choose to preserve structural elements like railings or existing
              features you want to keep. This ensures your render maintains
              practical elements while updating the overall design.
              <br />
            </p>
            <h2 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Step 4 – </span>
              Generate your terrace render
            </h2>
            <p>
              After selecting your preferences, generate the terrace render.
              Standard renders take about 15 seconds, while preserving specific
              elements may take slightly longer.
            </p>
            <aside className="callout">
              <div>
                <InfoIcon />
              </div>
              <div className="callout-text">
                <h4 className="callout-h4">Tips for better terrace renders</h4>
                <p className="callout-p">
                  To get the most realistic and useful terrace renders:
                  <ul>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Generate multiple versions
                      </span>
                      . Each render is unique, and you might find some better
                      integrate outdoor elements or maximize the available
                      space.
                    </li>
                    <li style={{ fontWeight: "500" }}>
                      Use low creativity for subtle updates to basic terraces.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Take photos during daylight and capture the full space
                      </span>
                      . This helps the AI better understand the terrace layout
                      and create more accurate transformations.
                    </li>
                  </ul>
                </p>
              </div>
            </aside>
            <h2 className="article-subtitleh2">Terrace render examples</h2>
            <p className="article-text">
              Here are several examples of terrace renovations created with our
              renders:
            </p>
            <CurtainEffect
              beforeImage={Example1Before}
              afterImage={Example1After}
              altBefore={"Terrace before render"}
              altAfter={"Terrace after render"}
            />
            <CurtainEffect
              beforeImage={Example2Before}
              afterImage={Example2After}
              altBefore={"Terrace before render"}
              altAfter={"Terrace after render"}
            />
            <CurtainEffect
              beforeImage={Example3Before}
              afterImage={Example3After}
              altBefore={"Terrace before render"}
              altAfter={"Terrace after render"}
            />
            <CurtainEffect
              beforeImage={Example4Before}
              afterImage={Example4After}
              altBefore={"Terrace before render"}
              altAfter={"Terrace after render"}
            />
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default HowToRenderTerrace;
