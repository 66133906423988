import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import HowToRenovateFrame1 from "../Images/HowToRenovateFrame1.jpg";
import HowToRenovateFrame2 from "../Images/HowToRenovateFrame2.jpg";
import HowToRenovateFrame3 from "../Images/HowToRenovateFrame3.jpg";
import InfoIcon from "../Icons/InfoIcon";

import BeforeHouseStaging1 from "../Images/curtain-effect/BeforeHouseStaging1.png";
import AfterHouseStaging1 from "../Images/curtain-effect/AfterHouseStaging1.png";
import BeforeHouseStaging2 from "../Images/curtain-effect/BeforeHouseStaging2.png";
import AfterHouseStaging2 from "../Images/curtain-effect/AfterHouseStaging2.png";
import BeforeHouseStaging3 from "../Images/curtain-effect/BeforeHouseStaging3.png";
import AfterHouseStaging3 from "../Images/curtain-effect/AfterHouseStaging3.png";

import CurtainEffect from "../CurtainEffect";

function HowToRenderHouse() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            How to Create House Exterior Renders
          </h1>

          <div className="title-secondary gray">
            Tutorial on How to Create House Exterior Renders
          </div>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Founder at Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">Founder of Pedra</div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              Why use Pedra's render tools for house exteriors?
            </h2>
            <p className="article-text">
              Pedra's render tools allow you to visualize a complete house
              exterior transformation in just seconds, helping potential buyers
              see the true potential of the property.
              <p style={{ marginTop: "10px" }}>
                Exterior renovations are often the most impactful part of home
                improvements as they define the first impression of any
                property. Being able to show the potential of a dated exterior
                can make a huge difference in selling second-hand properties.
              </p>
              <p style={{ marginTop: "10px" }}>
                With Pedra, you no longer need specialized architects or
                rendering experts to create compelling exterior renovation
                proposals.
              </p>
            </p>
            <h2 className="article-subtitleh2">
              How do I create a house exterior render?
            </h2>
            <p className="article-text">
              Start by uploading clear photos of the existing house exterior you
              want to transform.
              <p style={{ marginTop: "10px" }}>
                Select your exterior images and click{" "}
                <span style={{ fontWeight: "500" }}>"Edit with AI"</span>, then
                choose the{" "}
                <span style={{ fontWeight: "500" }}>"Renovation"</span> option.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToRenovateFrame1}
                alt="How to Use the Renovation Tool to Create House Exterior Renders – Pedra Software Screenshot"
              />
            </p>
            <h2 className="article-subtitleh2">
              <span style={{ fontWeight: "600" }}>
                Quick Exterior Renovation:{" "}
              </span>
              Generate a Fast House Exterior Render
            </h2>
            <p style={{ marginTop: "10px" }}>
              The <span style={{ fontWeight: "500" }}>"Renovation"</span> tool
              will transform your house exterior in about 25 seconds.
              <p>
                For a subtle update, choose the "Minimalist" style with "Low"
                creativity. This will maintain the basic structure while
                modernizing finishes and facade elements.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "20px 0px",
                }}
                src={HowToRenovateFrame2}
                alt="How to Use the Renovation Tool to Create House Exterior Renders – Pedra Software Screenshot"
              />
            </p>

            <h2 className="article-subtitleh2">
              Customize Your House Exterior Renovation Render
            </h2>
            <p className="article-text">
              Pedra offers extensive customization options for exterior renders.
              <p style={{ marginTop: "10px" }}>
                <p style={{ marginTop: "10px" }}>
                  Choose from various architectural styles, control how dramatic
                  the changes should be, and decide whether to preserve existing
                  elements like windows or distinctive features.
                </p>
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                    margin: "20px 0px",
                  }}
                  src={HowToRenovateFrame3}
                  alt="How to Use the Renovation Tool to Create House Exterior Renders – Pedra Software Screenshot"
                />
              </p>
            </p>
            <br />
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Step 1 – </span>
              Choose your architectural style
            </h3>
            <p className="article-text">
              Select from 9 different architectural styles including modern,
              traditional, Mediterranean, and more.
              <p className="article-text" style={{ marginTop: "10px" }}>
                <span style={{ fontWeight: "500" }}>
                  You can also upload reference images of house styles you love.
                </span>{" "}
                This allows you to create renders that match specific design
                inspiration or your client's preferred aesthetic.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Step 2 – </span>
              Set the renovation intensity
            </h3>
            <p className="article-text">
              Choose between Low, Medium, High, and Very High creativity levels
              for your exterior transformation.
              <p style={{ marginTop: "10px" }}>
                Low and Medium settings will update finishes and facade elements
                while keeping the basic structure intact.
              </p>
              <p style={{ marginTop: "10px" }}>
                High and Very High settings can completely reimagine the
                exterior with new architectural elements, rooflines, and
                structural changes.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Step 3 – </span>
              Preserve key exterior elements
            </h3>
            <p className="article-text">
              Choose to preserve windows, which is particularly important for
              houses with distinctive window features or specific lighting
              requirements. This ensures your render maintains practical
              elements while updating the aesthetic.
              <br />
            </p>
            <h2 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Step 4 – </span>
              Generate your exterior render
            </h2>
            <p>
              After selecting your preferences, generate the exterior render.
              Standard renders take about 15 seconds, while preserving specific
              elements may take slightly longer.
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Step 5 – </span>
              Preserve key exterior elements
            </h3>
            <p className="article-text">
              Choose to preserve specific parts of the image that you don't want
              to modify.
              <p style={{ marginTop: "10px" }}>
                After selecting the "preserve parts" option, you can draw
                directly on the image to indicate areas that should remain
                unchanged. This is particularly useful for:
                <ul>
                  <li>Neighboring buildings you want to keep intact</li>
                  <li>The street or surrounding landscape</li>
                  <li>Distinctive architectural features</li>
                  <li>Windows or other elements you want to maintain</li>
                </ul>
              </p>
              <p style={{ marginTop: "10px" }}>
                Simply draw over the areas you want to preserve before clicking
                generate. This ensures your render focuses only on renovating
                the intended parts of the exterior while maintaining the context
                and surroundings.
              </p>
              <br />
            </p>
            <aside className="callout">
              <div>
                <InfoIcon />
              </div>
              <div className="callout-text">
                <h4 className="callout-h4">
                  Tips for better house exterior renders
                </h4>
                <p className="callout-p">
                  To get the most realistic and useful exterior renders:
                  <ul>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Generate multiple versions
                      </span>
                      . Each render is unique, and you might find some better
                      preserve important features like architectural details or
                      distinctive elements.
                    </li>
                    <li style={{ fontWeight: "500" }}>
                      Use low creativity for subtle updates to dated exteriors.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Take photos on an overcast day to minimize harsh
                        shadows, and capture the entire facade
                      </span>
                      . This helps the AI better understand the structure and
                      create more accurate transformations.
                    </li>
                  </ul>
                </p>
              </div>
            </aside>
            <h2 className="article-subtitleh2">
              House exterior render examples
            </h2>
            <p className="article-text">
              Here are several examples of exterior renovations created with our
              renders:
            </p>
            <CurtainEffect
              beforeImage={BeforeHouseStaging1}
              afterImage={AfterHouseStaging1}
              altBefore={"House exterior before render"}
              altAfter={"House exterior after render"}
            />
            <CurtainEffect
              beforeImage={BeforeHouseStaging2}
              afterImage={AfterHouseStaging2}
              altBefore={"House exterior before render"}
              altAfter={"House exterior after render"}
            />
            <CurtainEffect
              beforeImage={BeforeHouseStaging3}
              afterImage={AfterHouseStaging3}
              altBefore={"House exterior before render"}
              altAfter={"House exterior after render"}
            />
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default HowToRenderHouse;
