import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import InfoIcon from "../Icons/InfoIcon";
import VirtualStagingExample1 from "../Images/HowToFloorPlanRender1Es.png";
import VirtualStagingExample2 from "../Images/HowToFloorPlanRender2Es.png";
import VirtualStagingExample3 from "../Images/HowToFloorPlanRender3Es.png";

function HowToRenderFloorPlanWithAIEs() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">Cómo renderizar un plano con IA</h1>
          <h2 className="title-secondary gray">
            Tutorial sobre cómo generar imágenes realistas de estancias a partir
            de planos usando inteligencia artificial
          </h2>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Fundador de Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fundador de Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <article className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              ¿Qué es el renderizado de planos con IA?
            </h2>
            <p className="article-text">
              Con Pedra, puedes transformar tus planos en imágenes
              fotorrealistas de cada estancia en cuestión de minutos. Nuestra
              tecnología de IA analiza tu plano y genera visualizaciones de alta
              calidad que muestran cómo se verían los espacios en la vida real,
              permitiéndote elegir entre diferentes estilos de decoración.
            </p>

            <h3 className="article-subtitle">Paso 1 – Sube tu plano</h3>
            <div className="value-prop-text">
              Sube tu plano a la plataforma de Pedra.
            </div>
            <div className="picture">
              <img
                src={VirtualStagingExample1}
                alt="Cómo subir planos para renderizado automático en Pedra – Infografía"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto", maxWidth: "700px" }}
              />
            </div>

            <h3 className="article-subtitle">
              Paso 2 – Elige tus opciones de renderizado
            </h3>
            <div className="value-prop-text">
              Selecciona tu estilo y opciones de personalización.
              <p>Elige el número de imágenes que quieres y el estilo.</p>
            </div>
            <div className="picture">
              <img
                src={VirtualStagingExample2}
                alt="Cómo elegir opciones para renderizado de planos – Infografía"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto", maxWidth: "700px" }}
              />
            </div>

            <h3 className="article-subtitle">
              Paso 3 – Obtén tus renders de habitaciones
            </h3>
            <div className="value-prop-text">
              Recibe renders fotorrealistas de cada habitación de tu plano.
              <p>
                Descarga y usa tus renders inmediatamente o continúa
                editándolos.
              </p>
            </div>
            <div className="picture">
              <img
                src={VirtualStagingExample3}
                alt="Cómo descargar renders de planos – Infografía"
                width="600"
                height="400"
                style={{ width: "100%", height: "auto", maxWidth: "700px" }}
              />
            </div>

            <aside className="callout" style={{ marginTop: "10px" }}>
              <div>
                <InfoIcon />
              </div>
              <h4 className="callout-h4" style={{ fontWeight: "400" }}>
                Consejos para obtener mejores resultados:
                <ul>
                  <li>
                    Asegúrate de que el plano esté bien definido y las medidas
                    sean legibles
                  </li>
                  <li>Considera el estilo que mejor se adapte a tu proyecto</li>
                  <li>
                    Genera múltiples renders para tener diferentes opciones de
                    visualización
                  </li>
                </ul>
              </h4>
            </aside>

            <h2 className="article-subtitleh2">
              ¿Por qué usar el renderizado de planos con IA?
            </h2>
            <p className="article-text">
              El renderizado de planos con IA ofrece múltiples ventajas:
            </p>
            <ul>
              <li>
                Visualización inmediata de cómo se verán los espacios en la
                realidad
              </li>
              <li>
                Ahorro significativo de tiempo comparado con el renderizado
                tradicional
              </li>
              <li>Flexibilidad para probar diferentes estilos de decoración</li>
              <li>
                Calidad fotorrealista que ayuda en la presentación de proyectos
              </li>
            </ul>
            <h2 className="article-subtitleh2">
              Cómo renderizar un plano de manera automática
            </h2>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "10px",
              }}
            >
              {typeof window !== "undefined" && (
                <iframe
                  width="100%"
                  height="400px"
                  src="https://www.youtube.com/embed/ddsCKznJT_w?si=MKEfKjCUEiQhCx6j"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen
                ></iframe>
              )}
            </div>
          </div>
        </article>
      </div>
    </>
  );
}

export default HowToRenderFloorPlanWithAIEs;
