import React from "react";
import ReleaseNov2024Frame1 from "../Images/ReleaseNov2024Frame1.png";
import ReleaseNov2024Frame2 from "../Images/ReleaseNov2024Frame2.png";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function ReleaseNov24() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "60px" }}>
          <Link className="go-back" to="/releases">
            &#8592; Releases
          </Link>
          <br />

          <div className="title-secondary gray small">NOVEMBER 2024</div>
          <h1 className="article-titleh1">
            Pedra: Team collaboration and industry recognition
          </h1>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Felix Ingla"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">Founder of Pedra</div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <div className="article-text">
              This November, we bring you updates to help you collaborate with
              your team, and an achievement we're proud to share:
            </div>
            <br />
            <div className="article-text">
              <h2 className="article-subtitleh2">
                Invite members to your workspace
              </h2>
              Now you can invite other members to your workspace so different
              people from your agency can work in Pedra. Simply write their
              email and invite them.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseNov2024Frame1}
                  alt="Workspace invitation feature"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                2024 Real Estate AI Innovation Award
              </h2>
              The Real Estate Agents Association has awarded us the AI
              innovation award for the real estate sector. We received 56% of
              votes from 600 agents.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseNov2024Frame2}
                  alt="Innovation award"
                />
              </div>
            </div>
            <br />
            <br />
            <h2 className="article-subtitleh2">Other improvements</h2>
            <div>
              <li>
                Option to renovate and furnish for "Kitchen and dining room" and
                "Kitchen and living room"
              </li>
              <li>Faster room emptying</li>
              <li>
                Option to hire professional virtual room emptying service for €4
                per image
              </li>
              <li>Optimization of downloaded image sizes</li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReleaseNov24;
