import React from "react";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import HowToRenovateFrame1 from "../Images/HowToRenovateFrame1.jpg";
import HowToRenovateFrame2 from "../Images/HowToRenovateFrame2.jpg";
import HowToRenovateFrame3 from "../Images/HowToRenovateFrame3.jpg";
import InfoIcon from "../Icons/InfoIcon";

import Example1Before from "../Images/curtain-effect-hero/BeforeTerraza.png";
import Example1After from "../Images/curtain-effect-hero/AfterTerraza.png";
import Example2Before from "../Images/curtain-effect/BeforeTerrazaStaging1.png";
import Example2After from "../Images/curtain-effect/AfterTerrazaStaging1.png";
import Example3Before from "../Images/curtain-effect/BeforeTerrazaStaging2.png";
import Example3After from "../Images/curtain-effect/AfterTerrazaStaging2.png";
import Example4Before from "../Images/curtain-effect/BeforeTerrazaStaging3.png";
import Example4After from "../Images/curtain-effect/AfterTerrazaStaging3.png";

import CurtainEffect from "../CurtainEffect";

function HowToRenderTerraceFr() {
  return (
    <>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "100px" }}>
          <h1 className="article-titleh1">
            Comment créer des rendus de terrasse
          </h1>

          <div className="title-secondary gray">
            Tutoriel sur la création de rendus pour rénover votre terrasse
          </div>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Founder at Pedra"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fondateur de Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <h2 className="article-subtitleh2">
              À quoi servent les outils de rendu de Pedra ?
            </h2>
            <p className="article-text">
              Avec les outils de Pedra, vous pouvez visualiser une rénovation de
              terrasse en quelques secondes seulement.
              <p style={{ marginTop: "10px" }}>
                Montrer le potentiel d'une terrasse rénovée est particulièrement
                efficace pour la vente de biens immobiliers, les espaces
                extérieurs étant devenus de plus en plus importants pour les
                acheteurs.
              </p>
              <p style={{ marginTop: "10px" }}>
                Grâce à Pedra, il n'est plus nécessaire de faire appel à des
                paysagistes ou des architectes pour obtenir une proposition de
                rénovation professionnelle.
              </p>
            </p>
            <h2 className="article-subtitleh2">
              Comment créer un rendu de terrasse ?
            </h2>
            <p className="article-text">
              Une fois que vous avez téléchargé les photos de votre terrasse,
              sélectionnez-les.
              <p style={{ marginTop: "10px" }}>
                Cliquez sur{" "}
                <span style={{ fontWeight: "500" }}>"Edit with AI"</span>, et
                sélectionnez l'option{" "}
                <span style={{ fontWeight: "500" }}>"Renovation"</span>.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "10px 0px",
                }}
                src={HowToRenovateFrame1}
                alt="Comment utiliser l'outil de rendu pour rénover des terrasses – Capture d'écran du logiciel Pedra"
              />
            </p>
            <h2 className="article-subtitleh2">
              <span style={{ fontWeight: "600" }}>Rénovation : </span>
              Créer un rendu de terrasse rapidement
            </h2>
            <p style={{ marginTop: "10px" }}>
              En cliquant sur{" "}
              <span style={{ fontWeight: "500" }}>"Renovation"</span>, vous
              obtiendrez une proposition de rénovation pour votre terrasse en
              environ 15 secondes.
              <p>
                Cette option génère par défaut un style moderne avec des
                modifications modérées de l'espace existant.
              </p>
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                  margin: "20px 0px",
                }}
                src={HowToRenovateFrame2}
                alt="Comment utiliser l'outil de rendu pour rénover des terrasses – Capture d'écran du logiciel Pedra"
              />
            </p>

            <h2 className="article-subtitleh2">
              <span style={{ fontWeight: "600" }}>Rénovation Avancée : </span>
              Personnaliser le rendu de votre terrasse
            </h2>
            <p className="article-text">
              Pedra vous permet de personnaliser entièrement la rénovation de
              votre terrasse.
              <p style={{ marginTop: "10px" }}>
                Dans le menu{" "}
                <span style={{ fontWeight: "500" }}>"Renovation"</span>, vous
                pouvez ajuster plusieurs paramètres :
                <p style={{ marginTop: "10px" }}>
                  Le style de la terrasse, le niveau de transformation souhaité,
                  et la possibilité de conserver certains éléments comme les
                  garde-corps ou les caractéristiques structurelles.
                </p>
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                    margin: "20px 0px",
                  }}
                  src={HowToRenovateFrame3}
                  alt="Comment utiliser l'outil de rendu pour rénover des terrasses – Capture d'écran du logiciel Pedra"
                />
              </p>
            </p>
            <br />
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Étape 1 – </span>
              Style de terrasse
            </h3>
            <p className="article-text">
              Pedra propose 9 styles de base : moderne, tropical, méditerranéen,
              etc.
              <p className="article-text" style={{ marginTop: "10px" }}>
                <span style={{ fontWeight: "500" }}>
                  Vous pouvez également importer votre propre style.
                </span>{" "}
                En important des images de référence, vous pourrez créer des
                rendus basés sur vos inspirations personnelles.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Étape 2 – </span>
              Niveau de transformation
            </h3>
            <p className="article-text">
              Choisissez le niveau de transformation entre Faible, Moyen, Élevé
              et Très Élevé.
              <p style={{ marginTop: "10px" }}>
                Les niveaux Faible et Moyen conserveront l'agencement général de
                votre terrasse tout en modernisant le sol, le mobilier et la
                végétation.
              </p>
              <p style={{ marginTop: "10px" }}>
                Les niveaux Élevé et Très Élevé permettront de repenser
                complètement l'espace, en ajoutant des pergolas et en modifiant
                les éléments architecturaux.
              </p>
            </p>
            <h3 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Étape 3 – </span>
              Conservation des éléments existants
            </h3>
            <p className="article-text">
              Vous pouvez choisir de conserver les éléments structurels comme
              les garde-corps dans votre rendu. Cette option est
              particulièrement utile pour préserver les éléments essentiels de
              votre terrasse.
              <br />
            </p>
            <h2 className="article-subtitle">
              <span style={{ color: "rgb(248, 121, 42)" }}>Étape 4 – </span>
              Générer le rendu
            </h2>
            <p>
              Une fois vos préférences sélectionnées, lancez la génération du
              rendu. Le processus prend environ 15 secondes par image, un peu
              plus si vous avez choisi de conserver certains éléments.
            </p>
            <aside className="callout">
              <div>
                <InfoIcon />
              </div>
              <div className="callout-text">
                <h4 className="callout-h4">
                  Conseils pour des rendus de terrasse réussis
                </h4>
                <p className="callout-p">
                  Pour obtenir les meilleurs résultats possibles :
                  <ul>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Générez plusieurs versions
                      </span>
                      . Chaque rendu est unique, certains intégreront mieux les
                      éléments extérieurs.
                    </li>
                    <li style={{ fontWeight: "500" }}>
                      Utilisez un niveau de transformation faible pour une mise
                      à jour subtile.
                    </li>
                    <li>
                      <span style={{ fontWeight: "500" }}>
                        Prenez les photos en pleine lumière et capturez tout
                        l'espace
                      </span>
                      . Cela permettra d'obtenir un résultat plus précis et
                      professionnel.
                    </li>
                  </ul>
                </p>
              </div>
            </aside>
            <h2 className="article-subtitleh2">
              Exemples de rendus de terrasse
            </h2>
            <p className="article-text">
              Voici plusieurs exemples de rénovations de terrasse créées avec
              nos rendus :
            </p>
            <CurtainEffect
              beforeImage={Example1Before}
              afterImage={Example1After}
              altBefore={"Terrasse avant le rendu"}
              altAfter={"Terrasse après le rendu"}
            />
            <CurtainEffect
              beforeImage={Example2Before}
              afterImage={Example2After}
              altBefore={"Terrasse avant le rendu"}
              altAfter={"Terrasse après le rendu"}
            />
            <CurtainEffect
              beforeImage={Example3Before}
              afterImage={Example3After}
              altBefore={"Terrasse avant le rendu"}
              altAfter={"Terrasse après le rendu"}
            />
            <CurtainEffect
              beforeImage={Example4Before}
              afterImage={Example4After}
              altBefore={"Terrasse avant le rendu"}
              altAfter={"Terrasse après le rendu"}
            />
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default HowToRenderTerraceFr;
