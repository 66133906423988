import React from "react";
import { Link } from "react-router-dom";

function Help() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="step-container article">
          <div style={{ marginTop: "100px" }}>
            <h1 className="article-titleh1">Welcome to Pedra Help Center</h1>
            <div className="article-text">
              <br />
              <div style={{ marginTop: "10px" }}>
                Learn how to transform your real estate properties with our
                virtual staging tools.
              </div>
              <div style={{ marginTop: "10px" }}>
                Whether you're new to{" "}
                <Link className="article-link" to="/">
                  Pedra
                </Link>{" "}
                or looking to master our features, we've got comprehensive
                guides to help you succeed.
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Getting Started with{" "}
                <span style={{ color: "rgb(248, 121, 42)" }}>
                  Virtual Staging
                </span>
              </h2>
              <div style={{ marginTop: "10px" }}>
                Our virtual staging tools allow you to transform empty spaces
                into beautifully furnished rooms, create renovation previews,
                and enhance property photos - all without physical staging.
              </div>
              <div style={{ marginTop: "10px" }}>
                Start with these essential tutorials:
              </div>
              <div style={{ marginTop: "10px" }}>
                <ul>
                  <li>
                    <Link
                      className="article-link"
                      to="/help/how-to-create-virtual-home-staging"
                    >
                      How to Create Virtual Home Staging
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/help/how-to-empty-rooms-virtually"
                    >
                      How to Empty Rooms Virtually
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/help/how-to-furnish-virtually"
                    >
                      How to Furnish Virtually
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/help/how-to-add-3d-objects"
                    >
                      How to Add 3D Objects
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/help/how-to-remove-object"
                    >
                      How to Remove Objects
                    </Link>
                  </li>
                </ul>
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Room-Specific{" "}
                <span style={{ color: "rgb(248, 121, 42)" }}>Tutorials</span>
              </h2>
              <div style={{ marginTop: "10px" }}>
                Learn how to stage and create renders for different types of
                rooms:
              </div>
              <div style={{ marginTop: "10px" }}>
                <h3 style={{ marginBottom: "10px", marginTop: "20px" }}>
                  Virtual Staging Guides:
                </h3>
                <ul>
                  <li>
                    <Link
                      className="article-link"
                      to="/help/how-to-create-living-room-virtual-home-staging"
                    >
                      Living Room Staging Guide
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/help/how-to-create-bedroom-virtual-home-staging"
                    >
                      Bedroom Staging Guide
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/help/how-to-create-kitchen-virtual-home-staging"
                    >
                      Kitchen Staging Guide
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/help/how-to-create-bathroom-virtual-home-staging"
                    >
                      Bathroom Staging Guide
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/help/how-to-create-terrace-virtual-home-staging"
                    >
                      Terrace Staging Guide
                    </Link>
                  </li>
                </ul>
                <h3 style={{ marginBottom: "10px", marginTop: "20px" }}>
                  Rendering Guides:
                </h3>
                <ul>
                  <li>
                    <Link
                      className="article-link"
                      to="/help/how-to-create-living-room-render"
                    >
                      Living Room Render Guide
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/help/how-to-create-bedroom-render"
                    >
                      Bedroom Render Guide
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/help/how-to-create-kitchen-render"
                    >
                      Kitchen Render Guide
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/help/how-to-create-bathroom-render"
                    >
                      Bathroom Render Guide
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/help/how-to-create-terrace-render"
                    >
                      Terrace Render Guide
                    </Link>
                  </li>
                </ul>
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Advanced{" "}
                <span style={{ color: "rgb(248, 121, 42)" }}>Features</span>
              </h2>
              <div style={{ marginTop: "10px" }}>
                Take your staging to the next level with our advanced tools:
              </div>
              <div style={{ marginTop: "10px" }}>
                <ul>
                  <li>
                    <Link
                      className="article-link"
                      to="/help/how-to-create-house-exterior-render"
                    >
                      Creating Exterior Renders
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/help/how-to-draw-floor-plan"
                    >
                      Drawing Floor Plans
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="article-link"
                      to="/help/how-to-renovate-houses-virtually"
                    >
                      Virtual Renovation Guide
                    </Link>
                  </li>
                </ul>
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Need <span style={{ color: "rgb(248, 121, 42)" }}>Help?</span>
              </h2>
              <div style={{ marginTop: "10px" }}>
                Can't find what you're looking for? Our support team is here to
                help. Contact us at felix@pedra.so.
              </div>
              <br />
              <br />
              <div style={{ marginTop: "10px" }}>
                Ready to start transforming your property listings?
              </div>
              <div style={{ marginTop: "10px" }}>
                Choose any tutorial above to begin, or visit our{" "}
                <Link className="article-link" to="/pricing">
                  pricing page
                </Link>{" "}
                to learn more about our plans.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Help;
