import React from "react";
import ReleaseSep2024Frame1 from "../Images/ReleaseSep2024Frame1.png";
import ReleaseSep2024Frame2 from "../Images/ReleaseSep2024Frame2.png";
import ReleaseSep2024Frame3 from "../Images/ReleaseSep2024Frame3.png";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function ReleaseEsSep24() {
  return (
    <div style={{ justifyContent: "center" }}>
      <div className="section-one-column-left">
        <div className="title-container article" style={{ marginTop: "60px" }}>
          <Link className="go-back" to="/es/releases">
            &#8592; Lanzamientos
          </Link>
          <br />

          <div className="title-secondary gray small">SEPTIEMBRE 2024</div>
          <h1 className="article-titleh1">
            Pedra: Renovación y amueblado en un click, mejoras en resultados y
            preservación de espacios
          </h1>
          <div className="author-block">
            <img
              style={{
                border: "1px solid #F7F7F7",
                borderRadius: "50%",
                height: "42px",
                width: "auto",
              }}
              src={FelixIngla}
              alt="Foto de Felix Ingla"
            />
            <div className="author-block-name-position">
              <div className="author-block-name">Felix Ingla</div>
              <div className="author-block-name-position">
                Fundador de Pedra
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one-column-left">
        <div className="step-container article">
          <div>
            <div className="article-text">
              Este septiembre os traemos mejoras para hacer el uso de Pedra más
              eficiente y así podáis mostrar el potencial de vuestras
              propiedades de manera sencilla:
            </div>
            <br />
            <div className="article-text">
              <h2 className="article-subtitleh2">
                Renueva y amuebla en 1 click
              </h2>
              Antes, para amueblar y renovar un espacio había que hacerlo en dos
              pasos. Ahora ya no hace falta – podrás generar propuestas de
              renovación que amueblen el espacio.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseSep2024Frame1}
                  alt="Ejemplo de renovación y amueblado en un click"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Mejores resultados en "Amueblar" en "Auto"
              </h2>
              Hemos trabajado el{" "}
              <Link className="article-link" to="/es/home-staging-virtual">
                home staging virtual
              </Link>{" "}
              de Pedra para que salga mejor que nunca. Ahora al darle a{" "}
              <strong>"Amueblar"</strong> en <strong>"Auto"</strong> verás
              muchos mejores resultados: paredes decoradas, hiperralismo y
              muebles correctamente posicionados.
              <div
                style={{
                  alignItems: "center",
                  textAlign: "center",
                  paddingTop: "20px",
                }}
              >
                <img
                  style={{
                    border: "1px solid #F7F7F7",
                    borderRadius: "12px",
                    width: "100%",
                  }}
                  src={ReleaseSep2024Frame2}
                  alt="Ejemplo de mejores resultados en Amueblar Auto"
                />
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">
                Mejora en la preservación del espacio al vaciar habitación
              </h2>
              Al <strong>"Vaciar habitación"</strong> verás que se conserva
              mejor la arquitectura del espacio.
            </div>
            <div
              style={{
                alignItems: "center",
                textAlign: "center",
                paddingTop: "20px",
              }}
            >
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "12px",
                  width: "100%",
                }}
                src={ReleaseSep2024Frame3}
                alt="Ejemplo de preservación del espacio al vaciar habitación"
              />
            </div>
            <br />
            <br />
            <h2 className="article-subtitleh2">Otras mejoras</h2>

            <div>
              <li>
                Mejores resultados en <strong>"Convertir a HD"</strong>
              </li>
              <li>
                Muebles mejor posicionados al <strong>"Amueblar"</strong> en{" "}
                <strong>"Auto"</strong>
              </li>
              <li>Mejores resultados en poner el cielo azul</li>
              <li>
                Mejora al quitar objetos (el objeto se quita correctamente y se
                preserva la calidad)
              </li>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReleaseEsSep24;
