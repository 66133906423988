import React from "react";
import { Helmet } from "react-helmet";
import ReleaseNov2024Frame1 from "../Images/ReleaseNov2024Frame1.png";
import ReleaseNov2024Frame2 from "../Images/ReleaseNov2024Frame2.png";
import FelixIngla from "../Images/Felix Ingla picture.jpeg";
import { Link } from "react-router-dom";

function ReleaseNov24() {
  const title = "Pedra: Colaboración en equipo y reconocimiento del sector";
  const description =
    "Novedades de Pedra: invitación a miembros del equipo y premio de innovación IA para el sector inmobiliario 2024";
  const imageUrl =
    "https://pedraimages.s3.eu-west-3.amazonaws.com/ReleaseAug2024.png";
  const canonicalUrl = "https://pedra.com/es/releases/espacios-de-trabajo";

  return (
    <>
      <Helmet prioritizeSeoTags>
        {/* OpenGraph / Facebook */}
        <title>{title}</title>
        <meta name="description" content={description} data-rh="true" />
        <link rel="canonical" href={canonicalUrl} data-rh="true" />

        <meta property="og:url" content={canonicalUrl} data-rh="true" />
        <meta property="og:type" content="article" data-rh="true" />
        <meta property="og:title" content={title} data-rh="true" />
        <meta property="og:description" content={description} data-rh="true" />
        <meta property="og:image" content={imageUrl} data-rh="true" />

        <meta
          name="twitter:card"
          content="summary_large_image"
          data-rh="true"
        />
        <meta name="twitter:title" content={title} data-rh="true" />
        <meta name="twitter:description" content={description} data-rh="true" />
        <meta name="twitter:image" content={imageUrl} data-rh="true" />

        {/* Twitter */}
        <meta
          name="twitter:card"
          content="summary_large_image"
          data-rh="true"
        />
        <meta
          name="twitter:title"
          content="Pedra: Colaboración en equipo y reconocimiento del sector"
          data-rh="true"
        />
        <meta
          name="twitter:description"
          content="Novedades de Pedra: invitación a miembros del equipo y premio de innovación IA para el sector inmobiliario 2024"
          data-rh="true"
        />
        <meta
          name="twitter:image"
          content={
            "https://pedraimages.s3.eu-west-3.amazonaws.com/ReleaseAug2024.png"
          }
          data-rh="true"
        />
      </Helmet>
      <div style={{ justifyContent: "center" }}>
        <div className="section-one-column-left">
          <div
            className="title-container article"
            style={{ marginTop: "60px" }}
          >
            <Link className="go-back" to="/releases">
              &#8592; Releases
            </Link>
            <br />

            <div className="title-secondary gray small">NOVIEMBRE 2024</div>
            <h1 className="article-titleh1">
              Pedra: Colaboración en equipo y reconocimiento del sector
            </h1>
            <div className="author-block">
              <img
                style={{
                  border: "1px solid #F7F7F7",
                  borderRadius: "50%",
                  height: "42px",
                  width: "auto",
                }}
                src={FelixIngla}
                alt="Felix Ingla"
              />
              <div className="author-block-name-position">
                <div className="author-block-name">Felix Ingla</div>
                <div className="author-block-name-position">
                  Fundador de Pedra
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section-one-column-left">
          <div className="step-container article">
            <div>
              <div className="article-text">
                Este noviembre os traemos novedades que os ayudarán a colaborar
                con vuestro equipo, y un logro de Pedra que nos orgullece
                compartir:
              </div>
              <br />
              <div className="article-text">
                <h2 className="article-subtitleh2">
                  Invitar a miembros a tu espacio de trabajo
                </h2>
                Ahora puedes invitar a otros miembros a tu espacio de trabajo
                para que diferentes personas de tu agencia puedan trabajar en
                Pedra. Tan sólo escribe su email y invítalos.
                <div
                  style={{
                    alignItems: "center",
                    textAlign: "center",
                    paddingTop: "20px",
                  }}
                >
                  <img
                    style={{
                      border: "1px solid #F7F7F7",
                      borderRadius: "12px",
                      width: "100%",
                    }}
                    src={ReleaseNov2024Frame1}
                    alt="Función de invitación al espacio de trabajo"
                  />
                </div>
                <br />
                <br />
                <h2 className="article-subtitleh2">
                  Premio de innovación IA para el sector inmobiliario 2024
                </h2>
                El Colegio de Agentes de la Propiedad Inmobiliaria nos ha
                otorgado el premio a la innovación IA para el sector
                inmobiliario. Recibimos el 56% de los votos de 600 agentes.
                <div
                  style={{
                    alignItems: "center",
                    textAlign: "center",
                    paddingTop: "20px",
                  }}
                >
                  <img
                    style={{
                      border: "1px solid #F7F7F7",
                      borderRadius: "12px",
                      width: "100%",
                    }}
                    src={ReleaseNov2024Frame2}
                    alt="Premio de innovación"
                  />
                </div>
              </div>
              <br />
              <br />
              <h2 className="article-subtitleh2">Otras mejoras</h2>
              <div>
                <li>
                  Opción de renovar y amueblar para "Cocina y comedor" y "Cocina
                  y salón"
                </li>
                <li>El vaciado de habitación ahora es más rápido</li>
                <li>
                  Opción de contratar servicio profesional de vaciado virtual de
                  habitaciones por 4€ la imagen
                </li>
                <li>Optimización del peso de las imágenes descargadas</li>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReleaseNov24;
